/* vendor */
@import "~@fancyapps/ui/dist/fancybox";
@import './vendor/animated-check';
@import "./vendor/tipso.min";
@import 'vendor/mobilescroll/mobiscroll.javascript.css';

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

@import "/node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "/node_modules/@glidejs/glide/src/assets/sass/glide.theme";

// vendor
@import "book-preview";
@import "./loader";
@import "./alerts";
@import "./mixins";
@import "./checkout";
@import "./header";
@import "./common";
@import "./footer";
@import "./map";
@import './calendar';
@import "animate.css";

/* pages */
@import "property";

/* global */
@font-face {
  font-family: "Butler Regular";
  src: url(vendor/fonts/Butler/Butler_Regular.otf);
}
@font-face {
  font-family: "Bradon Grotesque";
  src: url(vendor/fonts/Brandon_Grotesque/Brandon_thin.otf);
}
@font-face {
  font-family: travelstaytion-font;
  src: url(../fonts/travelstaytion-font.eot?et44l1);
  src: url(../fonts/travelstaytion-font.eot?et44l1#iefix) format('embedded-opentype'), url(../fonts/travelstaytion-font.ttf?et44l1) format('truetype');
  font-weight: 400;
  font-style: normal
}
[class*=" icon-"],
[class^=icon-] {
  font-family: travelstaytion-font !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}
.pagination span {
  padding: 0.5rem 1rem;
  border: 1px solid #0000001f;
  margin: 1rem 1rem;
  font-weight: bold;
}
body{
  font-family: 'Lato', sans-serif;
}
p {
  white-space: break-spaces;
}
h1, h2, h3, h4, h5, h6{
  font-family: "Butler Regular";
}
.search-sub {
  width: 720px;
}
select{
  background-color: transparent;
}
::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #BDC2C598;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #BDC2C598;
}
#faq-page {
  background: linear-gradient(45deg, #f7efe7, #e5ebe8);

  .question{
    background-color: #EDEDED;
  }
}
#terms-and-conditions{
  background: linear-gradient(45deg, #f7efe7, #e5ebe8);

  .header.active{
    background-color: #fff;
  }
}

#search {

  .active {
    background-color: #000000;
    color: #fff;
  }
}
.active {
  color: #fff;
}
.form-checkbox{
  color: #000;
}
.app-blue{
  color: #3271a7
}
.mbsc-segmented {
  width: 100%!important;
}
.f-input, .f-select{
  padding: .75rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-width: 1px;
  border-radius: 1rem;
  border-color: #e5e7eb;
  outline: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);
  width: 100%;
}
.search-second-page-text{
  background-color: #f4f4f4;
}
.is-instant {
  background-color: #ffffff;
  color: red !important;
  border-radius: 50%;
  padding: 10px;
  width: 35px;
  height: 35px;
}
.next-button{
  cursor: pointer;
}
.tipso_bubble {
  font-size: 0.8em !important;
  z-index: 99999!important;
}
.tipso_style{
  cursor: help;
  border-bottom: 0px dotted!important;
}
textarea:focus, input:focus{
  outline: none;
}
.gradient{
  background: linear-gradient(rgba(0,0,0,0),rgba(0, 0, 0, 0.66));
}
img {
  image-rendering: optimizeQuality;
}
.icon{
  img{
    height: 36px!important;
  }
}
.red-colorized{
  color: #cd5360;
}
.red-bullet-circle{
  font-size: 6px;
  color: #cd5360;
}
.blue-bullet-circle{
  font-size: 6px;
  color: #3271a7
}
.product-cancellation, .product-offers{
  color: #3271a7
}
.product-price-icon, .product-marker{
  color: #cd5360
}
.error{
  color: #cd5360;
}
.instant-icon{
  color: #cd5360;
  font-size: 1.5em;
}
.slider-black-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000002e;
  z-index: 1;
}
.link{
  color: #3870a5;
  text-decoration: underline;
}
.fixed-black-background {
  position: fixed;
  background-color: #00000066;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9997;
}
.popup-wrapper{

  .popup-inner {
    position: fixed;
    z-index: 9998;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
.success-message {
  text-align: center;
  padding: 1em;
  background-color: #000;
  color: #fff;
  font-weight: bold;
}

.error-message{
  text-align: center;
  padding: 1em;
  background-color: #b60000;
  color: #fff;
  font-weight: bold;
}
.slider-arrow-left{
  position: absolute;
  right: -100px;
  top: 41%;
  cursor: pointer;
  display: block;
  z-index: 30;
  border-radius: 50%;
  transition: all 200ms ease-in-out;

  i{
    font-size: 34px;
    display: block;
    line-height: calc(90px - 10px);
    text-align: center;
    color: #fff;
    transition: all 200ms ease-in-out;
  }
}
.slider-arrow-right{
  position: absolute;
  left: -100px;
  top: 41%;
  cursor: pointer;
  display: block;
  z-index: 30;
  border-radius: 50%;
  transition: all 200ms ease-in-out;

  i{
    font-size: 34px;
    display: block;
    line-height: calc(90px - 10px);
    text-align: center;
    color: #fff;
    transition: all 200ms ease-in-out;
  }
}
.search-product-slide{
  overflow: hidden;

  &:hover{
    .slider-arrow-right {
      left: 19px;
    }
    .slider-arrow-left{
      right: 19px;
    }
  }
}
.mapboxgl-popup-content{
  padding: 0px!important;
}
//.mbsc-material.mbsc-calendar-day-text{
//  font-weight: bold;
//}
#property-inline-calendar, #property-datepicker{

  .mbsc-material.mbsc-calendar-month-title {
    text-align: center;
    color: #000!important;
  }
  .mbsc-disabled .mbsc-calendar-day-text {
    text-decoration: line-through;
  }
  .mbsc-material.mbsc-calendar-day-text {
    font-size: 1em;
  }
  .mbsc-selected.mbsc-calendar-day-text{
    padding: 1em;
  }
  .mbsc-material.mbsc-range-day::after, .mbsc-material.mbsc-range-hover::before{
    height: 2.1em;

  }
  .mbsc-material.mbsc-range-day::after{
    background: rgb(247, 247, 247) !important;
  }
}
.mbsc-calendar-label-background.mbsc-material{
  background-color: transparent!important;
}
.mbsc-calendar-label-text.mbsc-material {
  color: #cd5360;
  text-align: center;
}
.underline:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: #000;
  top: 58px;
  z-index: 9999;
  width: 70px;
  text-align: center;
}
.home-full-image {
  position: relative;
}
.home-full-image-inner {
  position: absolute;
  z-index: 30;
  background-color: #000000b0;
  color: #fff;
  padding: 2em 0em;

  h1{
    color: #fff;
  }
}
.search-product-slide{
  .slick-arrow{
    background-color: #ffffffa6;
    width: 31px;
    height: 30px;
    padding: 5px 1px 1px 10px;
  }
}
.white-svg{
  filter: invert(100%) sepia(0%) saturate(312%) hue-rotate(8deg) brightness(105%) contrast(101%);
}
.live-search-form .active{
  background-color: #000000;
  color: #fff;
}
.is-luxury {
  background-color: #ffffff;
  color: #fdc704 !important;
  border-radius: 50%;
  padding: 8px;
  width: 35px;
  height: 35px;
}
.map-activated {
  border: 3px solid;
}
@media only screen and (min-width:320px) {
  .underline:after {
    left: 38%;
  }
}
@media only screen and (min-width:425px) {
  .underline:after {
    left: 41%;
  }
}
@media only screen and (min-width:768px) {
  .underline:after {
    left: 45%;
  }
}
@media only screen and (min-width:1024px) {
  .underline:after {
    display: none;
  }
}
@media only screen and (min-width: 1440px) {

  .home-full-image-inner {
    top: 10vh;
    left: 15vw;
    width: 68vw;
    padding: 3em 0em;
  }
}
@media only screen and (min-width: 1920px) {
}
@media only screen and (min-width: 2560px) {
}
.icon-travel_bed-double:before {
  content: "\e97e"
}
.icon-travel_bath:before {
  content: "\e916"
}
.icon-travel_user:before {
  content: "\e923"
}
.icon-travel_info:before {
  content: "\e944"
}
.icon-travel_house:before {
  content: "\e957"
}
.icon-travel_wifi:before {
  content: "\e917"
}
.icon-travel_towels:before {
  content: "\e91f"
}
.icon-travel_check:before {
  content: "\e918"
}
.icon-travel_phone:before {
  content: "\e905"
}
.icon-travel_plus:before {
  content: "\e93e"
}
.selected-marker{
  color: #000;
}
.map-top {
  top: 82px;
}
button.mapboxgl-popup-close-button {
  font-size: 27px;
  margin-right: 5px;
  margin-top: 3px;
}
.mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
  background-color: #222222;
  border-color: #222222;
}