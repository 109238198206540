.air-cell {
  color: rgb(34, 34, 34);
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 2.125rem;
  font-weight: 500;

  span {
    padding: 10px 13px;
  }

  &:hover {
    span {
      border: 1.5px solid rgb(34, 34, 34);
      border-radius: 100%;
    }
  }
}
.mbsc-disabled {
  .air-cell {
    color: rgb(173 173 173);
    text-decoration: line-through;

    &:hover {
      span {
        border: unset;
        border-radius: unset;
      }
    }
  }
}
.mbsc-disabled.checkout-only {
  .mbsc-calendar-cell-text {
    color: rgb(106, 106, 106) !important;
    text-decoration: none !important;
    cursor: default !important;
  }
}

.tip-cloud, .minimum_stay-cloud {
  position: absolute;
  z-index: 500;
  background-color: #ffff;
  top: -38px;
  box-shadow: 1px 1px 10px 1px #d9d9d98f;
  padding: 5px 12px;
  visibility: hidden;
  left: -20px;

  &::after{
    // create a inverted triangle
    content: "";
    position: absolute;
    top: 100%;
    left: 59px;
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: #ffff transparent transparent transparent;
  }
}

.tip-cloud.active, .minimum_stay-cloud.active {
  visibility: visible!important;
  color: #0a0a0a;
}
