.mbsc-popup, #property-inline-calendar, #property-datepicker{

  .cell-booked:not(.mbsc-calendar-day-empty){
    background: #777777;
  }

  .mbsc-hidden-content {
    display: none;
  }

  .cell-check-in:not(.mbsc-calendar-day-empty){
    background: linear-gradient( to left top, #777777 50%, transparent 50%) no-repeat;
  }
  .cell-check-out:not(.mbsc-calendar-day-empty){
    background: linear-gradient( to left top, transparent 50%, #777777 50%) no-repeat;
  }
}

