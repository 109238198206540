.top {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.18);

  .language-switcher-wrapper{
    a{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .language-switcher{
    color: #222222;
    font-size: 14px;
    border-radius: 22px;
    padding: 12px;

    &:hover{
      background-color: #f7f7f7;
    }
  }
  .user-top-menu-wrapper {

    .user-top-menu{
      border: 2px solid #000;
      padding: 5px 5px 5px 12px;
      border-radius: 1rem;
      width: 77px;
      color: #717171;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 39px;

      .menu-bar{
        width: 30px;
      }

      .menu-image{
        width: 30px;
        height: 30px;
      }

      &:hover{
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18) !important;
      }
    }

    .sub-menu-wrapper {
      top: 42px;
      right: 0px;
      z-index: 9999;

      .sub-menu-inner {
        background-color: #fff;
        padding: 1em;
        box-shadow: 1px 1px 10px 1px #d9d9d98f;
        border-radius: 10px;
        width: 240px;
      }
    }
  }
}
.top-fixed-home {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;

  .language-switcher-wrapper{
    a{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .language-switcher{
    color: #222222;
    font-size: 14px;
    border-radius: 22px;
    padding: 12px;

    &:hover{
      background-color: #f7f7f7;
    }
  }
  .user-top-menu-wrapper {
    position: relative;

    .user-top-menu{
      border: 1px solid #DDDDDD;
      padding: 5px 5px 5px 12px;
      border-radius: 21px;
      width: 77px;
      color: #717171;
      cursor: pointer;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

      .menu-bar{
        width: 30px;
      }

      .menu-image{
        width: 30px;
        height: 30px;
      }

      &:hover{
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18) !important;
      }
    }

    .sub-menu-wrapper {
      position: absolute;
      top: 45px;
      right: 0px;
      display: none;
      z-index: 9999;

      .sub-menu-inner {
        background-color: #fff;
        padding: 1em;
        box-shadow: 1px 1px 10px 1px #d9d9d98f;
        border-radius: 10px;
        width: 240px;

        .item:not(:last-child) {
          padding-bottom: 0.5em;
        }
      }
    }
  }
}