@import "property/datepicker";

.datepicker-wrapper{
  width: 100%;
  display: block;
  position: relative;
}
.property-availability-wrapper .title {
  font-size: 1.7em;
  font-weight: bold;
}
.cover-gradient-black-to-transparent {
  background: linear-gradient(0deg, rgb(0 0 0 / 48%) 0%, rgba(0, 0, 0, 0) 100%);
}
.minimum-stay-disabled, .checkout-only {
  cursor: pointer!important;

  .mbsc-calendar-cell-text{
    color:#717171;
    opacity:1;
    text-decoration: none!important;

    .active{
      background: rgb(255, 255, 255) !important;
      color: rgb(113, 113, 113);
      border: 1px solid rgb(221, 221, 221);
    }
  }
}
