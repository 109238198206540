.has-submenu:hover .is-submenu {
  display: block;
}
.has-submenu{
  position: relative;

  .is-submenu{
    position: absolute;
    z-index: 8000;
    display:none;
    background-color: #fff;
    padding: 1em;
    box-shadow: 1px 1px 10px 1px #d9d9d98f;
    border-radius: 10px;
    width: 240px;
    right: 2px;
  }
}
.enquiry-button {
  border: 2px solid #000;
  background-color: #fff;
  font-weight: bold;
  border-radius: 1.3em;
  padding: 0.7em 1em;
  font-size: 0.8em;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}
input[type=checkbox] {
  background-size: 27px;
  background-position: center;
  background-repeat: no-repeat;
  appearance: none;
  border: 1px solid #333;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  border-radius: 25%;

  &:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    background-color: #000;
    color: #fff;
  }
}

input[type=radio]{
  background-color: #000000;
  accent-color: #000;
  width: 1.25rem;
  height: 1.25rem;
  cursor:pointer;
}
.inline-alert-message {
  color: red;
  font-size: 1.1em;
  font-weight: bold;
}

.h-500{
  height: 500px;
}
.h-700{
  height: 700px;
}
.z-9000{
  z-index: 9000;
}
.md-h-500{
    @media (min-width: 768px) {
        height: 500px;
    }
}
.md-h-600{
  @media (min-width: 768px) {
    height: 600px;
  }
}
