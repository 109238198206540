#style-toggle, #satellite-toggle {
  border: none;
  box-shadow: 0 1px 4px rgb(0 0 0 / 30%);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
  padding: 0.2rem 0.5rem;
  background-color: #fff;
  font-weight: bold;
  border-radius: 50px;
}
.style-toggle-active{
  color: #fff;
  background-color: #000000!important;
}
#style-toggle:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}