#preview{
  font-family: 'Arial', sans-serif!important;
  font-size: 17px;

  #services {
    max-height: 250px;
    overflow: hidden;
  }

  #date-range{
    padding: .5rem 1em;
    background-color: rgb(229, 231, 235);
    border-radius: 1rem;

    & > .item{
      .month-year {
        font-size: 0.8em;
      }
      .number{
        text-align: center;
        font-weight: bold;
      }
    }
  }

  .open{
    max-height: 100%!important;
  }

  #cancellation-policy{
    color: rgb(29, 78, 216)
  }
  .service-item{
    color: #333;
  }
}