.success-alert {
  color: #008e25;
  font-weight: bold;
  padding: 0.5em;
  text-align: center;
}
.error-alert {
  .error-inner{
    color: #fff;
    text-align: left;
    border: 1px solid red;
    background-color: #ff6767;
    padding: 0.3rem 1rem;
    font-weight: bold;
  }
}
//.error-alert{
//  color: red;
//  text-align: center;
//  font-weight: bold;
//}