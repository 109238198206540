/* vendor */
@import 'vendor/mobilescroll/mobiscroll.javascript.css';
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap");
@import "animate.css";
.carousel {
  position: relative;
  box-sizing: border-box;
}

.carousel *, .carousel *:before, .carousel *:after {
  box-sizing: inherit;
}

.carousel.is-draggable {
  cursor: move;
  cursor: grab;
}

.carousel.is-dragging {
  cursor: move;
  cursor: grabbing;
}

.carousel__viewport {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  max-height: 100%;
}

.carousel__track {
  display: flex;
}

.carousel__slide {
  flex: 0 0 auto;
  width: var(--carousel-slide-width, 60%);
  max-width: 100%;
  padding: 1rem;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
}

.has-dots {
  margin-bottom: calc(0.5rem + 22px);
}

.carousel__dots {
  margin: 0 auto;
  padding: 0;
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  list-style: none;
  user-select: none;
}

.carousel__dots .carousel__dot {
  margin: 0;
  padding: 0;
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.carousel__dots .carousel__dot:after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: currentColor;
  opacity: 0.25;
  transition: opacity 0.15s ease-in-out;
}

.carousel__dots .carousel__dot.is-selected:after {
  opacity: 1;
}

.carousel__button {
  width: var(--carousel-button-width, 48px);
  height: var(--carousel-button-height, 48px);
  padding: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: all;
  cursor: pointer;
  color: var(--carousel-button-color, currentColor);
  background: var(--carousel-button-bg, transparent);
  border-radius: var(--carousel-button-border-radius, 50%);
  box-shadow: var(--carousel-button-shadow, none);
  transition: opacity 0.15s ease;
}

.carousel__button.is-prev, .carousel__button.is-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel__button.is-prev {
  left: 10px;
}

.carousel__button.is-next {
  right: 10px;
}

.carousel__button[disabled] {
  cursor: default;
  opacity: 0.3;
}

.carousel__button svg {
  width: var(--carousel-button-svg-width, 50%);
  height: var(--carousel-button-svg-height, 50%);
  fill: none;
  stroke: currentColor;
  stroke-width: var(--carousel-button-svg-stroke-width, 1.5);
  stroke-linejoin: bevel;
  stroke-linecap: round;
  filter: var(--carousel-button-svg-filter, none);
  pointer-events: none;
}

html.with-fancybox {
  scroll-behavior: auto;
}

body.compensate-for-scrollbar {
  overflow: hidden !important;
  touch-action: none;
}

.fancybox__container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  direction: ltr;
  margin: 0;
  padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  color: var(--fancybox-color, #fff);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: hidden;
  z-index: 1050;
  outline: none;
  transform-origin: top left;
  --carousel-button-width: 48px;
  --carousel-button-height: 48px;
  --carousel-button-svg-width: 24px;
  --carousel-button-svg-height: 24px;
  --carousel-button-svg-stroke-width: 2.5;
  --carousel-button-svg-filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
}

.fancybox__container *, .fancybox__container *::before, .fancybox__container *::after {
  box-sizing: inherit;
}

.fancybox__container :focus {
  outline: none;
}

body:not(.is-using-mouse) .fancybox__container :focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px var(--fancybox-accent-color, rgba(1, 210, 232, 0.94));
}

@media all and (min-width: 1024px) {
  .fancybox__container {
    --carousel-button-width:48px;
    --carousel-button-height:48px;
    --carousel-button-svg-width:27px;
    --carousel-button-svg-height:27px;
  }
}
.fancybox__backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: var(--fancybox-bg, rgba(24, 24, 27, 0.92));
}

.fancybox__carousel {
  position: relative;
  flex: 1 1 auto;
  min-height: 0;
  height: 100%;
  z-index: 10;
}

.fancybox__carousel.has-dots {
  margin-bottom: calc(0.5rem + 22px);
}

.fancybox__viewport {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
  cursor: default;
}

.fancybox__track {
  display: flex;
  height: 100%;
}

.fancybox__slide {
  flex: 0 0 auto;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 48px 8px 8px 8px;
  position: relative;
  overscroll-behavior: contain;
  display: flex;
  flex-direction: column;
  outline: 0;
  overflow: auto;
  --carousel-button-width: 36px;
  --carousel-button-height: 36px;
  --carousel-button-svg-width: 22px;
  --carousel-button-svg-height: 22px;
}

.fancybox__slide::before, .fancybox__slide::after {
  content: "";
  flex: 0 0 0;
  margin: auto;
}

@media all and (min-width: 1024px) {
  .fancybox__slide {
    padding: 64px 100px;
  }
}
.fancybox__content {
  margin: 0 env(safe-area-inset-right, 0px) 0 env(safe-area-inset-left, 0px);
  padding: 36px;
  color: var(--fancybox-content-color, #374151);
  background: var(--fancybox-content-bg, #fff);
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  z-index: 20;
}

.fancybox__content :focus:not(.carousel__button.is-close) {
  outline: thin dotted;
  box-shadow: none;
}

.fancybox__caption {
  align-self: center;
  max-width: 100%;
  margin: 0;
  padding: 1rem 0 0 0;
  line-height: 1.375;
  color: var(--fancybox-color, currentColor);
  visibility: visible;
  cursor: auto;
  flex-shrink: 0;
  overflow-wrap: anywhere;
}

.is-loading .fancybox__caption {
  visibility: hidden;
}

.fancybox__container > .carousel__dots {
  top: 100%;
  color: var(--fancybox-color, #fff);
}

.fancybox__nav .carousel__button {
  z-index: 40;
}

.fancybox__nav .carousel__button.is-next {
  right: 8px;
}

@media all and (min-width: 1024px) {
  .fancybox__nav .carousel__button.is-next {
    right: 40px;
  }
}
.fancybox__nav .carousel__button.is-prev {
  left: 8px;
}

@media all and (min-width: 1024px) {
  .fancybox__nav .carousel__button.is-prev {
    left: 40px;
  }
}
.carousel__button.is-close {
  position: absolute;
  top: 8px;
  right: 8px;
  top: calc(env(safe-area-inset-top, 0px) + 8px);
  right: calc(env(safe-area-inset-right, 0px) + 8px);
  z-index: 40;
}

@media all and (min-width: 1024px) {
  .carousel__button.is-close {
    right: 40px;
  }
}
.fancybox__content > .carousel__button.is-close {
  position: absolute;
  top: -40px;
  right: 0;
  color: var(--fancybox-color, #fff);
}

.fancybox__no-click, .fancybox__no-click button {
  pointer-events: none;
}

.fancybox__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  color: var(--fancybox-color, currentColor);
}

.fancybox__slide .fancybox__spinner {
  cursor: pointer;
  z-index: 1053;
}

.fancybox__spinner svg {
  animation: fancybox-rotate 2s linear infinite;
  transform-origin: center center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}

.fancybox__spinner svg circle {
  fill: none;
  stroke-width: 2.75;
  stroke-miterlimit: 10;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: fancybox-dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: currentColor;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes fancybox-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.fancybox__backdrop, .fancybox__caption, .fancybox__nav, .carousel__dots, .carousel__button.is-close {
  opacity: var(--fancybox-opacity, 1);
}

.fancybox__container.is-animated[aria-hidden=false] .fancybox__backdrop, .fancybox__container.is-animated[aria-hidden=false] .fancybox__caption, .fancybox__container.is-animated[aria-hidden=false] .fancybox__nav, .fancybox__container.is-animated[aria-hidden=false] .carousel__dots, .fancybox__container.is-animated[aria-hidden=false] .carousel__button.is-close {
  animation: 0.15s ease backwards fancybox-fadeIn;
}

.fancybox__container.is-animated.is-closing .fancybox__backdrop, .fancybox__container.is-animated.is-closing .fancybox__caption, .fancybox__container.is-animated.is-closing .fancybox__nav, .fancybox__container.is-animated.is-closing .carousel__dots, .fancybox__container.is-animated.is-closing .carousel__button.is-close {
  animation: 0.15s ease both fancybox-fadeOut;
}

.fancybox-fadeIn {
  animation: 0.15s ease both fancybox-fadeIn;
}

.fancybox-fadeOut {
  animation: 0.1s ease both fancybox-fadeOut;
}

.fancybox-zoomInUp {
  animation: 0.2s ease both fancybox-zoomInUp;
}

.fancybox-zoomOutDown {
  animation: 0.15s ease both fancybox-zoomOutDown;
}

.fancybox-throwOutUp {
  animation: 0.15s ease both fancybox-throwOutUp;
}

.fancybox-throwOutDown {
  animation: 0.15s ease both fancybox-throwOutDown;
}

@keyframes fancybox-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fancybox-fadeOut {
  to {
    opacity: 0;
  }
}
@keyframes fancybox-zoomInUp {
  from {
    transform: scale(0.97) translate3d(0, 16px, 0);
    opacity: 0;
  }
  to {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fancybox-zoomOutDown {
  to {
    transform: scale(0.97) translate3d(0, 16px, 0);
    opacity: 0;
  }
}
@keyframes fancybox-throwOutUp {
  to {
    transform: translate3d(0, -30%, 0);
    opacity: 0;
  }
}
@keyframes fancybox-throwOutDown {
  to {
    transform: translate3d(0, 30%, 0);
    opacity: 0;
  }
}
.fancybox__carousel .carousel__slide {
  scrollbar-width: thin;
  scrollbar-color: #ccc rgba(255, 255, 255, 0.1);
}

.fancybox__carousel .carousel__slide::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.fancybox__carousel .carousel__slide::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

.fancybox__carousel .carousel__slide::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 2px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

.fancybox__carousel.is-draggable .fancybox__slide, .fancybox__carousel.is-draggable .fancybox__slide .fancybox__content {
  cursor: move;
  cursor: grab;
}

.fancybox__carousel.is-dragging .fancybox__slide, .fancybox__carousel.is-dragging .fancybox__slide .fancybox__content {
  cursor: move;
  cursor: grabbing;
}

.fancybox__carousel .fancybox__slide .fancybox__content {
  cursor: auto;
}

.fancybox__carousel .fancybox__slide.can-zoom_in .fancybox__content {
  cursor: zoom-in;
}

.fancybox__carousel .fancybox__slide.can-zoom_out .fancybox__content {
  cursor: zoom-out;
}

.fancybox__carousel .fancybox__slide.is-draggable .fancybox__content {
  cursor: move;
  cursor: grab;
}

.fancybox__carousel .fancybox__slide.is-dragging .fancybox__content {
  cursor: move;
  cursor: grabbing;
}

.fancybox__image {
  transform-origin: 0 0;
  user-select: none;
  transition: none;
}

.has-image .fancybox__content {
  padding: 0;
  background: rgba(0, 0, 0, 0);
  min-height: 1px;
}

.is-closing .has-image .fancybox__content {
  overflow: visible;
}

.has-image[data-image-fit=contain] {
  overflow: visible;
  touch-action: none;
}

.has-image[data-image-fit=contain] .fancybox__content {
  flex-direction: row;
  flex-wrap: wrap;
}

.has-image[data-image-fit=contain] .fancybox__image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.has-image[data-image-fit=contain-w] {
  overflow-x: hidden;
  overflow-y: auto;
}

.has-image[data-image-fit=contain-w] .fancybox__content {
  min-height: auto;
}

.has-image[data-image-fit=contain-w] .fancybox__image {
  max-width: 100%;
  height: auto;
}

.has-image[data-image-fit=cover] {
  overflow: visible;
  touch-action: none;
}

.has-image[data-image-fit=cover] .fancybox__content {
  width: 100%;
  height: 100%;
}

.has-image[data-image-fit=cover] .fancybox__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fancybox__carousel .fancybox__slide.has-iframe .fancybox__content, .fancybox__carousel .fancybox__slide.has-map .fancybox__content, .fancybox__carousel .fancybox__slide.has-pdf .fancybox__content, .fancybox__carousel .fancybox__slide.has-video .fancybox__content, .fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
  max-width: 100%;
  flex-shrink: 1;
  min-height: 1px;
  overflow: visible;
}

.fancybox__carousel .fancybox__slide.has-iframe .fancybox__content, .fancybox__carousel .fancybox__slide.has-map .fancybox__content, .fancybox__carousel .fancybox__slide.has-pdf .fancybox__content {
  width: 100%;
  height: 80%;
}

.fancybox__carousel .fancybox__slide.has-video .fancybox__content, .fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
  width: 960px;
  height: 540px;
  max-width: 100%;
  max-height: 100%;
}

.fancybox__carousel .fancybox__slide.has-map .fancybox__content, .fancybox__carousel .fancybox__slide.has-pdf .fancybox__content, .fancybox__carousel .fancybox__slide.has-video .fancybox__content, .fancybox__carousel .fancybox__slide.has-html5video .fancybox__content {
  padding: 0;
  background: rgba(24, 24, 27, 0.9);
  color: #fff;
}

.fancybox__carousel .fancybox__slide.has-map .fancybox__content {
  background: #e5e3df;
}

.fancybox__html5video, .fancybox__iframe {
  border: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0);
}

.fancybox-placeholder {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.fancybox__thumbs {
  flex: 0 0 auto;
  position: relative;
  padding: 0px 3px;
  opacity: var(--fancybox-opacity, 1);
}

.fancybox__container.is-animated[aria-hidden=false] .fancybox__thumbs {
  animation: 0.15s ease-in backwards fancybox-fadeIn;
}

.fancybox__container.is-animated.is-closing .fancybox__thumbs {
  opacity: 0;
}

.fancybox__thumbs .carousel__slide {
  flex: 0 0 auto;
  width: var(--fancybox-thumbs-width, 96px);
  margin: 0;
  padding: 8px 3px;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  cursor: pointer;
}

.fancybox__thumbs .carousel__slide .fancybox__thumb::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-width: 5px;
  border-style: solid;
  border-color: var(--fancybox-accent-color, rgba(34, 213, 233, 0.96));
  opacity: 0;
  transition: opacity 0.15s ease;
  border-radius: var(--fancybox-thumbs-border-radius, 4px);
}

.fancybox__thumbs .carousel__slide.is-nav-selected .fancybox__thumb::after {
  opacity: 0.92;
}

.fancybox__thumbs .carousel__slide > * {
  pointer-events: none;
  user-select: none;
}

.fancybox__thumb {
  position: relative;
  width: 100%;
  padding-top: calc(100% / (var(--fancybox-thumbs-ratio, 1.5)));
  background-size: cover;
  background-position: center center;
  background-color: rgba(255, 255, 255, 0.1);
  background-repeat: no-repeat;
  border-radius: var(--fancybox-thumbs-border-radius, 4px);
}

.fancybox__toolbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;
  background: linear-gradient(to top, hsla(0deg, 0%, 0%, 0) 0%, hsla(0deg, 0%, 0%, 0.006) 8.1%, hsla(0deg, 0%, 0%, 0.021) 15.5%, hsla(0deg, 0%, 0%, 0.046) 22.5%, hsla(0deg, 0%, 0%, 0.077) 29%, hsla(0deg, 0%, 0%, 0.114) 35.3%, hsla(0deg, 0%, 0%, 0.155) 41.2%, hsla(0deg, 0%, 0%, 0.198) 47.1%, hsla(0deg, 0%, 0%, 0.242) 52.9%, hsla(0deg, 0%, 0%, 0.285) 58.8%, hsla(0deg, 0%, 0%, 0.326) 64.7%, hsla(0deg, 0%, 0%, 0.363) 71%, hsla(0deg, 0%, 0%, 0.394) 77.5%, hsla(0deg, 0%, 0%, 0.419) 84.5%, hsla(0deg, 0%, 0%, 0.434) 91.9%, hsla(0deg, 0%, 0%, 0.44) 100%);
  padding: 0;
  touch-action: none;
  display: flex;
  justify-content: space-between;
  --carousel-button-svg-width: 20px;
  --carousel-button-svg-height: 20px;
  opacity: var(--fancybox-opacity, 1);
  text-shadow: var(--fancybox-toolbar-text-shadow, 1px 1px 1px rgba(0, 0, 0, 0.4));
}

@media all and (min-width: 1024px) {
  .fancybox__toolbar {
    padding: 8px;
  }
}
.fancybox__container.is-animated[aria-hidden=false] .fancybox__toolbar {
  animation: 0.15s ease-in backwards fancybox-fadeIn;
}

.fancybox__container.is-animated.is-closing .fancybox__toolbar {
  opacity: 0;
}

.fancybox__toolbar__items {
  display: flex;
}

.fancybox__toolbar__items--left {
  margin-right: auto;
}

.fancybox__toolbar__items--center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.fancybox__toolbar__items--right {
  margin-left: auto;
}

@media (max-width: 640px) {
  .fancybox__toolbar__items--center:not(:last-child) {
    display: none;
  }
}
.fancybox__counter {
  min-width: 72px;
  padding: 0 10px;
  line-height: var(--carousel-button-height, 48px);
  text-align: center;
  font-size: 17px;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: subpixel-antialiased;
}

.fancybox__progress {
  background: var(--fancybox-accent-color, rgba(34, 213, 233, 0.96));
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 30;
  user-select: none;
}

.fancybox__container:fullscreen::backdrop {
  opacity: 0;
}

.fancybox__button--fullscreen g:nth-child(2) {
  display: none;
}

.fancybox__container:fullscreen .fancybox__button--fullscreen g:nth-child(1) {
  display: none;
}

.fancybox__container:fullscreen .fancybox__button--fullscreen g:nth-child(2) {
  display: block;
}

.fancybox__button--slideshow g:nth-child(2) {
  display: none;
}

.fancybox__container.has-slideshow .fancybox__button--slideshow g:nth-child(1) {
  display: none;
}

.fancybox__container.has-slideshow .fancybox__button--slideshow g:nth-child(2) {
  display: block;
}

.circ {
  opacity: 0;
  stroke-dasharray: 130;
  stroke-dashoffset: 130;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.tick {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  -webkit-transition: stroke-dashoffset 1s 0.5s ease-out;
  -moz-transition: stroke-dashoffset 1s 0.5s ease-out;
  -ms-transition: stroke-dashoffset 1s 0.5s ease-out;
  -o-transition: stroke-dashoffset 1s 0.5s ease-out;
  transition: stroke-dashoffset 1s 0.5s ease-out;
}

.drawn + svg .path {
  opacity: 1;
  stroke-dashoffset: 0;
}

/* Tipso Bubble Styles */
.tipso_bubble, .tipso_bubble > .tipso_arrow {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tipso_bubble {
  position: absolute;
  text-align: center;
  border-radius: 6px;
  z-index: 9999;
}

.tipso_style {
  cursor: help;
  border-bottom: 1px dotted;
}

.tipso_title {
  border-radius: 6px 6px 0 0;
}

.tipso_content {
  word-wrap: break-word;
  padding: 0.5em;
}

.tipso_bubble.tiny {
  font-size: 0.6rem;
}

.tipso_bubble.small {
  font-size: 0.8rem;
}

.tipso_bubble.default {
  font-size: 1rem;
}

.tipso_bubble.large {
  font-size: 1.2rem;
  width: 100%;
}

.tipso_bubble > .tipso_arrow {
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid;
  pointer-events: none;
}

.tipso_bubble.top > .tipso_arrow {
  border-top-color: #000;
  border-right-color: transparent;
  border-left-color: transparent;
  border-bottom-color: transparent;
  top: 100%;
  left: 50%;
  margin-left: -8px;
}

.tipso_bubble.bottom > .tipso_arrow {
  border-bottom-color: #000;
  border-right-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  bottom: 100%;
  left: 50%;
  margin-left: -8px;
}

.tipso_bubble.left > .tipso_arrow {
  border-left-color: #000;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: transparent;
  top: 50%;
  left: 100%;
  margin-top: -8px;
}

.tipso_bubble.right > .tipso_arrow {
  border-right-color: #000;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  top: 50%;
  right: 100%;
  margin-top: -8px;
}

.tipso_bubble .top_right_corner, .tipso_bubble.top_right_corner {
  border-bottom-left-radius: 0;
}

.tipso_bubble .bottom_right_corner, .tipso_bubble.bottom_right_corner {
  border-top-left-radius: 0;
}

.tipso_bubble .top_left_corner, .tipso_bubble.top_left_corner {
  border-bottom-right-radius: 0;
}

.tipso_bubble .bottom_left_corner, .tipso_bubble.bottom_left_corner {
  border-top-right-radius: 0;
}

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover, .glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

#preview {
  font-family: "Arial", sans-serif !important;
  font-size: 17px;
}
#preview #services {
  max-height: 250px;
  overflow: hidden;
}
#preview #date-range {
  padding: 0.5rem 1em;
  background-color: rgb(229, 231, 235);
  border-radius: 1rem;
}
#preview #date-range > .item .month-year {
  font-size: 0.8em;
}
#preview #date-range > .item .number {
  text-align: center;
  font-weight: bold;
}
#preview .open {
  max-height: 100% !important;
}
#preview #cancellation-policy {
  color: rgb(29, 78, 216);
}
#preview .service-item {
  color: #333;
}

.loader {
  width: 100px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid rgba(0, 0, 0, 0);
  border-radius: 50%;
  border-right-color: #000000;
  animation: l15 1s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}

.loader::after {
  margin: 8px;
  animation-duration: 3s;
}

@keyframes l15 {
  100% {
    transform: rotate(1turn);
  }
}
.loader-inner {
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-fixed-white-background {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.5);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9997;
}

/* HTML: <div class="loader"></div> */
.load-more-loader {
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 #000, -20px 0 rgba(0, 0, 0, 0.1333333333);
    background: #000;
  }
  33% {
    box-shadow: 20px 0 #000, -20px 0 rgba(0, 0, 0, 0.1333333333);
    background: rgba(0, 0, 0, 0.1333333333);
  }
  66% {
    box-shadow: 20px 0 rgba(0, 0, 0, 0.1333333333), -20px 0 #000;
    background: rgba(0, 0, 0, 0.1333333333);
  }
  100% {
    box-shadow: 20px 0 rgba(0, 0, 0, 0.1333333333), -20px 0 #000;
    background: #000;
  }
}
.success-alert {
  color: #008e25;
  font-weight: bold;
  padding: 0.5em;
  text-align: center;
}

.error-alert .error-inner {
  color: #fff;
  text-align: left;
  border: 1px solid red;
  background-color: #ff6767;
  padding: 0.3rem 1rem;
  font-weight: bold;
}

#steps > .item .head {
  background: linear-gradient(90deg, #f8f5f2, #e5ece9);
  border-radius: 1rem;
  padding: 1rem;
  font-size: 1.5rem;
}
#steps > .item .body {
  margin-top: 0.5em;
  padding: 1rem;
  border-width: 1px;
  border-radius: 1rem;
}

.next-button {
  padding: 0.7em 2em;
  font-weight: 700;
  border-width: 2px;
  border-radius: 1rem;
  border-color: #000;
  display: inline-block;
  text-align: center;
  background-color: unset !important;
}

#phone-code {
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-width: 1px 0px 1px 1px;
  border-radius: 1rem 0rem 0rem 1rem;
  border-color: #e5e7eb;
  outline: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

#preview-checkout {
  border-width: 1px;
  border-radius: 1rem;
}

.phone-with-code {
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-width: 1px 1px 1px 0px;
  border-radius: 0rem 1rem 1rem 0rem;
  border-color: #e5e7eb;
  outline: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

#preview {
  padding: 1rem;
  overflow-y: auto;
}
#preview #services {
  max-height: 250px;
  overflow: hidden;
}
#preview #date-range {
  padding: 0.5rem 1em;
  background-color: rgb(229, 231, 235);
  border-radius: 1rem;
}
#preview #date-range > .item .month-year {
  font-size: 0.8em;
}
#preview #date-range > .item .number {
  text-align: center;
  font-weight: bold;
}
#preview .open {
  max-height: 100% !important;
}
#preview #cancellation-policy {
  color: rgb(29, 78, 216);
}
#preview #terms-and-conditions-checkout-preview, #preview .terms-and-conditions-checkout, #preview .show-more {
  color: rgb(29, 78, 216);
  text-decoration: underline;
}

.terms-and-conditions-checkout {
  color: rgb(29, 78, 216);
  text-decoration: underline;
}

.link {
  color: #3870a5;
  text-decoration: underline;
}

#open-sign-in-popup {
  color: #3870a5;
  text-decoration: underline;
}

.payment-option-button {
  padding: 1rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 300;
  border-radius: 1rem;
  text-align: center;
  width: 100%;
  border: 1px solid #979797;
  cursor: pointer;
}
.payment-option-button:hover {
  background-color: #000;
  color: #fff;
}
.payment-option-button.active {
  background-color: #000;
  color: #fff;
}

#checkout-timeout-time-width {
  width: 100%;
  background: #000;
  height: 20px;
}

.top {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.18);
}
.top .language-switcher-wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.top .language-switcher {
  color: #222222;
  font-size: 14px;
  border-radius: 22px;
  padding: 12px;
}
.top .language-switcher:hover {
  background-color: #f7f7f7;
}
.top .user-top-menu-wrapper .user-top-menu {
  border: 2px solid #000;
  padding: 5px 5px 5px 12px;
  border-radius: 1rem;
  width: 77px;
  color: #717171;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 39px;
}
.top .user-top-menu-wrapper .user-top-menu .menu-bar {
  width: 30px;
}
.top .user-top-menu-wrapper .user-top-menu .menu-image {
  width: 30px;
  height: 30px;
}
.top .user-top-menu-wrapper .user-top-menu:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18) !important;
}
.top .user-top-menu-wrapper .sub-menu-wrapper {
  top: 42px;
  right: 0px;
  z-index: 9999;
}
.top .user-top-menu-wrapper .sub-menu-wrapper .sub-menu-inner {
  background-color: #fff;
  padding: 1em;
  box-shadow: 1px 1px 10px 1px rgba(217, 217, 217, 0.5607843137);
  border-radius: 10px;
  width: 240px;
}

.top-fixed-home {
  position: fixed;
  z-index: 99999;
  left: 0;
  right: 0;
}
.top-fixed-home .language-switcher-wrapper a {
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-fixed-home .language-switcher {
  color: #222222;
  font-size: 14px;
  border-radius: 22px;
  padding: 12px;
}
.top-fixed-home .language-switcher:hover {
  background-color: #f7f7f7;
}
.top-fixed-home .user-top-menu-wrapper {
  position: relative;
}
.top-fixed-home .user-top-menu-wrapper .user-top-menu {
  border: 1px solid #DDDDDD;
  padding: 5px 5px 5px 12px;
  border-radius: 21px;
  width: 77px;
  color: #717171;
  cursor: pointer;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-fixed-home .user-top-menu-wrapper .user-top-menu .menu-bar {
  width: 30px;
}
.top-fixed-home .user-top-menu-wrapper .user-top-menu .menu-image {
  width: 30px;
  height: 30px;
}
.top-fixed-home .user-top-menu-wrapper .user-top-menu:hover {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18) !important;
}
.top-fixed-home .user-top-menu-wrapper .sub-menu-wrapper {
  position: absolute;
  top: 45px;
  right: 0px;
  display: none;
  z-index: 9999;
}
.top-fixed-home .user-top-menu-wrapper .sub-menu-wrapper .sub-menu-inner {
  background-color: #fff;
  padding: 1em;
  box-shadow: 1px 1px 10px 1px rgba(217, 217, 217, 0.5607843137);
  border-radius: 10px;
  width: 240px;
}
.top-fixed-home .user-top-menu-wrapper .sub-menu-wrapper .sub-menu-inner .item:not(:last-child) {
  padding-bottom: 0.5em;
}

.has-submenu:hover .is-submenu {
  display: block;
}

.has-submenu {
  position: relative;
}
.has-submenu .is-submenu {
  position: absolute;
  z-index: 8000;
  display: none;
  background-color: #fff;
  padding: 1em;
  box-shadow: 1px 1px 10px 1px rgba(217, 217, 217, 0.5607843137);
  border-radius: 10px;
  width: 240px;
  right: 2px;
}

.enquiry-button {
  border: 2px solid #000;
  background-color: #fff;
  font-weight: bold;
  border-radius: 1.3em;
  padding: 0.7em 1em;
  font-size: 0.8em;
}
.enquiry-button:hover {
  background-color: #000;
  color: #fff;
}

input[type=checkbox] {
  background-size: 27px;
  background-position: center;
  background-repeat: no-repeat;
  appearance: none;
  border: 1px solid #333;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
  border-radius: 25%;
}
input[type=checkbox]:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  background-color: #000;
  color: #fff;
}

input[type=radio] {
  background-color: #000000;
  accent-color: #000;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.inline-alert-message {
  color: red;
  font-size: 1.1em;
  font-weight: bold;
}

.h-500 {
  height: 500px;
}

.h-700 {
  height: 700px;
}

.z-9000 {
  z-index: 9000;
}

@media (min-width: 768px) {
  .md-h-500 {
    height: 500px;
  }
}

@media (min-width: 768px) {
  .md-h-600 {
    height: 600px;
  }
}

.footer {
  background-color: #f4f4f4;
  border-top: 1px solid #DDDDDD;
}

.bot {
  background-color: #F7F7F7;
  color: #a09f9f;
}

#style-toggle, #satellite-toggle {
  border: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  position: absolute;
  z-index: 1;
  top: 20px;
  left: 20px;
  padding: 0.2rem 0.5rem;
  background-color: #fff;
  font-weight: bold;
  border-radius: 50px;
}

.style-toggle-active {
  color: #fff;
  background-color: #000000 !important;
}

#style-toggle:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.mbsc-popup .cell-booked:not(.mbsc-calendar-day-empty), #property-inline-calendar .cell-booked:not(.mbsc-calendar-day-empty), #property-datepicker .cell-booked:not(.mbsc-calendar-day-empty) {
  background: #777777;
}
.mbsc-popup .mbsc-hidden-content, #property-inline-calendar .mbsc-hidden-content, #property-datepicker .mbsc-hidden-content {
  display: none;
}
.mbsc-popup .cell-check-in:not(.mbsc-calendar-day-empty), #property-inline-calendar .cell-check-in:not(.mbsc-calendar-day-empty), #property-datepicker .cell-check-in:not(.mbsc-calendar-day-empty) {
  background: linear-gradient(to left top, #777777 50%, transparent 50%) no-repeat;
}
.mbsc-popup .cell-check-out:not(.mbsc-calendar-day-empty), #property-inline-calendar .cell-check-out:not(.mbsc-calendar-day-empty), #property-datepicker .cell-check-out:not(.mbsc-calendar-day-empty) {
  background: linear-gradient(to left top, transparent 50%, #777777 50%) no-repeat;
}

/* pages */
.air-cell {
  color: rgb(34, 34, 34);
  cursor: pointer;
  font-size: 0.875rem;
  line-height: 2.125rem;
  font-weight: 500;
}
.air-cell span {
  padding: 10px 13px;
}
.air-cell:hover span {
  border: 1.5px solid rgb(34, 34, 34);
  border-radius: 100%;
}

.mbsc-disabled .air-cell {
  color: rgb(173, 173, 173);
  text-decoration: line-through;
}
.mbsc-disabled .air-cell:hover span {
  border: unset;
  border-radius: unset;
}

.mbsc-disabled.checkout-only .mbsc-calendar-cell-text {
  color: rgb(106, 106, 106) !important;
  text-decoration: none !important;
  cursor: default !important;
}

.tip-cloud, .minimum_stay-cloud {
  position: absolute;
  z-index: 500;
  background-color: white;
  top: -38px;
  box-shadow: 1px 1px 10px 1px rgba(217, 217, 217, 0.5607843137);
  padding: 5px 12px;
  visibility: hidden;
  left: -20px;
}
.tip-cloud::after, .minimum_stay-cloud::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 59px;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}

.tip-cloud.active, .minimum_stay-cloud.active {
  visibility: visible !important;
  color: #0a0a0a;
}

.datepicker-wrapper {
  width: 100%;
  display: block;
  position: relative;
}

.property-availability-wrapper .title {
  font-size: 1.7em;
  font-weight: bold;
}

.cover-gradient-black-to-transparent {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 100%);
}

.minimum-stay-disabled, .checkout-only {
  cursor: pointer !important;
}
.minimum-stay-disabled .mbsc-calendar-cell-text, .checkout-only .mbsc-calendar-cell-text {
  color: #717171;
  opacity: 1;
  text-decoration: none !important;
}
.minimum-stay-disabled .mbsc-calendar-cell-text .active, .checkout-only .mbsc-calendar-cell-text .active {
  background: rgb(255, 255, 255) !important;
  color: rgb(113, 113, 113);
  border: 1px solid rgb(221, 221, 221);
}

/* global */
@font-face {
  font-family: "Butler Regular";
  src: url(vendor/fonts/Butler/Butler_Regular.otf);
}
@font-face {
  font-family: "Bradon Grotesque";
  src: url(vendor/fonts/Brandon_Grotesque/Brandon_thin.otf);
}
@font-face {
  font-family: travelstaytion-font;
  src: url(../fonts/travelstaytion-font.eot?et44l1);
  src: url(../fonts/travelstaytion-font.eot?et44l1#iefix) format("embedded-opentype"), url(../fonts/travelstaytion-font.ttf?et44l1) format("truetype");
  font-weight: 400;
  font-style: normal;
}
[class*=" icon-"],
[class^=icon-] {
  font-family: travelstaytion-font !important;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pagination span {
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1215686275);
  margin: 1rem 1rem;
  font-weight: bold;
}

body {
  font-family: "Lato", sans-serif;
}

p {
  white-space: break-spaces;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Butler Regular";
}

.search-sub {
  width: 720px;
}

select {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(189, 194, 197, 0.5960784314);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(189, 194, 197, 0.5960784314);
}

#faq-page {
  background: linear-gradient(45deg, #f7efe7, #e5ebe8);
}
#faq-page .question {
  background-color: #EDEDED;
}

#terms-and-conditions {
  background: linear-gradient(45deg, #f7efe7, #e5ebe8);
}
#terms-and-conditions .header.active {
  background-color: #fff;
}

#search .active {
  background-color: #000000;
  color: #fff;
}

.active {
  color: #fff;
}

.form-checkbox {
  color: #000;
}

.app-blue {
  color: #3271a7;
}

.mbsc-segmented {
  width: 100% !important;
}

.f-input, .f-select {
  padding: 0.75rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-width: 1px;
  border-radius: 1rem;
  border-color: #e5e7eb;
  outline: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  width: 100%;
}

.search-second-page-text {
  background-color: #f4f4f4;
}

.is-instant {
  background-color: #ffffff;
  color: red !important;
  border-radius: 50%;
  padding: 10px;
  width: 35px;
  height: 35px;
}

.next-button {
  cursor: pointer;
}

.tipso_bubble {
  font-size: 0.8em !important;
  z-index: 99999 !important;
}

.tipso_style {
  cursor: help;
  border-bottom: 0px dotted !important;
}

textarea:focus, input:focus {
  outline: none;
}

.gradient {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.66));
}

img {
  image-rendering: optimizeQuality;
}

.icon img {
  height: 36px !important;
}

.red-colorized {
  color: #cd5360;
}

.red-bullet-circle {
  font-size: 6px;
  color: #cd5360;
}

.blue-bullet-circle {
  font-size: 6px;
  color: #3271a7;
}

.product-cancellation, .product-offers {
  color: #3271a7;
}

.product-price-icon, .product-marker {
  color: #cd5360;
}

.error {
  color: #cd5360;
}

.instant-icon {
  color: #cd5360;
  font-size: 1.5em;
}

.slider-black-cover {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1803921569);
  z-index: 1;
}

.link {
  color: #3870a5;
  text-decoration: underline;
}

.fixed-black-background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9997;
}

.popup-wrapper .popup-inner {
  position: fixed;
  z-index: 9998;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.success-message {
  text-align: center;
  padding: 1em;
  background-color: #000;
  color: #fff;
  font-weight: bold;
}

.error-message {
  text-align: center;
  padding: 1em;
  background-color: #b60000;
  color: #fff;
  font-weight: bold;
}

.slider-arrow-left {
  position: absolute;
  right: -100px;
  top: 41%;
  cursor: pointer;
  display: block;
  z-index: 30;
  border-radius: 50%;
  transition: all 200ms ease-in-out;
}
.slider-arrow-left i {
  font-size: 34px;
  display: block;
  line-height: 80px;
  text-align: center;
  color: #fff;
  transition: all 200ms ease-in-out;
}

.slider-arrow-right {
  position: absolute;
  left: -100px;
  top: 41%;
  cursor: pointer;
  display: block;
  z-index: 30;
  border-radius: 50%;
  transition: all 200ms ease-in-out;
}
.slider-arrow-right i {
  font-size: 34px;
  display: block;
  line-height: 80px;
  text-align: center;
  color: #fff;
  transition: all 200ms ease-in-out;
}

.search-product-slide {
  overflow: hidden;
}
.search-product-slide:hover .slider-arrow-right {
  left: 19px;
}
.search-product-slide:hover .slider-arrow-left {
  right: 19px;
}

.mapboxgl-popup-content {
  padding: 0px !important;
}

#property-inline-calendar .mbsc-material.mbsc-calendar-month-title, #property-datepicker .mbsc-material.mbsc-calendar-month-title {
  text-align: center;
  color: #000 !important;
}
#property-inline-calendar .mbsc-disabled .mbsc-calendar-day-text, #property-datepicker .mbsc-disabled .mbsc-calendar-day-text {
  text-decoration: line-through;
}
#property-inline-calendar .mbsc-material.mbsc-calendar-day-text, #property-datepicker .mbsc-material.mbsc-calendar-day-text {
  font-size: 1em;
}
#property-inline-calendar .mbsc-selected.mbsc-calendar-day-text, #property-datepicker .mbsc-selected.mbsc-calendar-day-text {
  padding: 1em;
}
#property-inline-calendar .mbsc-material.mbsc-range-day::after, #property-inline-calendar .mbsc-material.mbsc-range-hover::before, #property-datepicker .mbsc-material.mbsc-range-day::after, #property-datepicker .mbsc-material.mbsc-range-hover::before {
  height: 2.1em;
}
#property-inline-calendar .mbsc-material.mbsc-range-day::after, #property-datepicker .mbsc-material.mbsc-range-day::after {
  background: rgb(247, 247, 247) !important;
}

.mbsc-calendar-label-background.mbsc-material {
  background-color: transparent !important;
}

.mbsc-calendar-label-text.mbsc-material {
  color: #cd5360;
  text-align: center;
}

.underline:after {
  content: "";
  position: absolute;
  height: 2px;
  background-color: #000;
  top: 58px;
  z-index: 9999;
  width: 70px;
  text-align: center;
}

.home-full-image {
  position: relative;
}

.home-full-image-inner {
  position: absolute;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.6901960784);
  color: #fff;
  padding: 2em 0em;
}
.home-full-image-inner h1 {
  color: #fff;
}

.search-product-slide .slick-arrow {
  background-color: rgba(255, 255, 255, 0.6509803922);
  width: 31px;
  height: 30px;
  padding: 5px 1px 1px 10px;
}

.white-svg {
  filter: invert(100%) sepia(0%) saturate(312%) hue-rotate(8deg) brightness(105%) contrast(101%);
}

.live-search-form .active {
  background-color: #000000;
  color: #fff;
}

.is-luxury {
  background-color: #ffffff;
  color: #fdc704 !important;
  border-radius: 50%;
  padding: 8px;
  width: 35px;
  height: 35px;
}

.map-activated {
  border: 3px solid;
}

@media only screen and (min-width: 320px) {
  .underline:after {
    left: 38%;
  }
}
@media only screen and (min-width: 425px) {
  .underline:after {
    left: 41%;
  }
}
@media only screen and (min-width: 768px) {
  .underline:after {
    left: 45%;
  }
}
@media only screen and (min-width: 1024px) {
  .underline:after {
    display: none;
  }
}
@media only screen and (min-width: 1440px) {
  .home-full-image-inner {
    top: 10vh;
    left: 15vw;
    width: 68vw;
    padding: 3em 0em;
  }
}
.icon-travel_bed-double:before {
  content: "\e97e";
}

.icon-travel_bath:before {
  content: "\e916";
}

.icon-travel_user:before {
  content: "\e923";
}

.icon-travel_info:before {
  content: "\e944";
}

.icon-travel_house:before {
  content: "\e957";
}

.icon-travel_wifi:before {
  content: "\e917";
}

.icon-travel_towels:before {
  content: "\e91f";
}

.icon-travel_check:before {
  content: "\e918";
}

.icon-travel_phone:before {
  content: "\e905";
}

.icon-travel_plus:before {
  content: "\e93e";
}

.selected-marker {
  color: #000;
}

.map-top {
  top: 82px;
}

button.mapboxgl-popup-close-button {
  font-size: 27px;
  margin-right: 5px;
  margin-top: 3px;
}

.mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
  background-color: #222222;
  border-color: #222222;
}