#steps{
  & > .item{
    .head{
      background: linear-gradient(90deg, #f8f5f2, #e5ece9);
      border-radius: 1rem;
      padding: 1rem;
      font-size: 1.5rem;
    }
    .body{
      margin-top: 0.5em;
      padding: 1rem;
      border-width: 1px;
      border-radius: 1rem;
    }
  }
}
.next-button{
  padding: 0.7em 2em;
  font-weight: 700;
  border-width: 2px;
  border-radius: 1rem;
  border-color: #000;
  display: inline-block;
  text-align: center;
  background-color: unset!important;
}
#phone-code {
  padding: .75rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-width: 1px 0px 1px 1px;
  border-radius: 1rem 0rem 0rem 1rem;
  border-color: #e5e7eb;
  outline: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
#preview-checkout{
  border-width: 1px;
  border-radius: 1rem;
}
.phone-with-code {
  padding: .75rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  border-width: 1px 1px 1px 0px;
  border-radius: 0rem 1rem 1rem 0rem;
  border-color: #e5e7eb;
  outline: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
#preview{
  padding: 1rem;
  overflow-y: auto;

  #services {
    max-height: 250px;
    overflow: hidden;
  }

  #date-range{
    padding: .5rem 1em;
    background-color: rgb(229, 231, 235);
    border-radius: 1rem;

    & > .item{
      .month-year {
        font-size: 0.8em;
      }
      .number{
        text-align: center;
        font-weight: bold;
      }
    }
  }

  .open{
    max-height: 100%!important;
  }

  #cancellation-policy{
    color: rgb(29, 78, 216)
  }

  #terms-and-conditions-checkout-preview, .terms-and-conditions-checkout, .show-more{
      color: rgb(29, 78, 216);
      text-decoration: underline;
  }
}
.terms-and-conditions-checkout{
  color: rgb(29, 78, 216);
  text-decoration: underline;
}
.link{
  color: #3870a5;
  text-decoration: underline;
}
#open-sign-in-popup{
  color: #3870a5;
  text-decoration: underline;
}
.payment-option-button{
  padding: 1rem 0.75rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 300;
  border-radius: 1rem;
  text-align: center;
  width: 100%;
  border: 1px solid #979797;
  cursor: pointer;

  &:hover{
    background-color: #000;
    color: #fff;
  }

  &.active{
    background-color: #000;
    color: #fff;
  }
}
#checkout-timeout-time-width {
  width: 100%;
  background: #000;
  height: 20px;
}