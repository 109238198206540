/*@font-face {*/
/*  font-family: 'Mobiscroll';*/
/*  src: url("icons_mobiscroll.woff?hhxpgt") format("woff"), url("icons_mobiscroll.woff") format("woff"), url("icons_mobiscroll.ttf?hhxpgt") format("truetype");*/
/*  font-weight: normal;*/
/*  font-style: normal; }*/

.mbsc-font-icon:before {
  font-family: 'Mobiscroll';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

/* Icons */
.mbsc-icon-arrow-down5::before {
  content: "\ea01"; }

.mbsc-icon-arrow-left6::before {
  content: "\ea02"; }

.mbsc-icon-arrow-right6::before {
  content: "\ea03"; }

.mbsc-icon-eye-blocked::before {
  content: "\ea04"; }

.mbsc-icon-eye::before {
  content: "\ea05"; }

.mbsc-icon-material-backspace::before {
  content: "\ea06"; }

.mbsc-icon-material-check-box-outline-blank::before {
  content: "\ea07"; }

.mbsc-icon-material-check::before {
  content: "\ea08"; }

.mbsc-icon-material-keyboard-arrow-down::before {
  content: "\ea09"; }

.mbsc-icon-material-keyboard-arrow-left::before {
  content: "\ea0a"; }

.mbsc-icon-material-keyboard-arrow-right::before {
  content: "\ea0b"; }

.mbsc-icon-material-keyboard-arrow-up::before {
  content: "\ea0c"; }

.mbsc-icon-material-star-outline::before {
  content: "\ea0d"; }

.mbsc-icon-material-star::before {
  content: "\ea0e"; }

.mbsc-icon-minus::before {
  content: "\ea0f"; }

.mbsc-icon-plus::before {
  content: "\ea10"; }

.mbsc-icon-star::before {
  content: "\ea11"; }

.mbsc-icon-star3::before {
  content: "\ea12"; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */

.mbsc-grid,
.mbsc-grid-unresp,
.mbsc-grid-fixed {
  width: 100%;
  padding-right: 1em;
  padding-left: 1em;
  margin-right: auto;
  margin-left: auto;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }
  .mbsc-grid *,
  .mbsc-grid-unresp *,
  .mbsc-grid-fixed * {
    -moz-box-sizing: border-box;
         box-sizing: border-box; }

.mbsc-form-grid {
  margin-top: .75em;
  margin-bottom: .75em; }

.mbsc-no-padding .mbsc-col,
.mbsc-no-padding [class*="mbsc-col-"],
.mbsc-form-grid .mbsc-col,
.mbsc-form-grid [class*="mbsc-col-"] {
  padding-right: 0;
  padding-left: 0; }

.mbsc-row {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  margin-right: -1em;
  margin-left: -1em; }

.mbsc-col {
  flex-basis: 0;
  -moz-box-flex: 1;
       flex-grow: 1;
  max-width: 100%; }

.mbsc-col-1,
.mbsc-col-2,
.mbsc-col-3,
.mbsc-col-4,
.mbsc-col-5,
.mbsc-col-6,
.mbsc-col-7,
.mbsc-col-8,
.mbsc-col-9,
.mbsc-col-10,
.mbsc-col-11,
.mbsc-col-12,
.mbsc-col,
.mbsc-col-auto,
.mbsc-col-sm-1,
.mbsc-col-sm-2,
.mbsc-col-sm-3,
.mbsc-col-sm-4,
.mbsc-col-sm-5,
.mbsc-col-sm-6,
.mbsc-col-sm-7,
.mbsc-col-sm-8,
.mbsc-col-sm-9,
.mbsc-col-sm-10,
.mbsc-col-sm-11,
.mbsc-col-sm-12,
.mbsc-col-sm,
.mbsc-col-sm-auto,
.mbsc-col-md-1,
.mbsc-col-md-2,
.mbsc-col-md-3,
.mbsc-col-md-4,
.mbsc-col-md-5,
.mbsc-col-md-6,
.mbsc-col-md-7,
.mbsc-col-md-8,
.mbsc-col-md-9,
.mbsc-col-md-10,
.mbsc-col-md-11,
.mbsc-col-md-12,
.mbsc-col-md,
.mbsc-col-md-auto,
.mbsc-col-lg-1,
.mbsc-col-lg-2,
.mbsc-col-lg-3,
.mbsc-col-lg-4,
.mbsc-col-lg-5,
.mbsc-col-lg-6,
.mbsc-col-lg-7,
.mbsc-col-lg-8,
.mbsc-col-lg-9,
.mbsc-col-lg-10,
.mbsc-col-lg-11,
.mbsc-col-lg-12,
.mbsc-col-lg,
.mbsc-col-lg-auto,
.mbsc-col-xl-1,
.mbsc-col-xl-2,
.mbsc-col-xl-3,
.mbsc-col-xl-4,
.mbsc-col-xl-5,
.mbsc-col-xl-6,
.mbsc-col-xl-7,
.mbsc-col-xl-8,
.mbsc-col-xl-9,
.mbsc-col-xl-10,
.mbsc-col-xl-11,
.mbsc-col-xl-12,
.mbsc-col-xl,
.mbsc-col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 1em;
  padding-left: 1em; }

.mbsc-col-1 {
  -moz-box-flex: 0;
       flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.mbsc-col-2 {
  -moz-box-flex: 0;
       flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.mbsc-col-3 {
  -moz-box-flex: 0;
       flex: 0 0 25%;
  max-width: 25%; }

.mbsc-col-4 {
  -moz-box-flex: 0;
       flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.mbsc-col-5 {
  -moz-box-flex: 0;
       flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.mbsc-col-6 {
  -moz-box-flex: 0;
       flex: 0 0 50%;
  max-width: 50%; }

.mbsc-col-7 {
  -moz-box-flex: 0;
       flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.mbsc-col-8 {
  -moz-box-flex: 0;
       flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.mbsc-col-9 {
  -moz-box-flex: 0;
       flex: 0 0 75%;
  max-width: 75%; }

.mbsc-col-10 {
  -moz-box-flex: 0;
       flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.mbsc-col-11 {
  -moz-box-flex: 0;
       flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.mbsc-col-12 {
  -moz-box-flex: 0;
       flex: 0 0 100%;
  max-width: 100%; }

.mbsc-col-auto {
  -moz-box-flex: 0;
       flex: 0 0 auto;
  width: auto;
  max-width: none; }

.mbsc-offset-1 {
  margin-left: 8.333333%; }

.mbsc-offset-2 {
  margin-left: 16.666667%; }

.mbsc-offset-3 {
  margin-left: 25%; }

.mbsc-offset-4 {
  margin-left: 33.333333%; }

.mbsc-offset-5 {
  margin-left: 41.666667%; }

.mbsc-offset-6 {
  margin-left: 50%; }

.mbsc-offset-7 {
  margin-left: 58.333333%; }

.mbsc-offset-8 {
  margin-left: 66.666667%; }

.mbsc-offset-9 {
  margin-left: 75%; }

.mbsc-offset-10 {
  margin-left: 83.333333%; }

.mbsc-offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .mbsc-grid.mbsc-grid-fixed {
    max-width: 540px; }
  .mbsc-grid .mbsc-col-sm {
    flex-basis: 0;
    -moz-box-flex: 1;
         flex-grow: 1;
    max-width: 100%; }
  .mbsc-grid .mbsc-col-sm-auto {
    -moz-box-flex: 0;
         flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .mbsc-grid .mbsc-col-sm-1 {
    -moz-box-flex: 0;
         flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .mbsc-grid .mbsc-col-sm-2 {
    -moz-box-flex: 0;
         flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .mbsc-grid .mbsc-col-sm-3 {
    -moz-box-flex: 0;
         flex: 0 0 25%;
    max-width: 25%; }
  .mbsc-grid .mbsc-col-sm-4 {
    -moz-box-flex: 0;
         flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .mbsc-grid .mbsc-col-sm-5 {
    -moz-box-flex: 0;
         flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .mbsc-grid .mbsc-col-sm-6 {
    -moz-box-flex: 0;
         flex: 0 0 50%;
    max-width: 50%; }
  .mbsc-grid .mbsc-col-sm-7 {
    -moz-box-flex: 0;
         flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .mbsc-grid .mbsc-col-sm-8 {
    -moz-box-flex: 0;
         flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .mbsc-grid .mbsc-col-sm-9 {
    -moz-box-flex: 0;
         flex: 0 0 75%;
    max-width: 75%; }
  .mbsc-grid .mbsc-col-sm-10 {
    -moz-box-flex: 0;
         flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .mbsc-grid .mbsc-col-sm-11 {
    -moz-box-flex: 0;
         flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .mbsc-grid .mbsc-col-sm-12 {
    -moz-box-flex: 0;
         flex: 0 0 100%;
    max-width: 100%; }
  .mbsc-grid .mbsc-offset-sm-0 {
    margin-left: 0; }
  .mbsc-grid .mbsc-offset-sm-1 {
    margin-left: 8.333333%; }
  .mbsc-grid .mbsc-offset-sm-2 {
    margin-left: 16.666667%; }
  .mbsc-grid .mbsc-offset-sm-3 {
    margin-left: 25%; }
  .mbsc-grid .mbsc-offset-sm-4 {
    margin-left: 33.333333%; }
  .mbsc-grid .mbsc-offset-sm-5 {
    margin-left: 41.666667%; }
  .mbsc-grid .mbsc-offset-sm-6 {
    margin-left: 50%; }
  .mbsc-grid .mbsc-offset-sm-7 {
    margin-left: 58.333333%; }
  .mbsc-grid .mbsc-offset-sm-8 {
    margin-left: 66.666667%; }
  .mbsc-grid .mbsc-offset-sm-9 {
    margin-left: 75%; }
  .mbsc-grid .mbsc-offset-sm-10 {
    margin-left: 83.333333%; }
  .mbsc-grid .mbsc-offset-sm-11 {
    margin-left: 91.666667%; }
  .mbsc-grid .mbsc-push-sm-0 {
    left: auto; }
  .mbsc-grid .mbsc-push-sm-1 {
    left: 8.33333333%; }
  .mbsc-grid .mbsc-push-sm-2 {
    left: 16.66666667%; }
  .mbsc-grid .mbsc-push-sm-3 {
    left: 25%; }
  .mbsc-grid .mbsc-push-sm-4 {
    left: 33.33333333%; }
  .mbsc-grid .mbsc-push-sm-5 {
    left: 41.66666667%; }
  .mbsc-grid .mbsc-push-sm-6 {
    left: 50%; }
  .mbsc-grid .mbsc-push-sm-7 {
    left: 58.33333333%; }
  .mbsc-grid .mbsc-push-sm-8 {
    left: 66.66666667%; }
  .mbsc-grid .mbsc-push-sm-9 {
    left: 75%; }
  .mbsc-grid .mbsc-push-sm-10 {
    left: 83.33333333%; }
  .mbsc-grid .mbsc-push-sm-11 {
    left: 91.66666667%; }
  .mbsc-grid .mbsc-push-sm-12 {
    left: 100%; }
  .mbsc-grid .mbsc-pull-sm-0 {
    right: auto; }
  .mbsc-grid .mbsc-pull-sm-1 {
    right: 8.33333333%; }
  .mbsc-grid .mbsc-pull-sm-2 {
    right: 16.66666667%; }
  .mbsc-grid .mbsc-pull-sm-3 {
    right: 25%; }
  .mbsc-grid .mbsc-pull-sm-4 {
    right: 33.33333333%; }
  .mbsc-grid .mbsc-pull-sm-5 {
    right: 41.66666667%; }
  .mbsc-grid .mbsc-pull-sm-6 {
    right: 50%; }
  .mbsc-grid .mbsc-pull-sm-7 {
    right: 58.33333333%; }
  .mbsc-grid .mbsc-pull-sm-8 {
    right: 66.66666667%; }
  .mbsc-grid .mbsc-pull-sm-9 {
    right: 75%; }
  .mbsc-grid .mbsc-pull-sm-10 {
    right: 83.33333333%; }
  .mbsc-grid .mbsc-pull-sm-11 {
    right: 91.66666667%; }
  .mbsc-grid .mbsc-pull-sm-12 {
    right: 100%; } }

@media (min-width: 768px) {
  .mbsc-grid.mbsc-grid-fixed {
    max-width: 720px; }
  .mbsc-grid .mbsc-col-md {
    flex-basis: 0;
    -moz-box-flex: 1;
         flex-grow: 1;
    max-width: 100%; }
  .mbsc-grid .mbsc-col-md-auto {
    -moz-box-flex: 0;
         flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .mbsc-grid .mbsc-col-md-1 {
    -moz-box-flex: 0;
         flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .mbsc-grid .mbsc-col-md-2 {
    -moz-box-flex: 0;
         flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .mbsc-grid .mbsc-col-md-3 {
    -moz-box-flex: 0;
         flex: 0 0 25%;
    max-width: 25%; }
  .mbsc-grid .mbsc-col-md-4 {
    -moz-box-flex: 0;
         flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .mbsc-grid .mbsc-col-md-5 {
    -moz-box-flex: 0;
         flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .mbsc-grid .mbsc-col-md-6 {
    -moz-box-flex: 0;
         flex: 0 0 50%;
    max-width: 50%; }
  .mbsc-grid .mbsc-col-md-7 {
    -moz-box-flex: 0;
         flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .mbsc-grid .mbsc-col-md-8 {
    -moz-box-flex: 0;
         flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .mbsc-grid .mbsc-col-md-9 {
    -moz-box-flex: 0;
         flex: 0 0 75%;
    max-width: 75%; }
  .mbsc-grid .mbsc-col-md-10 {
    -moz-box-flex: 0;
         flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .mbsc-grid .mbsc-col-md-11 {
    -moz-box-flex: 0;
         flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .mbsc-grid .mbsc-col-md-12 {
    -moz-box-flex: 0;
         flex: 0 0 100%;
    max-width: 100%; }
  .mbsc-grid .mbsc-offset-md-0 {
    margin-left: 0; }
  .mbsc-grid .mbsc-offset-md-1 {
    margin-left: 8.333333%; }
  .mbsc-grid .mbsc-offset-md-2 {
    margin-left: 16.666667%; }
  .mbsc-grid .mbsc-offset-md-3 {
    margin-left: 25%; }
  .mbsc-grid .mbsc-offset-md-4 {
    margin-left: 33.333333%; }
  .mbsc-grid .mbsc-offset-md-5 {
    margin-left: 41.666667%; }
  .mbsc-grid .mbsc-offset-md-6 {
    margin-left: 50%; }
  .mbsc-grid .mbsc-offset-md-7 {
    margin-left: 58.333333%; }
  .mbsc-grid .mbsc-offset-md-8 {
    margin-left: 66.666667%; }
  .mbsc-grid .mbsc-offset-md-9 {
    margin-left: 75%; }
  .mbsc-grid .mbsc-offset-md-10 {
    margin-left: 83.333333%; }
  .mbsc-grid .mbsc-offset-md-11 {
    margin-left: 91.666667%; }
  .mbsc-grid .mbsc-push-md-0 {
    left: auto; }
  .mbsc-grid .mbsc-push-md-1 {
    left: 8.33333333%; }
  .mbsc-grid .mbsc-push-md-2 {
    left: 16.66666667%; }
  .mbsc-grid .mbsc-push-md-3 {
    left: 25%; }
  .mbsc-grid .mbsc-push-md-4 {
    left: 33.33333333%; }
  .mbsc-grid .mbsc-push-md-5 {
    left: 41.66666667%; }
  .mbsc-grid .mbsc-push-md-6 {
    left: 50%; }
  .mbsc-grid .mbsc-push-md-7 {
    left: 58.33333333%; }
  .mbsc-grid .mbsc-push-md-8 {
    left: 66.66666667%; }
  .mbsc-grid .mbsc-push-md-9 {
    left: 75%; }
  .mbsc-grid .mbsc-push-md-10 {
    left: 83.33333333%; }
  .mbsc-grid .mbsc-push-md-11 {
    left: 91.66666667%; }
  .mbsc-grid .mbsc-push-md-12 {
    left: 100%; }
  .mbsc-grid .mbsc-pull-md-0 {
    right: auto; }
  .mbsc-grid .mbsc-pull-md-1 {
    right: 8.33333333%; }
  .mbsc-grid .mbsc-pull-md-2 {
    right: 16.66666667%; }
  .mbsc-grid .mbsc-pull-md-3 {
    right: 25%; }
  .mbsc-grid .mbsc-pull-md-4 {
    right: 33.33333333%; }
  .mbsc-grid .mbsc-pull-md-5 {
    right: 41.66666667%; }
  .mbsc-grid .mbsc-pull-md-6 {
    right: 50%; }
  .mbsc-grid .mbsc-pull-md-7 {
    right: 58.33333333%; }
  .mbsc-grid .mbsc-pull-md-8 {
    right: 66.66666667%; }
  .mbsc-grid .mbsc-pull-md-9 {
    right: 75%; }
  .mbsc-grid .mbsc-pull-md-10 {
    right: 83.33333333%; }
  .mbsc-grid .mbsc-pull-md-11 {
    right: 91.66666667%; }
  .mbsc-grid .mbsc-pull-md-12 {
    right: 100%; } }

@media (min-width: 992px) {
  .mbsc-grid.mbsc-grid-fixed {
    max-width: 960px; }
  .mbsc-grid .mbsc-col-lg {
    flex-basis: 0;
    -moz-box-flex: 1;
         flex-grow: 1;
    max-width: 100%; }
  .mbsc-grid .mbsc-col-lg-auto {
    -moz-box-flex: 0;
         flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .mbsc-grid .mbsc-col-lg-1 {
    -moz-box-flex: 0;
         flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .mbsc-grid .mbsc-col-lg-2 {
    -moz-box-flex: 0;
         flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .mbsc-grid .mbsc-col-lg-3 {
    -moz-box-flex: 0;
         flex: 0 0 25%;
    max-width: 25%; }
  .mbsc-grid .mbsc-col-lg-4 {
    -moz-box-flex: 0;
         flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .mbsc-grid .mbsc-col-lg-5 {
    -moz-box-flex: 0;
         flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .mbsc-grid .mbsc-col-lg-6 {
    -moz-box-flex: 0;
         flex: 0 0 50%;
    max-width: 50%; }
  .mbsc-grid .mbsc-col-lg-7 {
    -moz-box-flex: 0;
         flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .mbsc-grid .mbsc-col-lg-8 {
    -moz-box-flex: 0;
         flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .mbsc-grid .mbsc-col-lg-9 {
    -moz-box-flex: 0;
         flex: 0 0 75%;
    max-width: 75%; }
  .mbsc-grid .mbsc-col-lg-10 {
    -moz-box-flex: 0;
         flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .mbsc-grid .mbsc-col-lg-11 {
    -moz-box-flex: 0;
         flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .mbsc-grid .mbsc-col-lg-12 {
    -moz-box-flex: 0;
         flex: 0 0 100%;
    max-width: 100%; }
  .mbsc-grid .mbsc-offset-lg-0 {
    margin-left: 0; }
  .mbsc-grid .mbsc-offset-lg-1 {
    margin-left: 8.333333%; }
  .mbsc-grid .mbsc-offset-lg-2 {
    margin-left: 16.666667%; }
  .mbsc-grid .mbsc-offset-lg-3 {
    margin-left: 25%; }
  .mbsc-grid .mbsc-offset-lg-4 {
    margin-left: 33.333333%; }
  .mbsc-grid .mbsc-offset-lg-5 {
    margin-left: 41.666667%; }
  .mbsc-grid .mbsc-offset-lg-6 {
    margin-left: 50%; }
  .mbsc-grid .mbsc-offset-lg-7 {
    margin-left: 58.333333%; }
  .mbsc-grid .mbsc-offset-lg-8 {
    margin-left: 66.666667%; }
  .mbsc-grid .mbsc-offset-lg-9 {
    margin-left: 75%; }
  .mbsc-grid .mbsc-offset-lg-10 {
    margin-left: 83.333333%; }
  .mbsc-grid .mbsc-offset-lg-11 {
    margin-left: 91.666667%; }
  .mbsc-grid .mbsc-push-lg-0 {
    left: auto; }
  .mbsc-grid .mbsc-push-lg-1 {
    left: 8.33333333%; }
  .mbsc-grid .mbsc-push-lg-2 {
    left: 16.66666667%; }
  .mbsc-grid .mbsc-push-lg-3 {
    left: 25%; }
  .mbsc-grid .mbsc-push-lg-4 {
    left: 33.33333333%; }
  .mbsc-grid .mbsc-push-lg-5 {
    left: 41.66666667%; }
  .mbsc-grid .mbsc-push-lg-6 {
    left: 50%; }
  .mbsc-grid .mbsc-push-lg-7 {
    left: 58.33333333%; }
  .mbsc-grid .mbsc-push-lg-8 {
    left: 66.66666667%; }
  .mbsc-grid .mbsc-push-lg-9 {
    left: 75%; }
  .mbsc-grid .mbsc-push-lg-10 {
    left: 83.33333333%; }
  .mbsc-grid .mbsc-push-lg-11 {
    left: 91.66666667%; }
  .mbsc-grid .mbsc-push-lg-12 {
    left: 100%; }
  .mbsc-grid .mbsc-pull-lg-0 {
    right: auto; }
  .mbsc-grid .mbsc-pull-lg-1 {
    right: 8.33333333%; }
  .mbsc-grid .mbsc-pull-lg-2 {
    right: 16.66666667%; }
  .mbsc-grid .mbsc-pull-lg-3 {
    right: 25%; }
  .mbsc-grid .mbsc-pull-lg-4 {
    right: 33.33333333%; }
  .mbsc-grid .mbsc-pull-lg-5 {
    right: 41.66666667%; }
  .mbsc-grid .mbsc-pull-lg-6 {
    right: 50%; }
  .mbsc-grid .mbsc-pull-lg-7 {
    right: 58.33333333%; }
  .mbsc-grid .mbsc-pull-lg-8 {
    right: 66.66666667%; }
  .mbsc-grid .mbsc-pull-lg-9 {
    right: 75%; }
  .mbsc-grid .mbsc-pull-lg-10 {
    right: 83.33333333%; }
  .mbsc-grid .mbsc-pull-lg-11 {
    right: 91.66666667%; }
  .mbsc-grid .mbsc-pull-lg-12 {
    right: 100%; } }

@media (min-width: 1200px) {
  .mbsc-grid.mbsc-grid-fixed {
    max-width: 1140px; }
  .mbsc-grid .mbsc-col-xl {
    flex-basis: 0;
    -moz-box-flex: 1;
         flex-grow: 1;
    max-width: 100%; }
  .mbsc-grid .mbsc-col-xl-auto {
    -moz-box-flex: 0;
         flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .mbsc-grid .mbsc-col-xl-1 {
    -moz-box-flex: 0;
         flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .mbsc-grid .mbsc-col-xl-2 {
    -moz-box-flex: 0;
         flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .mbsc-grid .mbsc-col-xl-3 {
    -moz-box-flex: 0;
         flex: 0 0 25%;
    max-width: 25%; }
  .mbsc-grid .mbsc-col-xl-4 {
    -moz-box-flex: 0;
         flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .mbsc-grid .mbsc-col-xl-5 {
    -moz-box-flex: 0;
         flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .mbsc-grid .mbsc-col-xl-6 {
    -moz-box-flex: 0;
         flex: 0 0 50%;
    max-width: 50%; }
  .mbsc-grid .mbsc-col-xl-7 {
    -moz-box-flex: 0;
         flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .mbsc-grid .mbsc-col-xl-8 {
    -moz-box-flex: 0;
         flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .mbsc-grid .mbsc-col-xl-9 {
    -moz-box-flex: 0;
         flex: 0 0 75%;
    max-width: 75%; }
  .mbsc-grid .mbsc-col-xl-10 {
    -moz-box-flex: 0;
         flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .mbsc-grid .mbsc-col-xl-11 {
    -moz-box-flex: 0;
         flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .mbsc-grid .mbsc-col-xl-12 {
    -moz-box-flex: 0;
         flex: 0 0 100%;
    max-width: 100%; }
  .mbsc-grid .mbsc-offset-xl-0 {
    margin-left: 0; }
  .mbsc-grid .mbsc-offset-xl-1 {
    margin-left: 8.333333%; }
  .mbsc-grid .mbsc-offset-xl-2 {
    margin-left: 16.666667%; }
  .mbsc-grid .mbsc-offset-xl-3 {
    margin-left: 25%; }
  .mbsc-grid .mbsc-offset-xl-4 {
    margin-left: 33.333333%; }
  .mbsc-grid .mbsc-offset-xl-5 {
    margin-left: 41.666667%; }
  .mbsc-grid .mbsc-offset-xl-6 {
    margin-left: 50%; }
  .mbsc-grid .mbsc-offset-xl-7 {
    margin-left: 58.333333%; }
  .mbsc-grid .mbsc-offset-xl-8 {
    margin-left: 66.666667%; }
  .mbsc-grid .mbsc-offset-xl-9 {
    margin-left: 75%; }
  .mbsc-grid .mbsc-offset-xl-10 {
    margin-left: 83.333333%; }
  .mbsc-grid .mbsc-offset-xl-11 {
    margin-left: 91.666667%; }
  .mbsc-grid .mbsc-push-xl-0 {
    left: auto; }
  .mbsc-grid .mbsc-push-xl-1 {
    left: 8.33333333%; }
  .mbsc-grid .mbsc-push-xl-2 {
    left: 16.66666667%; }
  .mbsc-grid .mbsc-push-xl-3 {
    left: 25%; }
  .mbsc-grid .mbsc-push-xl-4 {
    left: 33.33333333%; }
  .mbsc-grid .mbsc-push-xl-5 {
    left: 41.66666667%; }
  .mbsc-grid .mbsc-push-xl-6 {
    left: 50%; }
  .mbsc-grid .mbsc-push-xl-7 {
    left: 58.33333333%; }
  .mbsc-grid .mbsc-push-xl-8 {
    left: 66.66666667%; }
  .mbsc-grid .mbsc-push-xl-9 {
    left: 75%; }
  .mbsc-grid .mbsc-push-xl-10 {
    left: 83.33333333%; }
  .mbsc-grid .mbsc-push-xl-11 {
    left: 91.66666667%; }
  .mbsc-grid .mbsc-push-xl-12 {
    left: 100%; }
  .mbsc-grid .mbsc-pull-xl-0 {
    right: auto; }
  .mbsc-grid .mbsc-pull-xl-1 {
    right: 8.33333333%; }
  .mbsc-grid .mbsc-pull-xl-2 {
    right: 16.66666667%; }
  .mbsc-grid .mbsc-pull-xl-3 {
    right: 25%; }
  .mbsc-grid .mbsc-pull-xl-4 {
    right: 33.33333333%; }
  .mbsc-grid .mbsc-pull-xl-5 {
    right: 41.66666667%; }
  .mbsc-grid .mbsc-pull-xl-6 {
    right: 50%; }
  .mbsc-grid .mbsc-pull-xl-7 {
    right: 58.33333333%; }
  .mbsc-grid .mbsc-pull-xl-8 {
    right: 66.66666667%; }
  .mbsc-grid .mbsc-pull-xl-9 {
    right: 75%; }
  .mbsc-grid .mbsc-pull-xl-10 {
    right: 83.33333333%; }
  .mbsc-grid .mbsc-pull-xl-11 {
    right: 91.66666667%; }
  .mbsc-grid .mbsc-pull-xl-12 {
    right: 100%; } }

.mbsc-align-items-start {
  -moz-box-align: start !important;
       align-items: flex-start !important; }

.mbsc-align-items-center {
  -moz-box-align: center !important;
       align-items: center !important; }

.mbsc-align-items-end {
  -moz-box-align: end !important;
       align-items: flex-end !important; }

.mbsc-justify-content-start {
  -moz-box-pack: start !important;
       justify-content: flex-start !important; }

.mbsc-justify-content-center {
  -moz-box-pack: center !important;
       justify-content: center !important; }

.mbsc-justify-content-end {
  -moz-box-pack: end !important;
       justify-content: flex-end !important; }

.mbsc-justify-content-around {
  justify-content: space-around !important; }

.mbsc-justify-content-between {
  -moz-box-pack: justify !important;
       justify-content: space-between !important; }

.mbsc-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
  line-height: 1.5em;
  text-align: center;
  -moz-box-flex: 0;
       flex: 0 0 auto; }

.mbsc-icon > svg {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  fill: currentColor; }

:root {
  --mbsc-safe-top: 0;
  --mbsc-safe-right: 0;
  --mbsc-safe-bottom: 0;
  --mbsc-safe-left: 0; }
  @supports (top: constant(safe-area-inset-top)) {
    :root {
      --mbsc-safe-top: constant(safe-area-inset-top);
      --mbsc-safe-right: constant(safe-area-inset-right);
      --mbsc-safe-bottom: constant(safe-area-inset-bottom);
      --mbsc-safe-left: constant(safe-area-inset-left); } }
  @supports (top: env(safe-area-inset-top)) {
    :root {
      --mbsc-safe-top: env(safe-area-inset-top);
      --mbsc-safe-right: env(safe-area-inset-right);
      --mbsc-safe-bottom: env(safe-area-inset-bottom);
      --mbsc-safe-left: env(safe-area-inset-left); } }

.mbsc-font {
  font-family: -apple-system, 'Segoe UI', Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%; }

.mbsc-reset {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.mbsc-resize {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: -1; }

.mbsc-resize-i {
  position: absolute;
  left: 0;
  top: 0; }

.mbsc-resize-y {
  width: 200%;
  height: 200%; }

.mbsc-hidden {
  visibility: hidden; }

.mbsc-ltr {
  direction: ltr; }

.mbsc-rtl {
  direction: rtl; }

.mbsc-ripple {
  background: currentColor;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 1000em;
  pointer-events: none;
  -webkit-transform: scale(0);
          transform: scale(0); }

/* Flex util classes */
.mbsc-flex,
.mbsc-flex-col {
  display: -moz-box;
  display: flex; }

.mbsc-flex-col {
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column; }

.mbsc-flex-1-1 {
  -moz-box-flex: 1;
       flex: 1 1 auto; }

.mbsc-flex-1-0 {
  -moz-box-flex: 1;
       flex: 1 0 auto; }

.mbsc-flex-1-0-0 {
  -moz-box-flex: 1;
       flex: 1 0 0; }

/* IE11 hack, where flex-basis auto does not work correctly */
@media all and (-ms-high-contrast: none) {
  .mbsc-flex-1-0-0 {
    -moz-box-flex: 1;
         flex: 1 0 auto; } }

.mbsc-flex-none {
  -moz-box-flex: 0;
       flex: none; }

@media (-webkit-min-device-pixel-ratio: 2) {
  .mbsc-hb,
  .mbsc-hb:before,
  .mbsc-hb:after {
    border-width: .5px !important; } }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material.mbsc-button {
  min-width: 4.571429em;
  padding: .428572em 1.142858em;
  border-radius: .285715em;
  font-size: .875em;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.714286em;
  transition: box-shadow .2s ease-out, background-color .2s ease-out;
  margin: .5em; }

.mbsc-material.mbsc-button-outline {
  border: 1px solid; }

.mbsc-material.mbsc-button .mbsc-icon {
  font-size: 1.142858em; }

.mbsc-material.mbsc-icon-button {
  min-width: 0;
  padding: .5em;
  border-radius: 2em;
  font-size: 1em;
  line-height: normal; }

.mbsc-material.mbsc-icon-button .mbsc-icon {
  width: 1.5em;
  height: 1.5em;
  font-size: 1em; }

.mbsc-material.mbsc-button:disabled, .mbsc-material.mbsc-button.mbsc-disabled {
  opacity: .3; }

.mbsc-material.mbsc-ltr.mbsc-button-icon-start {
  padding-right: .5em;
  margin-left: -.25em; }

.mbsc-material.mbsc-ltr.mbsc-button-icon-end {
  padding-left: .5em;
  margin-right: -.25em; }

.mbsc-material.mbsc-rtl.mbsc-button-icon-start {
  padding-left: .5em;
  margin-right: -.25em; }

.mbsc-material.mbsc-rtl.mbsc-button-icon-end {
  padding-right: .5em;
  margin-left: -.25em; }

.mbsc-material .mbsc-button-group,
.mbsc-material .mbsc-button-group-justified {
  margin: .5em; }

.mbsc-material .mbsc-button-group-block {
  margin: .5em 1em; }

.mbsc-material {
  /* Flat buttons */
  /* Outline buttons */
  /* Predefined colors */ }
  .mbsc-material.mbsc-button-standard {
    background: #cfcfcf;
    color: black;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
    .mbsc-material.mbsc-button-standard.mbsc-hover {
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
    .mbsc-material.mbsc-button-standard.mbsc-focus, .mbsc-material.mbsc-button-standard.mbsc-active {
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mbsc-material.mbsc-button-flat, .mbsc-material.mbsc-button-outline {
    color: black; }
    .mbsc-material.mbsc-button-flat.mbsc-hover, .mbsc-material.mbsc-button-flat.mbsc-active, .mbsc-material.mbsc-button-outline.mbsc-hover, .mbsc-material.mbsc-button-outline.mbsc-active {
      background: rgba(0, 0, 0, 0.2); }
    .mbsc-material.mbsc-button-flat.mbsc-focus, .mbsc-material.mbsc-button-outline.mbsc-focus {
      background: rgba(0, 0, 0, 0.3); }
  .mbsc-material.mbsc-button-outline {
    border-color: black; }
  .mbsc-material.mbsc-button-primary.mbsc-button-standard {
    background: #3f97f6;
    color: #fff; }
  .mbsc-material.mbsc-button-secondary.mbsc-button-standard {
    background: #90979E;
    color: #fff; }
  .mbsc-material.mbsc-button-success.mbsc-button-standard {
    background: #43BE5F;
    color: #fff; }
  .mbsc-material.mbsc-button-danger.mbsc-button-standard {
    background: #f5504e;
    color: #fff; }
  .mbsc-material.mbsc-button-warning.mbsc-button-standard {
    background: #f8b042;
    color: #fff; }
  .mbsc-material.mbsc-button-info.mbsc-button-standard {
    background: #5BB7C5;
    color: #fff; }
  .mbsc-material.mbsc-button-dark.mbsc-button-standard {
    background: #47494A;
    color: #fff; }
  .mbsc-material.mbsc-button-light.mbsc-button-standard {
    background: #fff;
    color: #303030; }
  .mbsc-material.mbsc-button-primary.mbsc-button-flat {
    color: #3f97f6; }
    .mbsc-material.mbsc-button-primary.mbsc-button-flat.mbsc-hover, .mbsc-material.mbsc-button-primary.mbsc-button-flat.mbsc-active {
      background: rgba(63, 151, 246, 0.2); }
    .mbsc-material.mbsc-button-primary.mbsc-button-flat.mbsc-focus {
      background: rgba(63, 151, 246, 0.3); }
  .mbsc-material.mbsc-button-secondary.mbsc-button-flat {
    color: #90979E; }
    .mbsc-material.mbsc-button-secondary.mbsc-button-flat.mbsc-hover, .mbsc-material.mbsc-button-secondary.mbsc-button-flat.mbsc-active {
      background: rgba(144, 151, 158, 0.2); }
    .mbsc-material.mbsc-button-secondary.mbsc-button-flat.mbsc-focus {
      background: rgba(144, 151, 158, 0.3); }
  .mbsc-material.mbsc-button-success.mbsc-button-flat {
    color: #43BE5F; }
    .mbsc-material.mbsc-button-success.mbsc-button-flat.mbsc-hover, .mbsc-material.mbsc-button-success.mbsc-button-flat.mbsc-active {
      background: rgba(67, 190, 95, 0.2); }
    .mbsc-material.mbsc-button-success.mbsc-button-flat.mbsc-focus {
      background: rgba(67, 190, 95, 0.3); }
  .mbsc-material.mbsc-button-danger.mbsc-button-flat {
    color: #f5504e; }
    .mbsc-material.mbsc-button-danger.mbsc-button-flat.mbsc-hover, .mbsc-material.mbsc-button-danger.mbsc-button-flat.mbsc-active {
      background: rgba(245, 80, 78, 0.2); }
    .mbsc-material.mbsc-button-danger.mbsc-button-flat.mbsc-focus {
      background: rgba(245, 80, 78, 0.3); }
  .mbsc-material.mbsc-button-warning.mbsc-button-flat {
    color: #f8b042; }
    .mbsc-material.mbsc-button-warning.mbsc-button-flat.mbsc-hover, .mbsc-material.mbsc-button-warning.mbsc-button-flat.mbsc-active {
      background: rgba(248, 176, 66, 0.2); }
    .mbsc-material.mbsc-button-warning.mbsc-button-flat.mbsc-focus {
      background: rgba(248, 176, 66, 0.3); }
  .mbsc-material.mbsc-button-info.mbsc-button-flat {
    color: #5BB7C5; }
    .mbsc-material.mbsc-button-info.mbsc-button-flat.mbsc-hover, .mbsc-material.mbsc-button-info.mbsc-button-flat.mbsc-active {
      background: rgba(91, 183, 197, 0.2); }
    .mbsc-material.mbsc-button-info.mbsc-button-flat.mbsc-focus {
      background: rgba(91, 183, 197, 0.3); }
  .mbsc-material.mbsc-button-dark.mbsc-button-flat {
    color: #47494A; }
    .mbsc-material.mbsc-button-dark.mbsc-button-flat.mbsc-hover, .mbsc-material.mbsc-button-dark.mbsc-button-flat.mbsc-active {
      background: rgba(71, 73, 74, 0.2); }
    .mbsc-material.mbsc-button-dark.mbsc-button-flat.mbsc-focus {
      background: rgba(71, 73, 74, 0.3); }
  .mbsc-material.mbsc-button-light.mbsc-button-flat {
    color: #cccccc; }
    .mbsc-material.mbsc-button-light.mbsc-button-flat.mbsc-hover, .mbsc-material.mbsc-button-light.mbsc-button-flat.mbsc-active {
      background: rgba(255, 255, 255, 0.2); }
    .mbsc-material.mbsc-button-light.mbsc-button-flat.mbsc-focus {
      background: rgba(255, 255, 255, 0.3); }
  .mbsc-material.mbsc-button-primary.mbsc-button-outline {
    border-color: #3f97f6;
    color: #3f97f6; }
    .mbsc-material.mbsc-button-primary.mbsc-button-outline.mbsc-hover, .mbsc-material.mbsc-button-primary.mbsc-button-outline.mbsc-active {
      background: rgba(63, 151, 246, 0.2); }
    .mbsc-material.mbsc-button-primary.mbsc-button-outline.mbsc-focus {
      background: rgba(63, 151, 246, 0.3); }
  .mbsc-material.mbsc-button-secondary.mbsc-button-outline {
    border-color: #90979E;
    color: #90979E; }
    .mbsc-material.mbsc-button-secondary.mbsc-button-outline.mbsc-hover, .mbsc-material.mbsc-button-secondary.mbsc-button-outline.mbsc-active {
      background: rgba(144, 151, 158, 0.2); }
    .mbsc-material.mbsc-button-secondary.mbsc-button-outline.mbsc-focus {
      background: rgba(144, 151, 158, 0.3); }
  .mbsc-material.mbsc-button-success.mbsc-button-outline {
    border-color: #43BE5F;
    color: #43BE5F; }
    .mbsc-material.mbsc-button-success.mbsc-button-outline.mbsc-hover, .mbsc-material.mbsc-button-success.mbsc-button-outline.mbsc-active {
      background: rgba(67, 190, 95, 0.2); }
    .mbsc-material.mbsc-button-success.mbsc-button-outline.mbsc-focus {
      background: rgba(67, 190, 95, 0.3); }
  .mbsc-material.mbsc-button-danger.mbsc-button-outline {
    border-color: #f5504e;
    color: #f5504e; }
    .mbsc-material.mbsc-button-danger.mbsc-button-outline.mbsc-hover, .mbsc-material.mbsc-button-danger.mbsc-button-outline.mbsc-active {
      background: rgba(245, 80, 78, 0.2); }
    .mbsc-material.mbsc-button-danger.mbsc-button-outline.mbsc-focus {
      background: rgba(245, 80, 78, 0.3); }
  .mbsc-material.mbsc-button-warning.mbsc-button-outline {
    border-color: #f8b042;
    color: #f8b042; }
    .mbsc-material.mbsc-button-warning.mbsc-button-outline.mbsc-hover, .mbsc-material.mbsc-button-warning.mbsc-button-outline.mbsc-active {
      background: rgba(248, 176, 66, 0.2); }
    .mbsc-material.mbsc-button-warning.mbsc-button-outline.mbsc-focus {
      background: rgba(248, 176, 66, 0.3); }
  .mbsc-material.mbsc-button-info.mbsc-button-outline {
    border-color: #5BB7C5;
    color: #5BB7C5; }
    .mbsc-material.mbsc-button-info.mbsc-button-outline.mbsc-hover, .mbsc-material.mbsc-button-info.mbsc-button-outline.mbsc-active {
      background: rgba(91, 183, 197, 0.2); }
    .mbsc-material.mbsc-button-info.mbsc-button-outline.mbsc-focus {
      background: rgba(91, 183, 197, 0.3); }
  .mbsc-material.mbsc-button-dark.mbsc-button-outline {
    border-color: #47494A;
    color: #47494A; }
    .mbsc-material.mbsc-button-dark.mbsc-button-outline.mbsc-hover, .mbsc-material.mbsc-button-dark.mbsc-button-outline.mbsc-active {
      background: rgba(71, 73, 74, 0.2); }
    .mbsc-material.mbsc-button-dark.mbsc-button-outline.mbsc-focus {
      background: rgba(71, 73, 74, 0.3); }
  .mbsc-material.mbsc-button-light.mbsc-button-outline {
    border-color: #cccccc;
    color: #cccccc; }
    .mbsc-material.mbsc-button-light.mbsc-button-outline.mbsc-hover, .mbsc-material.mbsc-button-light.mbsc-button-outline.mbsc-active {
      background: rgba(255, 255, 255, 0.2); }
    .mbsc-material.mbsc-button-light.mbsc-button-outline.mbsc-focus {
      background: rgba(255, 255, 255, 0.3); }

.mbsc-material-dark {
  /* Flat buttons */
  /* Outline buttons */
  /* Predefined colors */ }
  .mbsc-material-dark.mbsc-button-standard {
    background: #2b2b2b;
    color: white;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
    .mbsc-material-dark.mbsc-button-standard.mbsc-hover {
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12); }
    .mbsc-material-dark.mbsc-button-standard.mbsc-focus, .mbsc-material-dark.mbsc-button-standard.mbsc-active {
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mbsc-material-dark.mbsc-button-flat, .mbsc-material-dark.mbsc-button-outline {
    color: white; }
    .mbsc-material-dark.mbsc-button-flat.mbsc-hover, .mbsc-material-dark.mbsc-button-flat.mbsc-active, .mbsc-material-dark.mbsc-button-outline.mbsc-hover, .mbsc-material-dark.mbsc-button-outline.mbsc-active {
      background: rgba(255, 255, 255, 0.2); }
    .mbsc-material-dark.mbsc-button-flat.mbsc-focus, .mbsc-material-dark.mbsc-button-outline.mbsc-focus {
      background: rgba(255, 255, 255, 0.3); }
  .mbsc-material-dark.mbsc-button-outline {
    border-color: white; }
  .mbsc-material-dark.mbsc-button-primary.mbsc-button-standard {
    background: #3f97f6;
    color: #000; }
  .mbsc-material-dark.mbsc-button-secondary.mbsc-button-standard {
    background: #90979E;
    color: #000; }
  .mbsc-material-dark.mbsc-button-success.mbsc-button-standard {
    background: #43BE5F;
    color: #000; }
  .mbsc-material-dark.mbsc-button-danger.mbsc-button-standard {
    background: #f5504e;
    color: #000; }
  .mbsc-material-dark.mbsc-button-warning.mbsc-button-standard {
    background: #f8b042;
    color: #000; }
  .mbsc-material-dark.mbsc-button-info.mbsc-button-standard {
    background: #5BB7C5;
    color: #000; }
  .mbsc-material-dark.mbsc-button-dark.mbsc-button-standard {
    background: #47494A;
    color: #000; }
  .mbsc-material-dark.mbsc-button-light.mbsc-button-standard {
    background: #fff;
    color: #fff; }
  .mbsc-material-dark.mbsc-button-primary.mbsc-button-flat {
    color: #3f97f6; }
    .mbsc-material-dark.mbsc-button-primary.mbsc-button-flat.mbsc-hover, .mbsc-material-dark.mbsc-button-primary.mbsc-button-flat.mbsc-active {
      background: rgba(63, 151, 246, 0.2); }
    .mbsc-material-dark.mbsc-button-primary.mbsc-button-flat.mbsc-focus {
      background: rgba(63, 151, 246, 0.3); }
  .mbsc-material-dark.mbsc-button-secondary.mbsc-button-flat {
    color: #90979E; }
    .mbsc-material-dark.mbsc-button-secondary.mbsc-button-flat.mbsc-hover, .mbsc-material-dark.mbsc-button-secondary.mbsc-button-flat.mbsc-active {
      background: rgba(144, 151, 158, 0.2); }
    .mbsc-material-dark.mbsc-button-secondary.mbsc-button-flat.mbsc-focus {
      background: rgba(144, 151, 158, 0.3); }
  .mbsc-material-dark.mbsc-button-success.mbsc-button-flat {
    color: #43BE5F; }
    .mbsc-material-dark.mbsc-button-success.mbsc-button-flat.mbsc-hover, .mbsc-material-dark.mbsc-button-success.mbsc-button-flat.mbsc-active {
      background: rgba(67, 190, 95, 0.2); }
    .mbsc-material-dark.mbsc-button-success.mbsc-button-flat.mbsc-focus {
      background: rgba(67, 190, 95, 0.3); }
  .mbsc-material-dark.mbsc-button-danger.mbsc-button-flat {
    color: #f5504e; }
    .mbsc-material-dark.mbsc-button-danger.mbsc-button-flat.mbsc-hover, .mbsc-material-dark.mbsc-button-danger.mbsc-button-flat.mbsc-active {
      background: rgba(245, 80, 78, 0.2); }
    .mbsc-material-dark.mbsc-button-danger.mbsc-button-flat.mbsc-focus {
      background: rgba(245, 80, 78, 0.3); }
  .mbsc-material-dark.mbsc-button-warning.mbsc-button-flat {
    color: #f8b042; }
    .mbsc-material-dark.mbsc-button-warning.mbsc-button-flat.mbsc-hover, .mbsc-material-dark.mbsc-button-warning.mbsc-button-flat.mbsc-active {
      background: rgba(248, 176, 66, 0.2); }
    .mbsc-material-dark.mbsc-button-warning.mbsc-button-flat.mbsc-focus {
      background: rgba(248, 176, 66, 0.3); }
  .mbsc-material-dark.mbsc-button-info.mbsc-button-flat {
    color: #5BB7C5; }
    .mbsc-material-dark.mbsc-button-info.mbsc-button-flat.mbsc-hover, .mbsc-material-dark.mbsc-button-info.mbsc-button-flat.mbsc-active {
      background: rgba(91, 183, 197, 0.2); }
    .mbsc-material-dark.mbsc-button-info.mbsc-button-flat.mbsc-focus {
      background: rgba(91, 183, 197, 0.3); }
  .mbsc-material-dark.mbsc-button-dark.mbsc-button-flat {
    color: #47494A; }
    .mbsc-material-dark.mbsc-button-dark.mbsc-button-flat.mbsc-hover, .mbsc-material-dark.mbsc-button-dark.mbsc-button-flat.mbsc-active {
      background: rgba(71, 73, 74, 0.2); }
    .mbsc-material-dark.mbsc-button-dark.mbsc-button-flat.mbsc-focus {
      background: rgba(71, 73, 74, 0.3); }
  .mbsc-material-dark.mbsc-button-light.mbsc-button-flat {
    color: #cccccc; }
    .mbsc-material-dark.mbsc-button-light.mbsc-button-flat.mbsc-hover, .mbsc-material-dark.mbsc-button-light.mbsc-button-flat.mbsc-active {
      background: rgba(255, 255, 255, 0.2); }
    .mbsc-material-dark.mbsc-button-light.mbsc-button-flat.mbsc-focus {
      background: rgba(255, 255, 255, 0.3); }
  .mbsc-material-dark.mbsc-button-primary.mbsc-button-outline {
    border-color: #3f97f6;
    color: #3f97f6; }
    .mbsc-material-dark.mbsc-button-primary.mbsc-button-outline.mbsc-hover, .mbsc-material-dark.mbsc-button-primary.mbsc-button-outline.mbsc-active {
      background: rgba(63, 151, 246, 0.2); }
    .mbsc-material-dark.mbsc-button-primary.mbsc-button-outline.mbsc-focus {
      background: rgba(63, 151, 246, 0.3); }
  .mbsc-material-dark.mbsc-button-secondary.mbsc-button-outline {
    border-color: #90979E;
    color: #90979E; }
    .mbsc-material-dark.mbsc-button-secondary.mbsc-button-outline.mbsc-hover, .mbsc-material-dark.mbsc-button-secondary.mbsc-button-outline.mbsc-active {
      background: rgba(144, 151, 158, 0.2); }
    .mbsc-material-dark.mbsc-button-secondary.mbsc-button-outline.mbsc-focus {
      background: rgba(144, 151, 158, 0.3); }
  .mbsc-material-dark.mbsc-button-success.mbsc-button-outline {
    border-color: #43BE5F;
    color: #43BE5F; }
    .mbsc-material-dark.mbsc-button-success.mbsc-button-outline.mbsc-hover, .mbsc-material-dark.mbsc-button-success.mbsc-button-outline.mbsc-active {
      background: rgba(67, 190, 95, 0.2); }
    .mbsc-material-dark.mbsc-button-success.mbsc-button-outline.mbsc-focus {
      background: rgba(67, 190, 95, 0.3); }
  .mbsc-material-dark.mbsc-button-danger.mbsc-button-outline {
    border-color: #f5504e;
    color: #f5504e; }
    .mbsc-material-dark.mbsc-button-danger.mbsc-button-outline.mbsc-hover, .mbsc-material-dark.mbsc-button-danger.mbsc-button-outline.mbsc-active {
      background: rgba(245, 80, 78, 0.2); }
    .mbsc-material-dark.mbsc-button-danger.mbsc-button-outline.mbsc-focus {
      background: rgba(245, 80, 78, 0.3); }
  .mbsc-material-dark.mbsc-button-warning.mbsc-button-outline {
    border-color: #f8b042;
    color: #f8b042; }
    .mbsc-material-dark.mbsc-button-warning.mbsc-button-outline.mbsc-hover, .mbsc-material-dark.mbsc-button-warning.mbsc-button-outline.mbsc-active {
      background: rgba(248, 176, 66, 0.2); }
    .mbsc-material-dark.mbsc-button-warning.mbsc-button-outline.mbsc-focus {
      background: rgba(248, 176, 66, 0.3); }
  .mbsc-material-dark.mbsc-button-info.mbsc-button-outline {
    border-color: #5BB7C5;
    color: #5BB7C5; }
    .mbsc-material-dark.mbsc-button-info.mbsc-button-outline.mbsc-hover, .mbsc-material-dark.mbsc-button-info.mbsc-button-outline.mbsc-active {
      background: rgba(91, 183, 197, 0.2); }
    .mbsc-material-dark.mbsc-button-info.mbsc-button-outline.mbsc-focus {
      background: rgba(91, 183, 197, 0.3); }
  .mbsc-material-dark.mbsc-button-dark.mbsc-button-outline {
    border-color: #47494A;
    color: #47494A; }
    .mbsc-material-dark.mbsc-button-dark.mbsc-button-outline.mbsc-hover, .mbsc-material-dark.mbsc-button-dark.mbsc-button-outline.mbsc-active {
      background: rgba(71, 73, 74, 0.2); }
    .mbsc-material-dark.mbsc-button-dark.mbsc-button-outline.mbsc-focus {
      background: rgba(71, 73, 74, 0.3); }
  .mbsc-material-dark.mbsc-button-light.mbsc-button-outline {
    border-color: #cccccc;
    color: #cccccc; }
    .mbsc-material-dark.mbsc-button-light.mbsc-button-outline.mbsc-hover, .mbsc-material-dark.mbsc-button-light.mbsc-button-outline.mbsc-active {
      background: rgba(255, 255, 255, 0.2); }
    .mbsc-material-dark.mbsc-button-light.mbsc-button-outline.mbsc-focus {
      background: rgba(255, 255, 255, 0.3); }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-button {
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: middle;
  display: -moz-inline-box;
  display: inline-flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 1em;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  touch-action: manipulation;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  cursor: pointer; }

.mbsc-button:disabled,
.mbsc-button.mbsc-disabled {
  cursor: not-allowed; }

.mbsc-button:focus {
  outline: 0; }

.mbsc-button::-moz-focus-inner {
  border: 0; }

.mbsc-icon-button {
  width: 2.5em;
  height: 2.5em;
  padding: .5em;
  -moz-box-flex: 0;
       flex: 0 0 auto; }

.mbsc-button-icon > *,
.mbsc-button-icon > * > * {
  height: 100%; }

.mbsc-button-icon-end {
  -moz-box-ordinal-group: 2;
       order: 1; }

.mbsc-button-group,
.mbsc-button-group-block,
.mbsc-button-group-justified {
  border: 1px solid transparent; }

.mbsc-button-group-block .mbsc-button,
.mbsc-button-block {
  display: block;
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important; }

.mbsc-button-group-justified,
.mbsc-button-group-justified mbsc-button {
  display: -moz-box;
  display: flex;
  flex-wrap: wrap; }

.mbsc-button-group-justified .mbsc-button,
.mbsc-button-group-justified mbsc-button {
  -moz-box-flex: 1;
       flex: 1 auto; }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material.mbsc-form-control-label.mbsc-disabled, .mbsc-material.mbsc-description.mbsc-disabled {
  opacity: .3; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-form-control-label {
  display: block; }

.mbsc-form-control-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border: 0;
  opacity: 0;
  margin: 0;
  z-index: 3; }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material {
  /* Color presets */ }
  .mbsc-material.mbsc-checkbox-right {
    padding: .9375em 3.5em .9375em 1em; }
  .mbsc-material.mbsc-checkbox-left {
    padding: .9375em 1em .9375em 3.5em; }
  .mbsc-material.mbsc-checkbox-box {
    border-radius: .1875em;
    width: 1.125em;
    height: 1.125em;
    margin-top: -.5625em;
    border: .125em solid;
    transition: background-color .1s ease-out; }
  .mbsc-material.mbsc-checkbox-box-right {
    right: 1.25em; }
  .mbsc-material.mbsc-checkbox-box-left {
    left: 1.25em; }
  .mbsc-material.mbsc-checkbox-box:after {
    top: .125em;
    left: .0625em;
    width: .8125em;
    height: .4375em;
    opacity: 1;
    border: .125em solid;
    border-top: 0;
    border-right: 0;
    -webkit-transform: scale(0) rotate(-45deg);
            transform: scale(0) rotate(-45deg);
    transition: -webkit-transform .1s ease-out;
    transition: transform .1s ease-out;
    transition: transform .1s ease-out, -webkit-transform .1s ease-out; }
  .mbsc-material.mbsc-checkbox-box:before {
    content: '';
    position: absolute;
    top: -.8125em;
    left: -.8125em;
    z-index: -1;
    width: 2.5em;
    height: 2.5em;
    opacity: 0;
    border-radius: 2.5em;
    background: currentColor;
    transition: opacity .2s ease-in-out; }
  .mbsc-material.mbsc-checkbox-box.mbsc-focus:before {
    opacity: .12; }
  .mbsc-material.mbsc-checkbox-box.mbsc-active:before {
    opacity: .2; }
  .mbsc-material.mbsc-checkbox-box.mbsc-checked {
    background: currentColor;
    border-color: currentColor; }
  .mbsc-material.mbsc-checkbox-box.mbsc-checked:after {
    -webkit-transform: scale(1) rotate(-45deg);
            transform: scale(1) rotate(-45deg); }
  .mbsc-material.mbsc-checkbox-box.mbsc-disabled {
    opacity: .3; }
  .mbsc-material.mbsc-checkbox-box.mbsc-checkbox-box-primary {
    color: #3f97f6; }
  .mbsc-material.mbsc-checkbox-box.mbsc-checkbox-box-secondary {
    color: #90979E; }
  .mbsc-material.mbsc-checkbox-box.mbsc-checkbox-box-success {
    color: #43BE5F; }
  .mbsc-material.mbsc-checkbox-box.mbsc-checkbox-box-danger {
    color: #f5504e; }
  .mbsc-material.mbsc-checkbox-box.mbsc-checkbox-box-warning {
    color: #f8b042; }
  .mbsc-material.mbsc-checkbox-box.mbsc-checkbox-box-info {
    color: #5BB7C5; }

.mbsc-material.mbsc-checkbox-box {
  color: #1a73e8;
  border-color: #303030; }

.mbsc-material.mbsc-checkbox-box:after {
  border-color: white; }

.mbsc-material-dark.mbsc-checkbox-box {
  color: #87b0f3;
  border-color: #fff; }

.mbsc-material-dark.mbsc-checkbox-box:after {
  border-color: #000; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-checkbox {
  line-height: 1.25em;
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mbsc-checkbox-box {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  position: absolute;
  top: 50%;
  display: block;
  width: 1.375em;
  height: 1.375em; }

.mbsc-checkbox-box:after {
  content: '';
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  position: absolute;
  display: block;
  opacity: 0;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.mbsc-checkbox-box.mbsc-checked:after {
  opacity: 1; }

.mbsc-description {
  display: block;
  font-size: .75em;
  opacity: .6; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material {
  /* Wrapper */
  /* Inner wrapper */
  /* Form element */
  /* Icon */
  /* Ripple */
  /* Label */
  /* Error message */
  /* Underline input --------------------------------------------------------------------------- */
  /* Form element */
  /* Icon */
  /* Label */
  /* ------------------------------------------------------------------------------------------- */
  /* Box input --------------------------------------------------------------------------------- */
  /* Inner wrapper */
  /* Form element */
  /* Label */
  /* ------------------------------------------------------------------------------------------- */
  /* Outline input ----------------------------------------------------------------------------- */
  /* Inner wrapper */
  /* Form element */
  /* Label */
  /* Error message */
  /* ------------------------------------------------------------------------------------------- */
  /* Box & outline input common styles --------------------------------------------------------- */
  /* Form element */
  /* Icon */
  /* Label */
  /* Error message */
  /* ------------------------------------------------------------------------------------------- */
  /* Select */
  /* Select icon */
  /* Textarea */
  /* Input tags */ }
  .mbsc-material.mbsc-textfield-wrapper {
    margin: 1.5em 1em;
    -moz-box-sizing: border-box;
         box-sizing: border-box; }
  .mbsc-material.mbsc-textfield-inner.mbsc-disabled {
    opacity: .5; }
  .mbsc-material.mbsc-textfield {
    display: block;
    width: 100%;
    height: 2.25em;
    background-color: transparent;
    border-bottom: 1px solid;
    font-size: 1em;
    transition: border-color .2s; }
  .mbsc-material.mbsc-textfield-has-icon-left {
    padding-left: 2em; }
  .mbsc-material.mbsc-textfield-has-icon-right {
    padding-right: 2em; }
  .mbsc-material.mbsc-textfield-icon {
    top: .375em; }
  .mbsc-material.mbsc-textfield-icon-floating, .mbsc-material.mbsc-textfield-icon-stacked, .mbsc-material.mbsc-textfield-icon-outline {
    top: 1em; }
  .mbsc-material .mbsc-textfield-ripple {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    transition: -webkit-transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
    transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), -webkit-transform 180ms cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 2; }
  .mbsc-material .mbsc-textfield-ripple-active {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }
  .mbsc-material.mbsc-label {
    font-size: .75em; }
  .mbsc-material.mbsc-label-inline {
    line-height: 2em;
    font-size: 1em; }
  .mbsc-material.mbsc-label-inline.mbsc-ltr {
    padding-right: .5em; }
  .mbsc-material.mbsc-label-inline.mbsc-rtl {
    padding-left: .5em; }
  .mbsc-material.mbsc-label-floating {
    font-size: 1em;
    line-height: 1.5em;
    top: 1.125em; }
  .mbsc-material.mbsc-label-floating-active {
    -webkit-transform: translateY(-1.125em) scale(0.75);
            transform: translateY(-1.125em) scale(0.75); }
  .mbsc-material.mbsc-label.mbsc-disabled {
    opacity: .5; }
  .mbsc-material.mbsc-error-message.mbsc-ltr {
    left: 0; }
  .mbsc-material.mbsc-error-message.mbsc-rtl {
    right: 0; }
  .mbsc-material.mbsc-error-message-has-icon-left.mbsc-ltr {
    left: 2.66667em; }
  .mbsc-material.mbsc-error-message-has-icon-right.mbsc-rtl {
    right: 2.66667em; }
  .mbsc-material.mbsc-textfield-underline-stacked, .mbsc-material.mbsc-textfield-underline-floating {
    height: 3em;
    padding-top: .875em; }
  .mbsc-material.mbsc-textfield-icon-underline {
    top: .25em; }
  .mbsc-material.mbsc-textfield-icon-underline.mbsc-textfield-icon-floating, .mbsc-material.mbsc-textfield-icon-underline.mbsc-textfield-icon-stacked {
    top: 1.125em; }
  .mbsc-material.mbsc-label-underline-inline {
    padding-top: 1px; }
  .mbsc-material.mbsc-label-underline-stacked-has-icon-left.mbsc-ltr {
    left: 2.66667em; }
  .mbsc-material.mbsc-label-underline-stacked-has-icon-right.mbsc-rtl {
    right: 2.66667em; }
  .mbsc-material.mbsc-label-underline-floating-has-icon-left.mbsc-ltr {
    left: 2em; }
  .mbsc-material.mbsc-label-underline-floating-has-icon-right.mbsc-rtl {
    right: 2em; }
  .mbsc-material.mbsc-textfield-box, .mbsc-material.mbsc-textfield-inner-box {
    border-radius: .25em .25em 0 0; }
  .mbsc-material.mbsc-textfield-box {
    padding: 0 1em; }
  .mbsc-material.mbsc-textfield-box-stacked, .mbsc-material.mbsc-textfield-box-floating {
    height: 3.5em;
    padding-top: 1.25em; }
  .mbsc-material.mbsc-textfield-underline.mbsc-disabled {
    border-style: dotted; }
  .mbsc-material.mbsc-label-box-inline {
    padding-top: 1px; }
  .mbsc-material.mbsc-label-box-stacked {
    top: .666667em; }
  .mbsc-material.mbsc-label-box-floating {
    top: 1em; }
  .mbsc-material.mbsc-label-box-floating.mbsc-label-floating-active {
    -webkit-transform: translateY(-0.625em) scale(0.75);
            transform: translateY(-0.625em) scale(0.75); }
  .mbsc-material.mbsc-textfield-inner-outline {
    padding: 0 2px; }
  .mbsc-material.mbsc-textfield-outline {
    height: 3.5em;
    padding: 2px 1em;
    border: 0;
    border-radius: 4px; }
  .mbsc-material.mbsc-textfield-fieldset {
    top: -.5em;
    border: 1px solid;
    border-radius: 4px;
    transition: border-color .2s; }
  .mbsc-material.mbsc-textfield-fieldset-has-icon-left {
    padding-left: 3em; }
  .mbsc-material.mbsc-textfield-fieldset-has-icon-right {
    padding-right: 3em; }
  .mbsc-material.mbsc-textfield-fieldset.mbsc-focus {
    border-width: 2px; }
  .mbsc-material.mbsc-textfield-legend {
    padding: 0;
    margin: 0;
    font-size: .75em;
    color: transparent;
    width: 0.01px;
    white-space: nowrap; }
  .mbsc-material.mbsc-textfield-legend-active {
    width: auto;
    padding: 0 .333334em;
    margin: 0 -.333334em; }
  .mbsc-material.mbsc-label-outline-inline {
    line-height: 3.375em; }
  .mbsc-material.mbsc-label-outline-stacked {
    top: -.5em;
    margin: 0 2px; }
  .mbsc-material.mbsc-label-outline-floating {
    top: 1em;
    margin: 0 2px; }
  .mbsc-material.mbsc-label-outline-floating.mbsc-label-floating-active {
    margin-top: -1px;
    -webkit-transform: translateY(-1.5em) scale(0.75);
            transform: translateY(-1.5em) scale(0.75); }
  .mbsc-material.mbsc-error-message-outline {
    margin: 0 2px; }
  .mbsc-material.mbsc-textfield-outline-has-icon-left, .mbsc-material.mbsc-textfield-box-has-icon-left {
    padding-left: 3em; }
  .mbsc-material.mbsc-textfield-outline-has-icon-right, .mbsc-material.mbsc-textfield-box-has-icon-right {
    padding-right: 3em; }
  .mbsc-material.mbsc-textfield-icon-outline-left, .mbsc-material.mbsc-textfield-icon-box-left {
    left: .75em; }
  .mbsc-material.mbsc-textfield-icon-outline-right, .mbsc-material.mbsc-textfield-icon-box-right {
    right: .75em; }
  .mbsc-material.mbsc-label-box-stacked.mbsc-ltr, .mbsc-material.mbsc-label-outline-stacked.mbsc-ltr {
    left: 1.333334em; }
  .mbsc-material.mbsc-label-box-stacked.mbsc-rtl, .mbsc-material.mbsc-label-outline-stacked.mbsc-rtl {
    right: 1.333334em; }
  .mbsc-material.mbsc-label-outline-stacked-has-icon-left.mbsc-ltr, .mbsc-material.mbsc-label-box-stacked-has-icon-left.mbsc-ltr {
    left: 4em; }
  .mbsc-material.mbsc-label-box-stacked-has-icon-right.mbsc-rtl, .mbsc-material.mbsc-label-outline-stacked-has-icon-right.mbsc-rtl {
    right: 4em; }
  .mbsc-material.mbsc-label-box-floating.mbsc-ltr, .mbsc-material.mbsc-label-outline-floating.mbsc-ltr {
    left: 1em; }
  .mbsc-material.mbsc-label-box-floating.mbsc-rtl, .mbsc-material.mbsc-label-outline-floating.mbsc-rtl {
    right: 1em; }
  .mbsc-material.mbsc-label-outline-floating-has-icon-left.mbsc-ltr, .mbsc-material.mbsc-label-box-floating-has-icon-left.mbsc-ltr {
    left: 3em; }
  .mbsc-material.mbsc-label-outline-floating-has-icon-left.mbsc-rtl, .mbsc-material.mbsc-label-box-floating-has-icon-left.mbsc-rtl {
    right: 1em; }
  .mbsc-material.mbsc-label-outline-floating-has-icon-right.mbsc-rtl, .mbsc-material.mbsc-label-box-floating-has-icon-right.mbsc-rtl {
    right: 3em; }
  .mbsc-material.mbsc-error-message-box.mbsc-ltr, .mbsc-material.mbsc-error-message-outline.mbsc-ltr {
    left: 1.333334em; }
  .mbsc-material.mbsc-error-message-box.mbsc-rtl, .mbsc-material.mbsc-error-message-outline.mbsc-rtl {
    right: 1.333334em; }
  .mbsc-material.mbsc-error-message-box.mbsc-error-message-has-icon-left.mbsc-ltr, .mbsc-material.mbsc-error-message-outline.mbsc-error-message-has-icon-left.mbsc-ltr {
    left: 4em; }
  .mbsc-material.mbsc-error-message-box.mbsc-error-message-has-icon-right.mbsc-rtl, .mbsc-material.mbsc-error-message-outline.mbsc-error-message-has-icon-right.mbsc-rtl {
    right: 4em; }
  .mbsc-material.mbsc-select.mbsc-ltr {
    padding-right: 3em; }
  .mbsc-material.mbsc-select.mbsc-rtl {
    padding-left: 3em; }
  .mbsc-material.mbsc-select.mbsc-textfield-has-icon-right.mbsc-ltr {
    padding-right: 4.5em; }
  .mbsc-material.mbsc-select.mbsc-textfield-has-icon-left.mbsc-rtl {
    padding-left: 4.5em; }
  .mbsc-material.mbsc-select.mbsc-textfield-underline-has-icon-right.mbsc-ltr {
    padding-right: 3.75em; }
  .mbsc-material.mbsc-select.mbsc-textfield-underline-has-icon-left.mbsc-rtl {
    padding-left: 3.75em; }
  .mbsc-material.mbsc-select-icon {
    top: .375em; }
  .mbsc-material.mbsc-select-icon.mbsc-ltr {
    right: .75em; }
  .mbsc-material.mbsc-select-icon.mbsc-rtl {
    left: .75em; }
  .mbsc-material.mbsc-select-icon-right.mbsc-ltr {
    right: 3em; }
  .mbsc-material.mbsc-select-icon-left.mbsc-rtl {
    left: 3em; }
  .mbsc-material.mbsc-select-icon-stacked, .mbsc-material.mbsc-select-icon-floating, .mbsc-material.mbsc-select-icon-outline {
    top: 1em; }
  .mbsc-material.mbsc-select-icon-underline {
    top: .25em; }
  .mbsc-material.mbsc-select-icon-underline.mbsc-ltr {
    right: 0; }
  .mbsc-material.mbsc-select-icon-underline.mbsc-rtl {
    left: 0; }
  .mbsc-material.mbsc-select-icon-underline.mbsc-select-icon-right.mbsc-ltr {
    right: 2.25em; }
  .mbsc-material.mbsc-select-icon-underline.mbsc-select-icon-left.mbsc-rtl {
    left: 2.25em; }
  .mbsc-material.mbsc-select-icon-underline.mbsc-select-icon-floating, .mbsc-material.mbsc-select-icon-underline.mbsc-select-icon-stacked {
    top: 1.125em; }
  .mbsc-material.mbsc-textarea {
    height: 1.875em;
    padding-bottom: .375em; }
  .mbsc-material.mbsc-textarea.mbsc-textfield-stacked, .mbsc-material.mbsc-textarea.mbsc-textfield-floating {
    padding-top: 0; }
  .mbsc-material.mbsc-textarea.mbsc-textfield-outline {
    height: 1.5em;
    padding-top: 0;
    padding-bottom: 0; }
  .mbsc-material.mbsc-textarea.mbsc-textfield-underline {
    padding-bottom: .3125em; }
  .mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-box {
    padding-top: .375em; }
  .mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-stacked, .mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-floating {
    padding-top: 1.625em; }
  .mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-outline {
    padding-top: 1em;
    padding-bottom: 1em; }
  .mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-underline {
    padding-top: .25em; }
  .mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-stacked, .mbsc-material.mbsc-textarea-inner.mbsc-textfield-inner-underline.mbsc-textfield-inner-floating {
    padding-top: 1.125em; }
  .mbsc-material.mbsc-textfield-tags-inner.mbsc-textfield-inner-underline, .mbsc-material.mbsc-textfield-tags-inner.mbsc-textfield-inner-inline {
    padding-top: 0; }
  .mbsc-material.mbsc-textfield-tags-inner.mbsc-textfield-inner-outline {
    padding-top: .625em;
    padding-bottom: .625em; }
  .mbsc-material.mbsc-textfield-tags.mbsc-textfield {
    padding-bottom: 0;
    min-height: 2.25em; }
  .mbsc-material.mbsc-textfield-tags.mbsc-textfield-outline {
    padding-top: 1px;
    padding-bottom: 1px; }
  .mbsc-material.mbsc-textfield-tag {
    border-radius: 1em; }

.mbsc-material {
  /* Form element */
  /* Icon */
  /* Ripple */
  /* Label */
  /* Error message */
  /* Select */
  /* Box input ------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------- */
  /* Outline input --------------------------------------------------------------------------- */
  /* Input tag */
  /* ----------------------------------------------------------------------------------------- */ }
  .mbsc-material.mbsc-textfield {
    border-bottom-color: rgba(0, 0, 0, 0.42);
    color: rgba(0, 0, 0, 0.87); }
  .mbsc-material.mbsc-textfield.mbsc-hover {
    border-color: rgba(0, 0, 0, 0.87); }
  .mbsc-material.mbsc-textfield.mbsc-error {
    border-color: #de3226; }
  .mbsc-material.mbsc-textfield-icon, .mbsc-material.mbsc-select-icon {
    color: rgba(0, 0, 0, 0.54); }
  .mbsc-material.mbsc-textfield-ripple {
    background-color: #1a73e8; }
  .mbsc-material.mbsc-textfield-ripple.mbsc-error {
    background-color: #de3226; }
  .mbsc-material.mbsc-label {
    color: rgba(0, 0, 0, 0.6); }
  .mbsc-material.mbsc-label-stacked.mbsc-focus, .mbsc-material.mbsc-label-floating.mbsc-focus {
    color: #1a73e8; }
  .mbsc-material.mbsc-label.mbsc-error {
    color: #de3226; }
  .mbsc-material.mbsc-error-message {
    color: #de3226; }
  .mbsc-material.mbsc-select {
    background: #fff; }
  .mbsc-material.mbsc-textfield-box.mbsc-select, .mbsc-material.mbsc-textfield-inner-box {
    background: whitesmoke; }
  .mbsc-material.mbsc-textfield-box.mbsc-select.mbsc-hover, .mbsc-material.mbsc-textfield-inner-box.mbsc-hover {
    background: #ededed; }
  .mbsc-material.mbsc-textfield-box.mbsc-select.mbsc-focus, .mbsc-material.mbsc-textfield-inner-box.mbsc-focus {
    background: #e3e3e3; }
  .mbsc-material.mbsc-textfield-fieldset {
    border-color: rgba(0, 0, 0, 0.42); }
  .mbsc-material.mbsc-textfield-fieldset.mbsc-hover {
    border-color: rgba(0, 0, 0, 0.87); }
  .mbsc-material.mbsc-textfield-fieldset.mbsc-focus {
    border-color: #1a73e8; }
  .mbsc-material.mbsc-textfield-fieldset.mbsc-error {
    border-color: #de3226; }
  .mbsc-material.mbsc-textfield-fieldset.disabled {
    border-color: rgba(0, 0, 0, 0.87); }
  .mbsc-material.mbsc-textfield-tag {
    background: #d4d4d4; }
  .mbsc-material.mbsc-textfield-tag-clear {
    color: rgba(0, 0, 0, 0.54); }

.mbsc-material-dark {
  /* Form element */
  /* Icon */
  /* Ripple */
  /* Label */
  /* Error message */
  /* Select */
  /* Box input ------------------------------------------------------------------------------- */
  /* ----------------------------------------------------------------------------------------- */
  /* Outline input --------------------------------------------------------------------------- */
  /* Input tag */
  /* ----------------------------------------------------------------------------------------- */ }
  .mbsc-material-dark.mbsc-textfield {
    border-bottom-color: #fff;
    color: #fff; }
  .mbsc-material-dark.mbsc-textfield.mbsc-hover {
    border-color: #fff; }
  .mbsc-material-dark.mbsc-textfield.mbsc-error {
    border-color: #de3226; }
  .mbsc-material-dark.mbsc-textfield-icon, .mbsc-material-dark.mbsc-select-icon {
    color: #fff; }
  .mbsc-material-dark.mbsc-textfield-ripple {
    background-color: #87b0f3; }
  .mbsc-material-dark.mbsc-textfield-ripple.mbsc-error {
    background-color: #de3226; }
  .mbsc-material-dark.mbsc-label {
    color: rgba(255, 255, 255, 0.6); }
  .mbsc-material-dark.mbsc-label-stacked.mbsc-focus, .mbsc-material-dark.mbsc-label-floating.mbsc-focus {
    color: #87b0f3; }
  .mbsc-material-dark.mbsc-label.mbsc-error {
    color: #de3226; }
  .mbsc-material-dark.mbsc-error-message {
    color: #de3226; }
  .mbsc-material-dark.mbsc-select {
    background: #000; }
  .mbsc-material-dark.mbsc-textfield-box.mbsc-select, .mbsc-material-dark.mbsc-textfield-inner-box {
    background: #0a0a0a; }
  .mbsc-material-dark.mbsc-textfield-box.mbsc-select.mbsc-hover, .mbsc-material-dark.mbsc-textfield-inner-box.mbsc-hover {
    background: #121212; }
  .mbsc-material-dark.mbsc-textfield-box.mbsc-select.mbsc-focus, .mbsc-material-dark.mbsc-textfield-inner-box.mbsc-focus {
    background: #1c1c1c; }
  .mbsc-material-dark.mbsc-textfield-fieldset {
    border-color: #fff; }
  .mbsc-material-dark.mbsc-textfield-fieldset.mbsc-hover {
    border-color: #fff; }
  .mbsc-material-dark.mbsc-textfield-fieldset.mbsc-focus {
    border-color: #87b0f3; }
  .mbsc-material-dark.mbsc-textfield-fieldset.mbsc-error {
    border-color: #de3226; }
  .mbsc-material-dark.mbsc-textfield-fieldset.disabled {
    border-color: #fff; }
  .mbsc-material-dark.mbsc-textfield-tag {
    background: #454545; }
  .mbsc-material-dark.mbsc-textfield-tag-clear {
    color: #fff; }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
@-webkit-keyframes autofill {
  from {
    opacity: 1; }
  to {
    opacity: 1; } }
@keyframes autofill {
  from {
    opacity: 1; }
  to {
    opacity: 1; } }

/* Wrapper */
.mbsc-textfield-wrapper {
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mbsc-textfield-wrapper-inline {
  display: -moz-box;
  display: flex; }

.mbsc-form-grid .mbsc-textfield-wrapper-box,
.mbsc-form-grid .mbsc-textfield-wrapper-outline {
  margin: .75em 1em; }

/* Inner wrapper */
.mbsc-textfield-inner {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  position: relative;
  display: block; }

.mbsc-textfield-inner-inline {
  -moz-box-flex: 1;
       flex: 1 auto; }

/* Form element */
.mbsc-textfield {
  display: block;
  width: 100%;
  font-size: 1em;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  outline: 0;
  font-family: inherit;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none; }

.mbsc-textfield:-webkit-autofill {
  -webkit-animation-name: autofill;
          animation-name: autofill; }

.mbsc-textfield::-webkit-inner-spin-button {
  height: 2em;
  align-self: center; }

.mbsc-textfield::-moz-placeholder {
  opacity: .5;
  color: inherit; }

.mbsc-textfield::-webkit-input-placeholder {
  opacity: .5;
  color: inherit; }

/* Floating label */
.mbsc-textfield-floating:-ms-input-placeholder {
  color: transparent; }

.mbsc-textfield-floating::-moz-placeholder {
  opacity: 0;
  -moz-transition: opacity .2s;
  transition: opacity .2s; }

.mbsc-textfield-floating::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: opacity .2s;
  transition: opacity .2s; }

.mbsc-textfield-floating::-webkit-datetime-edit {
  color: transparent; }

.mbsc-textfield-floating-active:-ms-input-placeholder {
  color: inherit; }

.mbsc-textfield-floating-active::-moz-placeholder {
  opacity: .5; }

.mbsc-textfield-floating-active::-webkit-input-placeholder {
  opacity: .5; }

.mbsc-textfield-floating-active::-webkit-datetime-edit {
  color: inherit; }

.mbsc-textfield-floating .mbsc-textfield-tags-placeholder {
  opacity: 0;
  transition: opacity .2s; }

.mbsc-textfield-floating-active .mbsc-textfield-tags-placeholder {
  opacity: .5; }

.mbsc-textfield-fieldset {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 0 1em;
  pointer-events: none; }

/* Icon */
.mbsc-textfield-icon {
  position: absolute;
  top: 0;
  text-align: center; }

.mbsc-textfield-icon-left {
  left: 0; }

.mbsc-textfield-icon-right {
  right: 0; }

/* Label */
.mbsc-label {
  position: absolute;
  z-index: 1;
  top: 0;
  line-height: 1em;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none; }

.mbsc-label-inline {
  position: static;
  overflow: hidden;
  -moz-box-flex: 0;
       flex: 0 0 auto;
  width: 30%;
  max-width: 12.5em;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  pointer-events: auto; }

.mbsc-label-floating-animate {
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s; }

.mbsc-label-floating.mbsc-ltr {
  -webkit-transform-origin: top left;
          transform-origin: top left; }

.mbsc-label-floating.mbsc-rtl {
  -webkit-transform-origin: top right;
          transform-origin: top right; }

/* Error message */
.mbsc-error-message {
  position: absolute;
  top: 100%;
  font-size: .75em;
  line-height: 1.5em; }

/* File input */
.mbsc-textfield-file {
  position: absolute;
  left: 0;
  opacity: 0; }

/* Select */
.mbsc-select {
  cursor: pointer; }

.mbsc-select-icon {
  position: absolute;
  text-align: center;
  pointer-events: none; }

/* Textarea */
.mbsc-textarea {
  resize: none;
  overflow: hidden;
  line-height: 1.5em; }

/* Password toggle */
.mbsc-toggle-icon:hover {
  cursor: pointer; }

/* Input tags */
.mbsc-textfield.mbsc-textfield-hidden.mbsc-textarea {
  padding: 0;
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  opacity: 0; }

.mbsc-textfield.mbsc-textfield-tags.mbsc-textarea {
  display: block;
  overflow: auto;
  height: auto;
  max-height: 5.5em; }

.mbsc-textfield-tag {
  display: inline-block;
  margin: .25em 0;
  line-height: normal; }

.mbsc-textfield-tag.mbsc-ltr {
  margin-right: .5em;
  padding-left: .75em; }

.mbsc-textfield-tag.mbsc-rtl {
  margin-left: .5em;
  padding-right: .75em; }

.mbsc-textfield-tag-text {
  font-size: .875em;
  line-height: 1.857143em; }

.mbsc-textfield-tag-clear.mbsc-icon {
  width: 1.625em;
  height: 1.625em;
  vertical-align: top;
  border: .25em solid transparent;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  cursor: pointer; }

.mbsc-textfield-tags-placeholder {
  opacity: .5;
  line-height: 2.125em; }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material.mbsc-popup-body-center, .mbsc-material.mbsc-popup-body-anchored, .mbsc-material.mbsc-popup-body-round {
  border-radius: .25em; }

.mbsc-material.mbsc-popup-header {
  font-size: 1.25em;
  font-weight: 500;
  padding: .8em .8em 0 .8em;
  line-height: 1.4em; }

.mbsc-material.mbsc-popup-buttons {
  padding: .5em; }

.mbsc-material.mbsc-popup-buttons.mbsc-ltr {
  text-align: right; }

.mbsc-material.mbsc-popup-buttons.mbsc-rtl {
  text-align: right; }

.mbsc-material.mbsc-popup-button.mbsc-button-flat {
  margin-left: 0;
  margin-right: 0; }

.mbsc-material.mbsc-popup-button.mbsc-font {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  font-size: .9375em; }

.mbsc-material.mbsc-popup-round .mbsc-popup-body-bottom-full {
  border-radius: .25em .25em 0 0; }

.mbsc-material.mbsc-popup-round .mbsc-popup-body-top-full {
  border-radius: 0 0 .25em .25em; }

.mbsc-material.mbsc-popup-body {
  background: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  color: #303030; }

.mbsc-material.mbsc-popup-arrow {
  background: #fff;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2); }

.mbsc-material.mbsc-popup-button.mbsc-font {
  color: #1a73e8; }

.mbsc-material-dark.mbsc-popup-body {
  background: #303030;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  color: #fff; }

.mbsc-material-dark.mbsc-popup-arrow {
  background: #303030;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2); }

.mbsc-material-dark.mbsc-popup-button.mbsc-font {
  color: #87b0f3; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
@-webkit-keyframes mbsc-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }
@keyframes mbsc-fade-in {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@-webkit-keyframes mbsc-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes mbsc-fade-out {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes mbsc-pop-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes mbsc-pop-in {
  from {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes mbsc-pop-out {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

@keyframes mbsc-pop-out {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.8);
            transform: scale(0.8); } }

@-webkit-keyframes mbsc-slide-up-in {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes mbsc-slide-up-in {
  from {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes mbsc-slide-up-out {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); } }

@keyframes mbsc-slide-up-out {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    -webkit-transform: translateY(100%);
            transform: translateY(100%); } }

@-webkit-keyframes mbsc-slide-down-in {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@keyframes mbsc-slide-down-in {
  from {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(0);
            transform: translateY(0); } }

@-webkit-keyframes mbsc-slide-down-out {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

@keyframes mbsc-slide-down-out {
  from {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  to {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%); } }

.mbsc-popup-open-ios {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto; }

.mbsc-popup-ctx {
  position: relative;
  --mbsc-safe-top: 0;
  --mbsc-safe-right: 0;
  --mbsc-safe-bottom: 0;
  --mbsc-safe-left: 0; }

.mbsc-popup-limits {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1; }

.mbsc-popup-limits-anchored,
.mbsc-popup-limits-center {
  margin: 1em; }

.mbsc-popup-wrapper {
  z-index: 99998;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mbsc-popup,
.mbsc-popup-wrapper {
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-popup-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  border: 0 solid transparent; }

.mbsc-popup-wrapper-ctx {
  position: absolute;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 100%;
  overflow: hidden; }

.mbsc-popup-wrapper-center {
  padding: 1em;
  -moz-box-align: center;
       align-items: center;
  border-width: var(--mbsc-safe-top) var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left); }

.mbsc-popup-wrapper-top {
  -moz-box-align: start;
       align-items: flex-start;
  border-bottom: var(--mbsc-safe-bottom) solid transparent; }

.mbsc-popup-wrapper-bottom {
  -moz-box-align: end;
       align-items: flex-end;
  border-top-width: var(--mbsc-safe-top); }

.mbsc-popup-wrapper-anchored {
  border-width: 0 var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left); }

.mbsc-popup-hidden {
  opacity: 0; }

.mbsc-popup-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -10em;
  pointer-events: auto;
  background: rgba(0, 0, 0, 0.5);
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }

.mbsc-popup-overlay-in {
  opacity: 1;
  -webkit-animation: mbsc-fade-in 150ms cubic-bezier(0, 0, 0.2, 1);
          animation: mbsc-fade-in 150ms cubic-bezier(0, 0, 0.2, 1); }

.mbsc-popup-overlay-out {
  opacity: 0;
  -webkit-animation: mbsc-fade-out 75ms cubic-bezier(0.4, 0, 1, 1);
          animation: mbsc-fade-out 75ms cubic-bezier(0.4, 0, 1, 1); }

.mbsc-popup {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  max-height: 100%;
  min-width: 12em;
  position: relative;
  z-index: 2;
  pointer-events: auto;
  text-shadow: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mbsc-popup-center {
  min-width: 16em; }

.mbsc-popup-bottom {
  width: 100%;
  bottom: 0; }

.mbsc-popup-top {
  width: 100%;
  top: 0; }

.mbsc-popup-anchored {
  position: absolute; }

.mbsc-popup-anchored-left {
  margin-left: -1em; }

.mbsc-popup-anchored-right {
  margin-left: 1em; }

.mbsc-popup-arrow-wrapper {
  position: absolute;
  z-index: 1;
  overflow: hidden;
  pointer-events: none; }

.mbsc-popup-arrow-wrapper-top,
.mbsc-popup-arrow-wrapper-bottom {
  left: 0;
  right: 0;
  height: 2em; }

.mbsc-popup-arrow-wrapper-left,
.mbsc-popup-arrow-wrapper-right {
  top: 0;
  bottom: 0;
  width: 2em; }

.mbsc-popup-arrow-wrapper-top {
  top: 100%; }

.mbsc-popup-arrow-wrapper-bottom {
  bottom: 100%; }

.mbsc-popup-arrow-wrapper-left {
  left: 100%; }

.mbsc-popup-arrow-wrapper-right {
  right: 100%; }

.mbsc-popup-arrow {
  position: absolute;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  width: 1.5em;
  height: 1.5em;
  pointer-events: auto; }

.mbsc-popup-arrow-top {
  bottom: 1.25em; }

.mbsc-popup-arrow-bottom {
  top: 1.25em; }

.mbsc-popup-arrow-top,
.mbsc-popup-arrow-bottom {
  left: 50%;
  margin-left: -.75em;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.mbsc-popup-arrow-left {
  right: 1.25em; }

.mbsc-popup-arrow-right {
  left: 1.25em; }

.mbsc-popup-arrow-left,
.mbsc-popup-arrow-right {
  top: 50%;
  margin-top: -.75em;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.mbsc-popup-focus {
  outline: 0; }

.mbsc-popup-body {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  overflow: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -moz-box-flex: 1;
       flex: 1 1 auto; }

.mbsc-popup-body-top {
  padding-top: var(--mbsc-safe-top); }

.mbsc-popup-body-bottom {
  padding-bottom: var(--mbsc-safe-bottom); }

.mbsc-popup-body-top,
.mbsc-popup-body-bottom {
  padding-left: var(--mbsc-safe-left);
  padding-right: var(--mbsc-safe-right); }

.mbsc-popup-body-round {
  padding: 0; }

.mbsc-popup-header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -moz-box-flex: 0;
       flex: none; }

.mbsc-popup-content {
  -moz-box-flex: 1;
       flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.mbsc-popup-padding {
  padding: 1em; }

.mbsc-popup-buttons {
  -moz-box-flex: 0;
       flex: none; }

/* Animations */
.mbsc-popup-pop-in {
  opacity: 1;
  -webkit-animation: mbsc-pop-in 150ms cubic-bezier(0, 0, 0.2, 1);
          animation: mbsc-pop-in 150ms cubic-bezier(0, 0, 0.2, 1); }

.mbsc-popup-pop-out {
  opacity: 0;
  -webkit-animation: mbsc-pop-out 75ms cubic-bezier(0.4, 0, 1, 1);
          animation: mbsc-pop-out 75ms cubic-bezier(0.4, 0, 1, 1); }

.mbsc-popup-slide-up-in {
  -webkit-animation: mbsc-slide-up-in 250ms cubic-bezier(0, 0, 0.2, 1);
          animation: mbsc-slide-up-in 250ms cubic-bezier(0, 0, 0.2, 1); }

.mbsc-popup-slide-up-out {
  -webkit-animation: mbsc-slide-up-out 200ms cubic-bezier(0.4, 0, 1, 1);
          animation: mbsc-slide-up-out 200ms cubic-bezier(0.4, 0, 1, 1); }

.mbsc-popup-slide-down-in {
  -webkit-animation: mbsc-slide-down-in 250ms cubic-bezier(0, 0, 0.2, 1);
          animation: mbsc-slide-down-in 250ms cubic-bezier(0, 0, 0.2, 1); }

.mbsc-popup-slide-down-out {
  -webkit-animation: mbsc-slide-down-out 200ms cubic-bezier(0.4, 0, 1, 1);
          animation: mbsc-slide-down-out 200ms cubic-bezier(0.4, 0, 1, 1); }

/* Desktop styling */
.mbsc-popup-pointer.mbsc-picker .mbsc-popup-overlay,
.mbsc-popup-pointer .mbsc-popup-overlay-anchored {
  background: none; }

.mbsc-popup-round .mbsc-popup-slide-up-in {
  -webkit-animation-name: mbsc-slide-up-in, mbsc-fade-in;
          animation-name: mbsc-slide-up-in, mbsc-fade-in; }

.mbsc-popup-round .mbsc-popup-slide-up-out {
  -webkit-animation-name: mbsc-slide-up-out, mbsc-fade-out;
          animation-name: mbsc-slide-up-out, mbsc-fade-out; }

.mbsc-popup-round .mbsc-popup-slide-down-in {
  -webkit-animation-name: mbsc-slide-down-in, mbsc-fade-in;
          animation-name: mbsc-slide-down-in, mbsc-fade-in; }

.mbsc-popup-round .mbsc-popup-slide-down-out {
  -webkit-animation-name: mbsc-slide-down-out, mbsc-fade-out;
          animation-name: mbsc-slide-down-out, mbsc-fade-out; }

.mbsc-popup-round .mbsc-popup-top,
.mbsc-popup-round .mbsc-popup-bottom {
  width: auto;
  margin-top: 3em;
  margin-bottom: 3em; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material .mbsc-toast-message {
  border-radius: 1.571429em; }

.mbsc-material .mbsc-toast-background {
  background: #444;
  color: #fff; }

.mbsc-material.mbsc-prompt-input.mbsc-font {
  margin: 1.5em 0 0 0; }

.mbsc-material.mbsc-primary .mbsc-toast-background {
  background: #3f97f6;
  color: #fff; }

.mbsc-material.mbsc-secondary .mbsc-toast-background {
  background: #90979E;
  color: #fff; }

.mbsc-material.mbsc-success .mbsc-toast-background {
  background: #43BE5F;
  color: #fff; }

.mbsc-material.mbsc-danger .mbsc-toast-background {
  background: #f5504e;
  color: #fff; }

.mbsc-material.mbsc-warning .mbsc-toast-background {
  background: #f8b042;
  color: #fff; }

.mbsc-material.mbsc-info .mbsc-toast-background {
  background: #5BB7C5;
  color: #fff; }

.mbsc-material.mbsc-color-none .mbsc-snackbar-button.mbsc-button {
  color: #488fed; }

.mbsc-material-dark.mbsc-color-none .mbsc-snackbar-button.mbsc-button {
  color: #b5cff8; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Toast */
.mbsc-toast.mbsc-font .mbsc-popup-body,
.mbsc-toast.mbsc-font .mbsc-popup,
.mbsc-snackbar.mbsc-font .mbsc-popup-body,
.mbsc-snackbar.mbsc-font .mbsc-popup {
  background: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  margin: 0;
  pointer-events: none; }

.mbsc-toast.mbsc-font .mbsc-popup-content {
  text-align: center;
  padding-bottom: 4em; }

.mbsc-toast-message {
  display: inline-block;
  min-width: 10em;
  max-width: 50em;
  padding: .857143em 2em;
  font-size: .875em;
  line-height: 1.428572; }

/* Snackbar */
.mbsc-snackbar-cont {
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  min-width: 18em;
  max-width: 36em;
  margin: 0 auto;
  padding: .5em;
  pointer-events: auto; }

.mbsc-snackbar-message {
  -moz-box-flex: 1;
       flex: 1;
  padding: .5em 1em;
  font-size: .875em;
  line-height: 1.571429em; }

.mbsc-snackbar-button.mbsc-button.mbsc-font {
  margin: 0;
  color: #fff; }

/* Alert, Confirm, Prompt*/
.mbsc-alert-content {
  max-width: 20em; }

.mbsc-alert-title {
  margin: 0 0 1em 0;
  padding: 0;
  font-size: 1.428572em;
  font-weight: bold; }

.mbsc-alert-message {
  margin: 1em 0;
  padding: 0;
  font-size: 1em; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material .mbsc-block-title,
.mbsc-material .mbsc-form-group-title {
  padding: .666667em 1.333334em;
  font-size: .75em;
  text-transform: uppercase; }

.mbsc-material.mbsc-page {
  background-color: #fff;
  color: #303030; }

.mbsc-material .mbsc-block-title,
.mbsc-material .mbsc-form-group-title {
  color: #1a73e8; }

.mbsc-material a {
  color: #1a73e8; }

.mbsc-material-dark.mbsc-page {
  background-color: #000;
  color: #fff; }

.mbsc-material-dark .mbsc-block-title,
.mbsc-material-dark .mbsc-form-group-title {
  color: #87b0f3; }

.mbsc-material-dark a {
  color: #87b0f3; }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-page {
  display: block;
  min-height: 100%;
  padding: var(--mbsc-safe-top) var(--mbsc-safe-right) var(--mbsc-safe-bottom) var(--mbsc-safe-left); }

.mbsc-page:before,
.mbsc-page:after {
  content: '';
  display: table; }

/* Block */
.mbsc-block,
.mbsc-form-group {
  margin: 1.5em 0; }

.mbsc-form-group-inset {
  margin: 2em 1.5em; }

.mbsc-form-grid .mbsc-form-group-title {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0; }

/* Typography */
.mbsc-page h1,
.mbsc-page h2,
.mbsc-page h3,
.mbsc-page h4,
.mbsc-page h5,
.mbsc-page h6,
.mbsc-h1,
.mbsc-h2,
.mbsc-h3,
.mbsc-h4,
.mbsc-h5,
.mbsc-h6 {
  margin: 0;
  padding: 0;
  color: inherit;
  font-weight: normal;
  font-family: inherit; }

.mbsc-page p,
.mbsc-p {
  margin: 1em 0;
  padding: 0;
  line-height: 1.5; }

.mbsc-page a,
.mbsc-a {
  text-decoration: none; }

.mbsc-page a:hover,
.mbsc-a:hover {
  text-decoration: underline; }

.mbsc-page a.mbsc-btn:hover,
.mbsc-a.mbsc-btn:hover {
  text-decoration: none; }

.mbsc-page h1,
.mbsc-h1 {
  margin: .347826em 0;
  font-size: 2.875em; }

.mbsc-page h2,
.mbsc-h2 {
  margin: .470588em 0;
  font-size: 2.125em; }

.mbsc-page h3,
.mbsc-h3 {
  margin: .666666em 0;
  font-size: 1.5em; }

.mbsc-page h4,
.mbsc-h4 {
  margin: .8em 0;
  font-size: 1.25em; }

.mbsc-page h5,
.mbsc-h5 {
  margin: 1.066666em 0;
  font-size: .9375em; }

.mbsc-page h6,
.mbsc-h6 {
  margin: 1.333333em 0;
  font-size: .75em; }

/* Padding, margin */
.mbsc-padding {
  padding: 1em; }

.mbsc-padding > p:first-child {
  margin-top: 0; }

.mbsc-padding > p:last-child {
  margin-bottom: 0; }

.mbsc-margin {
  margin: 1em 0; }

.mbsc-margin:first-child {
  margin-top: 0; }

.mbsc-margin:last-child {
  margin-bottom: 0; }

/* Lists */
.mbsc-page ul,
.mbsc-page ol,
.mbsc-ul,
.mbsc-ol {
  padding: 0;
  margin: 1em 0 1em 1.25em;
  line-height: 1.5; }

.mbsc-page ul ul,
.mbsc-page ol ol,
.mbsc-ul .mbsc-ul,
.mbsc-ol .mbsc-ol {
  margin: 0 0 0 1.25em; }

/* Font sizes */
.mbsc-txt-xs {
  font-size: .625em; }

.mbsc-txt-s {
  font-size: .75em; }

.mbsc-txt-m {
  font-size: 1.25em; }

.mbsc-txt-l {
  font-size: 1.5em; }

.mbsc-txt-xl {
  font-size: 2em; }

.mbsc-txt-muted {
  opacity: .6; }

/* Line heights */
.mbsc-line-height-xs {
  line-height: 1; }

.mbsc-line-height-s {
  line-height: 1.25; }

.mbsc-line-height-m {
  line-height: 1.5; }

.mbsc-line-height-l {
  line-height: 1.75; }

.mbsc-line-height-xl {
  line-height: 2; }

/* Font weights */
.mbsc-ultra-bold {
  font-weight: 900; }

.mbsc-bold {
  font-weight: bold; }

.mbsc-medium {
  font-weight: 500; }

.mbsc-light {
  font-weight: 300; }

.mbsc-thin {
  font-weight: 100; }

.mbsc-italic {
  font-style: italic; }

/* Text align */
.mbsc-align-left {
  text-align: left; }

.mbsc-align-right {
  text-align: right; }

.mbsc-align-center {
  text-align: center; }

.mbsc-align-justify {
  text-align: justify; }

/* Float */
.mbsc-pull-right {
  float: right; }

.mbsc-pull-left {
  float: left; }

/* Image section */
.mbsc-media-fluid {
  display: block;
  width: 100%; }

.mbsc-img-thumbnail {
  width: 6em;
  height: 6em;
  margin: 1em; }

/* Avatar image */
.mbsc-avatar {
  width: 2.5em;
  height: 2.5em;
  padding: 0;
  border-radius: 1.25em; }

/* Note */
.mbsc-note {
  position: relative;
  padding: .75em 1.25em;
  margin: 1em;
  border: 1px solid transparent;
  font-size: .875em; }

@media (max-width: 600px) {
  .mbsc-note {
    text-align: center; } }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material {
  /* Color presets */ }
  .mbsc-material.mbsc-radio-left {
    padding: .9375em 1em .9375em 3.5em; }
  .mbsc-material.mbsc-radio-right {
    padding: .9375em 3.5em .9375em 1em; }
  .mbsc-material.mbsc-radio-box {
    border: .125em solid;
    transition: background-color .1s ease-out; }
  .mbsc-material.mbsc-radio-box-left {
    left: 1.125em; }
  .mbsc-material.mbsc-radio-box-right {
    right: 1.125em; }
  .mbsc-material.mbsc-radio-box:before {
    content: '';
    position: absolute;
    top: -.75em;
    left: -.75em;
    z-index: -1;
    width: 2.5em;
    height: 2.5em;
    opacity: 0;
    border-radius: 2.5em;
    transition: opacity .2s ease-in-out;
    background: currentColor; }
  .mbsc-material.mbsc-radio-box.mbsc-focus:before {
    opacity: .12; }
  .mbsc-material.mbsc-radio-box.mbsc-active:before {
    opacity: .2; }
  .mbsc-material.mbsc-radio-box:after {
    background: currentColor;
    -webkit-transform: scale(0);
            transform: scale(0);
    transition: -webkit-transform .1s ease-out;
    transition: transform .1s ease-out;
    transition: transform .1s ease-out, -webkit-transform .1s ease-out; }
  .mbsc-material.mbsc-radio-box.mbsc-checked {
    border-color: currentColor; }
  .mbsc-material.mbsc-radio-box.mbsc-checked:after {
    -webkit-transform: scale(1);
            transform: scale(1); }
  .mbsc-material.mbsc-radio-box.mbsc-disabled {
    opacity: .3; }
  .mbsc-material.mbsc-radio-box.mbsc-radio-box-primary {
    color: #3f97f6; }
  .mbsc-material.mbsc-radio-box.mbsc-radio-box-secondary {
    color: #90979E; }
  .mbsc-material.mbsc-radio-box.mbsc-radio-box-success {
    color: #43BE5F; }
  .mbsc-material.mbsc-radio-box.mbsc-radio-box-danger {
    color: #f5504e; }
  .mbsc-material.mbsc-radio-box.mbsc-radio-box-warning {
    color: #f8b042; }
  .mbsc-material.mbsc-radio-box.mbsc-radio-box-info {
    color: #5BB7C5; }

.mbsc-material.mbsc-radio-box {
  color: #1a73e8;
  border-color: #303030; }

.mbsc-material-dark.mbsc-radio-box {
  color: #87b0f3;
  border-color: #fff; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-radio {
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  line-height: 1.25em;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mbsc-radio-box {
  position: absolute;
  top: 50%;
  display: block;
  width: 1.25em;
  height: 1.25em;
  margin-top: -.625em;
  border-radius: 1.25em;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-radio-box:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: .625em;
  height: .625em;
  margin-top: -.3125em;
  margin-left: -.3125em;
  border-radius: .625em;
  opacity: 0; }

.mbsc-radio-box.mbsc-checked:after {
  opacity: 1; }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material.mbsc-segmented {
  padding: .75em; }

.mbsc-material.mbsc-segmented-selectbox {
  display: none; }

.mbsc-material.mbsc-segmented-item:first-child .mbsc-segmented-button {
  border-top-left-radius: .25em;
  border-bottom-left-radius: .25em; }

.mbsc-material.mbsc-segmented-item:first-child .mbsc-segmented-button.mbsc-rtl {
  border-radius: 0 .25em .25em 0;
  border-right-width: .142858em; }

.mbsc-material.mbsc-segmented-item:last-child .mbsc-segmented-button {
  border-top-right-radius: .25em;
  border-bottom-right-radius: .25em;
  border-right-width: .142858em; }

.mbsc-material.mbsc-segmented-item:last-child .mbsc-segmented-button.mbsc-rtl {
  border-radius: .25em 0 0 .25em;
  border-right-width: 0; }

.mbsc-material.mbsc-segmented-button.mbsc-button {
  margin: 0;
  padding: .285715em 1.142858em;
  border: .142858em solid transparent;
  border-right-width: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  min-width: auto;
  font-size: .875em;
  /* Color presets */ }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-hover, .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-active {
    box-shadow: none; }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-focus:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-primary {
    color: #3f97f6;
    border-color: #3f97f6; }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-primary.mbsc-hover, .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-primary.mbsc-active {
      background: rgba(63, 151, 246, 0.2); }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-primary.mbsc-selected {
      background: #3f97f6; }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-secondary {
    color: #90979E;
    border-color: #90979E; }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-secondary.mbsc-hover, .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-secondary.mbsc-active {
      background: rgba(144, 151, 158, 0.2); }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-secondary.mbsc-selected {
      background: #90979E; }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-success {
    color: #43BE5F;
    border-color: #43BE5F; }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-success.mbsc-hover, .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-success.mbsc-active {
      background: rgba(67, 190, 95, 0.2); }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-success.mbsc-selected {
      background: #43BE5F; }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-danger {
    color: #f5504e;
    border-color: #f5504e; }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-danger.mbsc-hover, .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-danger.mbsc-active {
      background: rgba(245, 80, 78, 0.2); }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-danger.mbsc-selected {
      background: #f5504e; }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-warning {
    color: #f8b042;
    border-color: #f8b042; }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-warning.mbsc-hover, .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-warning.mbsc-active {
      background: rgba(248, 176, 66, 0.2); }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-warning.mbsc-selected {
      background: #f8b042; }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-info {
    color: #5BB7C5;
    border-color: #5BB7C5; }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-info.mbsc-hover, .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-info.mbsc-active {
      background: rgba(91, 183, 197, 0.2); }
    .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-button-info.mbsc-selected {
      background: #5BB7C5; }

.mbsc-material.mbsc-segmented-button.mbsc-button {
  border-color: #1a73e8;
  color: black; }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-hover, .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-active {
    background: rgba(0, 0, 0, 0.2); }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-selected {
    background: #1a73e8;
    color: #fff; }
  .mbsc-material.mbsc-segmented-button.mbsc-button.mbsc-focus::after {
    background: rgba(0, 0, 0, 0.2); }

.mbsc-material-dark.mbsc-segmented-button.mbsc-button {
  border-color: #87b0f3;
  color: white; }
  .mbsc-material-dark.mbsc-segmented-button.mbsc-button.mbsc-hover, .mbsc-material-dark.mbsc-segmented-button.mbsc-button.mbsc-active {
    background: rgba(255, 255, 255, 0.2); }
  .mbsc-material-dark.mbsc-segmented-button.mbsc-button.mbsc-selected {
    background: #87b0f3;
    color: #000; }
  .mbsc-material-dark.mbsc-segmented-button.mbsc-button.mbsc-focus::after {
    background: rgba(255, 255, 255, 0.2); }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-segmented {
  display: -moz-box;
  display: flex;
  overflow: hidden; }

.mbsc-segmented-item {
  margin: 0;
  position: relative;
  vertical-align: top;
  text-align: center;
  font-size: 1em;
  -moz-box-flex: 1;
       flex: 1 1 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  min-width: 0; }

.mbsc-segmented-label {
  display: block;
  margin: 0;
  padding: 0; }

.mbsc-segmented-input {
  width: 1px;
  height: 0;
  opacity: 0;
  position: absolute; }

.mbsc-segmented-button.mbsc-button {
  width: 100%; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-ios.mbsc-stepper-input {
  border-color: #dedede;
  color: #000000; }

.mbsc-ios.mbsc-stepper-input.mbsc-disabled {
  color: rgba(0, 0, 0, 0.2); }

.mbsc-ios.mbsc-stepper-button {
  background: #dedede;
  color: #000000; }

.mbsc-ios.mbsc-stepper-plus:before {
  border-color: rgba(0, 0, 0, 0.2); }

.mbsc-ios-dark.mbsc-stepper-input {
  border-color: #2b2b2b;
  color: #ffffff; }

.mbsc-ios-dark.mbsc-stepper-input.mbsc-disabled {
  color: rgba(255, 255, 255, 0.2); }

.mbsc-ios-dark.mbsc-stepper-button {
  background: #2b2b2b;
  color: #ffffff; }

.mbsc-ios-dark.mbsc-stepper-plus:before {
  border-color: rgba(255, 255, 255, 0.2); }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material.mbsc-stepper-control {
  height: 2.25em; }

.mbsc-material.mbsc-stepper-inner {
  font-size: 2.142857em;
  font-weight: 750; }

.mbsc-material.mbsc-stepper-button.mbsc-button {
  line-height: 1em;
  box-shadow: none;
  border: 2px solid currentColor; }

.mbsc-material.mbsc-stepper-minus.mbsc-ltr, .mbsc-material.mbsc-stepper-plus.mbsc-rtl {
  border-right: 0; }

.mbsc-material.mbsc-stepper-minus.mbsc-rtl, .mbsc-material.mbsc-stepper-plus.mbsc-ltr {
  border-left: 0; }

.mbsc-material.mbsc-stepper-button.mbsc-disabled {
  opacity: 1; }

.mbsc-material.mbsc-stepper-input {
  z-index: 1;
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-left: 0;
  border-right: 0;
  width: 4em; }

.mbsc-material.mbsc-primary .mbsc-stepper-button {
  background: #3f97f6;
  border-color: #3f97f6; }

.mbsc-material.mbsc-secondary .mbsc-stepper-button {
  background: #90979E;
  border-color: #90979E; }

.mbsc-material.mbsc-success .mbsc-stepper-button {
  background: #43BE5F;
  border-color: #43BE5F; }

.mbsc-material.mbsc-danger .mbsc-stepper-button {
  background: #f5504e;
  border-color: #f5504e; }

.mbsc-material.mbsc-warning .mbsc-stepper-button {
  background: #f8b042;
  border-color: #f8b042; }

.mbsc-material.mbsc-info .mbsc-stepper-button {
  background: #5BB7C5;
  border-color: #5BB7C5; }

.mbsc-material.mbsc-primary .mbsc-stepper-input {
  border-color: #3f97f6; }

.mbsc-material.mbsc-secondary .mbsc-stepper-input {
  border-color: #90979E; }

.mbsc-material.mbsc-success .mbsc-stepper-input {
  border-color: #43BE5F; }

.mbsc-material.mbsc-danger .mbsc-stepper-input {
  border-color: #f5504e; }

.mbsc-material.mbsc-warning .mbsc-stepper-input {
  border-color: #f8b042; }

.mbsc-material.mbsc-info .mbsc-stepper-input {
  border-color: #5BB7C5; }

.mbsc-material.mbsc-stepper-input {
  border-color: #d6d6d6;
  color: #5b5b5b; }

.mbsc-material.mbsc-stepper-input.mbsc-disabled {
  color: #b3b3b3; }

.mbsc-material.mbsc-stepper-button {
  background: #d6d6d6;
  color: #d6d6d6; }

.mbsc-material.mbsc-stepper-inner {
  color: #fff; }

.mbsc-material.mbsc-color-none .mbsc-stepper-inner {
  color: #303030; }

.mbsc-material.mbsc-stepper-button.mbsc-disabled {
  background: #d6d6d6; }

.mbsc-material.mbsc-stepper-button.mbsc-disabled .mbsc-stepper-inner {
  color: #b3b3b3; }

.mbsc-material-dark.mbsc-stepper-input {
  border-color: #595959;
  color: #c2c2c2; }

.mbsc-material-dark.mbsc-stepper-input.mbsc-disabled {
  color: #737373; }

.mbsc-material-dark.mbsc-stepper-button {
  background: #595959;
  color: #595959; }

.mbsc-material-dark.mbsc-stepper-inner {
  color: #000; }

.mbsc-material-dark.mbsc-color-none .mbsc-stepper-inner {
  color: #fff; }

.mbsc-material-dark.mbsc-stepper-button.mbsc-disabled {
  background: #595959; }

.mbsc-material-dark.mbsc-stepper-button.mbsc-disabled .mbsc-stepper-inner {
  color: #737373; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-windows.mbsc-stepper-input {
  border-color: #cccccc;
  color: #333333; }

.mbsc-windows.mbsc-stepper-input.mbsc-disabled {
  color: #b3b3b3; }

.mbsc-windows.mbsc-stepper-button {
  color: #cccccc; }

.mbsc-windows.mbsc-stepper-button.mbsc-hover:before {
  background: rgba(0, 0, 0, 0.2); }

.mbsc-windows.mbsc-stepper-button.mbsc-disabled {
  background: #cccccc; }

.mbsc-windows.mbsc-stepper-inner {
  color: #333333; }

.mbsc-windows.mbsc-stepper-button.mbsc-disabled .mbsc-stepper-inner {
  color: #b3b3b3; }

.mbsc-windows-dark.mbsc-stepper-input {
  border-color: #404040;
  color: #ffffff; }

.mbsc-windows-dark.mbsc-stepper-input.mbsc-disabled {
  color: gray; }

.mbsc-windows-dark.mbsc-stepper-button {
  color: #404040; }

.mbsc-windows-dark.mbsc-stepper-button.mbsc-hover:before {
  background: rgba(255, 255, 255, 0.2); }

.mbsc-windows-dark.mbsc-stepper-button.mbsc-disabled {
  background: #404040; }

.mbsc-windows-dark.mbsc-stepper-inner {
  color: #ffffff; }

.mbsc-windows-dark.mbsc-stepper-button.mbsc-disabled .mbsc-stepper-inner {
  color: gray; }

.mbsc-stepper {
  line-height: 1.25em;
  position: relative;
  display: block;
  margin: 0;
  z-index: 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  padding: 1.5em 11.75em 1.5em 1em; }

.mbsc-stepper.mbsc-rtl {
  padding: 1.5em 1em 1.5em 11.75em; }

.mbsc-stepper-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.mbsc-stepper-input {
  width: 3.571429em;
  padding: 0;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  font-size: .875em;
  text-align: center;
  opacity: 1;
  z-index: 3;
  background: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

.mbsc-stepper-input::-webkit-outer-spin-button,
.mbsc-stepper-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.mbsc-stepper-input:focus {
  outline-width: 0; }

.mbsc-stepper-input.mbsc-disabled {
  cursor: not-allowed; }

.mbsc-stepper-control {
  position: absolute;
  display: -moz-box;
  display: flex;
  z-index: 2;
  margin-top: -1em;
  top: 50%;
  height: 2em; }

.mbsc-stepper-control.mbsc-ltr {
  right: 1em; }

.mbsc-stepper-control.mbsc-rtl {
  left: 1em; }

.mbsc-stepper-start .mbsc-stepper-input {
  border: none;
  -moz-box-ordinal-group: 0;
       order: -1; }

.mbsc-stepper-start.mbsc-ltr .mbsc-stepper-minus.mbsc-button {
  margin-right: -1px; }

.mbsc-stepper-start.mbsc-rtl .mbsc-stepper-minus.mbsc-button {
  margin-left: -1px; }

.mbsc-stepper-end .mbsc-stepper-input {
  border: none;
  -moz-box-ordinal-group: 3;
       order: 2; }

.mbsc-stepper-end.mbsc-ltr .mbsc-stepper-minus.mbsc-button {
  margin-right: -1px; }

.mbsc-stepper-end.mbsc-rtl .mbsc-stepper-minus.mbsc-button {
  margin-left: -1px; }

.mbsc-stepper-button.mbsc-button.mbsc-font {
  width: 3.25em;
  margin: 0;
  display: inline-block; }

.mbsc-stepper-inner {
  font-size: 2em;
  font-weight: bold; }

.mbsc-stepper-minus.mbsc-button.mbsc-ltr {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.mbsc-stepper-plus.mbsc-button.mbsc-ltr {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.mbsc-stepper-minus.mbsc-button.mbsc-rtl {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.mbsc-stepper-plus.mbsc-button.mbsc-rtl {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material {
  /* Color presets */ }
  .mbsc-material.mbsc-switch-right {
    padding: .9375em 4em .9375em 1em; }
  .mbsc-material.mbsc-switch-left {
    padding: .9375em 1em .9375em 4em; }
  .mbsc-material.mbsc-switch-track {
    width: 1.75em;
    height: .875em;
    padding: 0 .25em;
    margin-top: -.4375em;
    border-radius: 1.25em; }
  .mbsc-material.mbsc-switch-track-left {
    left: 1.25em; }
  .mbsc-material.mbsc-switch-track-right {
    right: 1.25em; }
  .mbsc-material.mbsc-switch-handle {
    width: 1.25em;
    height: 1.25em;
    border-radius: 1.25em;
    top: 50%;
    margin-left: -.625em;
    margin-top: -.625em; }
  .mbsc-material.mbsc-switch-handle:before {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    border-radius: 2.875em;
    top: -.625em;
    left: -.625em;
    width: 2.5em;
    height: 2.5em; }
  .mbsc-material.mbsc-switch-handle.mbsc-focus:before {
    opacity: .5; }
  .mbsc-material.mbsc-switch-handle.mbsc-active:before {
    opacity: 1; }
  .mbsc-material.mbsc-switch-handle.mbsc-switch-primary.mbsc-checked {
    background: #3f97f6; }
  .mbsc-material.mbsc-switch-track.mbsc-switch-primary.mbsc-checked {
    background: #a0ccfb; }
  .mbsc-material.mbsc-switch-handle.mbsc-switch-secondary.mbsc-checked {
    background: #90979E; }
  .mbsc-material.mbsc-switch-track.mbsc-switch-secondary.mbsc-checked {
    background: #c6cace; }
  .mbsc-material.mbsc-switch-handle.mbsc-switch-success.mbsc-checked {
    background: #43BE5F; }
  .mbsc-material.mbsc-switch-track.mbsc-switch-success.mbsc-checked {
    background: #8fd8a0; }
  .mbsc-material.mbsc-switch-handle.mbsc-switch-danger.mbsc-checked {
    background: #f5504e; }
  .mbsc-material.mbsc-switch-track.mbsc-switch-danger.mbsc-checked {
    background: #faafaf; }
  .mbsc-material.mbsc-switch-handle.mbsc-switch-warning.mbsc-checked {
    background: #f8b042; }
  .mbsc-material.mbsc-switch-track.mbsc-switch-warning.mbsc-checked {
    background: #fcd9a4; }
  .mbsc-material.mbsc-switch-handle.mbsc-switch-info.mbsc-checked {
    background: #5BB7C5; }
  .mbsc-material.mbsc-switch-track.mbsc-switch-info.mbsc-checked {
    background: #a6d8e0; }

.mbsc-material.mbsc-switch-track {
  background: #c4c4c4; }
  .mbsc-material.mbsc-switch-track.mbsc-checked {
    background: rgba(26, 115, 232, 0.3); }
  .mbsc-material.mbsc-switch-track.mbsc-disabled {
    background: #e8e8e8; }

.mbsc-material.mbsc-switch-handle {
  background: white;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12); }
  .mbsc-material.mbsc-switch-handle.mbsc-checked {
    background: #1a73e8; }
  .mbsc-material.mbsc-switch-handle.mbsc-disabled {
    background: #cccccc; }

.mbsc-material.mbsc-switch-handle:before {
  background: rgba(0, 0, 0, 0.1); }

.mbsc-material-dark.mbsc-switch-track {
  background: #2b2b2b; }
  .mbsc-material-dark.mbsc-switch-track.mbsc-checked {
    background: rgba(135, 176, 243, 0.3); }
  .mbsc-material-dark.mbsc-switch-track.mbsc-disabled {
    background: #2b2b2b; }

.mbsc-material-dark.mbsc-switch-handle {
  background: #878787;
  box-shadow: 0 3px 1px -2px rgba(255, 255, 255, 0.2), 0 1px 5px 0 rgba(255, 255, 255, 0.12); }
  .mbsc-material-dark.mbsc-switch-handle.mbsc-checked {
    background: #87b0f3; }
  .mbsc-material-dark.mbsc-switch-handle.mbsc-disabled {
    background: #292929; }

.mbsc-material-dark.mbsc-switch-handle:before {
  background: rgba(255, 255, 255, 0.1); }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-switch {
  position: relative;
  display: block;
  margin: 0;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  line-height: 1.25em; }

.mbsc-switch-track {
  position: absolute;
  top: 50%;
  z-index: 4;
  display: block;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-switch-handle {
  display: block;
  position: absolute;
  cursor: pointer; }
  .mbsc-switch-handle.mbsc-disabled {
    cursor: not-allowed; }

.mbsc-switch-handle-animate {
  transition: left .1s ease-in-out; }

.mbsc-description {
  display: block;
  font-size: .75em;
  opacity: .6; }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material {
  /* Range Highlight */
  /* Marks */
  /* Labels */
  /* Desktop style */ }
  .mbsc-material.mbsc-calendar-wrapper-fixed:after {
    content: '';
    position: absolute;
    z-index: 6;
    bottom: -0.5em;
    left: 0;
    right: 0;
    height: 0.5em;
    pointer-events: none; }
  .mbsc-material.mbsc-calendar-controls {
    padding: 0.5em; }
  .mbsc-material.mbsc-calendar-week-day {
    height: 2.5em;
    line-height: 2.5em;
    font-size: 0.75em;
    font-weight: bold; }
  .mbsc-material.mbsc-calendar-week-nr {
    width: 2.363637em;
    height: auto;
    font-size: 0.6875em;
    line-height: 3em;
    font-weight: bold; }
  .mbsc-material.mbsc-calendar-button.mbsc-button {
    padding: .428572em; }
  .mbsc-material.mbsc-calendar-title {
    font-size: 1.428572em;
    font-weight: 400;
    text-transform: none;
    line-height: 1.4em; }
  .mbsc-material.mbsc-calendar-cell-text {
    height: 2em;
    padding: 0 .5em;
    line-height: 2em;
    border-radius: 2em; }
  .mbsc-material.mbsc-calendar-month-name {
    font-size: .8125em; }
  .mbsc-material.mbsc-calendar-month-name.mbsc-ltr {
    padding-left: .461539em; }
  .mbsc-material.mbsc-calendar-month-name.mbsc-rtl {
    padding-right: .461539em; }
  .mbsc-material.mbsc-calendar-day-text {
    height: 1.846154em;
    width: 1.846154em;
    line-height: 1.846154em;
    margin: .230769em auto;
    padding: 0;
    font-size: .8125em;
    border: 2px solid transparent; }
  .mbsc-material.mbsc-range-day::after, .mbsc-material.mbsc-range-hover::before {
    content: '';
    height: 1.75em;
    position: absolute;
    left: 0;
    right: 0;
    top: .25em;
    z-index: -1; }
  .mbsc-material.mbsc-range-hover::before {
    -moz-box-sizing: content-box;
         box-sizing: content-box;
    height: 1.5em;
    border-top: 2px dashed;
    border-bottom: 2px dashed; }
  .mbsc-material.mbsc-range-day-start.mbsc-ltr::after, .mbsc-material.mbsc-range-day-end.mbsc-rtl::after {
    margin-left: -.875em;
    border-radius: 2em 0 0 2em;
    left: 50%;
    right: 0; }
  .mbsc-material.mbsc-range-day-end.mbsc-ltr::after, .mbsc-material.mbsc-range-day-start.mbsc-rtl::after {
    margin-right: -.875em;
    border-radius: 0 2em 2em 0;
    right: 50%;
    left: 0; }
  .mbsc-material.mbsc-range-hover-start.mbsc-ltr::before, .mbsc-material.mbsc-range-hover-end.mbsc-rtl::before {
    left: 50%;
    right: 0; }
  .mbsc-material.mbsc-range-hover-end.mbsc-ltr::before, .mbsc-material.mbsc-range-hover-start.mbsc-rtl::before {
    right: 50%;
    left: 0; }
  .mbsc-material.mbsc-range-day-start.mbsc-range-day-end::after, .mbsc-material.mbsc-range-hover-start.mbsc-range-hover-end::before {
    display: none; }
  .mbsc-material.mbsc-calendar-marks {
    margin-top: -.375em;
    transition: -webkit-transform .1s ease-out;
    transition: transform .1s ease-out;
    transition: transform .1s ease-out, -webkit-transform .1s ease-out; }
  .mbsc-material.mbsc-focus .mbsc-calendar-marks,
  .mbsc-material.mbsc-hover .mbsc-calendar-marks,
  .mbsc-material.mbsc-selected .mbsc-calendar-marks,
  .mbsc-material.mbsc-calendar-day-colors .mbsc-calendar-marks,
  .mbsc-material.mbsc-highlighted .mbsc-calendar-marks,
  .mbsc-material.mbsc-hover-highlighted .mbsc-calendar-marks {
    -webkit-transform: translate3d(0, 0.25em, 0);
            transform: translate3d(0, 0.25em, 0); }
  .mbsc-material.mbsc-calendar-label {
    font-weight: 600;
    border-radius: .4em; }
  .mbsc-material.mbsc-calendar-label-background {
    margin: 0 -.1em;
    background: currentColor; }
  .mbsc-material.mbsc-calendar-text:before {
    border-radius: .4em; }
  .mbsc-material.mbsc-calendar-label:before {
    margin: 0 -.1em;
    border-radius: 0; }
  .mbsc-material.mbsc-calendar-label-start.mbsc-ltr .mbsc-calendar-label-background,
  .mbsc-material.mbsc-calendar-label-end.mbsc-rtl .mbsc-calendar-label-background, .mbsc-material.mbsc-calendar-label-start.mbsc-ltr.mbsc-calendar-text:before, .mbsc-material.mbsc-calendar-label-end.mbsc-rtl.mbsc-calendar-text:before {
    margin-left: 0;
    border-top-left-radius: .4em;
    border-bottom-left-radius: .4em; }
  .mbsc-material.mbsc-calendar-label-end.mbsc-ltr .mbsc-calendar-label-background,
  .mbsc-material.mbsc-calendar-label-start.mbsc-rtl .mbsc-calendar-label-background, .mbsc-material.mbsc-calendar-label-end.mbsc-ltr.mbsc-calendar-text:before, .mbsc-material.mbsc-calendar-label-start.mbsc-rtl.mbsc-calendar-text:before {
    margin-right: 0;
    border-top-right-radius: .4em;
    border-bottom-right-radius: .4em; }
  .mbsc-material.mbsc-calendar-height-md .mbsc-calendar-slide {
    padding: 0; }
  .mbsc-material.mbsc-calendar-height-md .mbsc-calendar-day {
    border-left: 1px solid;
    border-bottom: 1px solid; }
  .mbsc-material.mbsc-calendar-height-md .mbsc-calendar-week-nr {
    font-size: 0.6875em;
    line-height: 3.636363em; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-week-day {
    padding: 0 1em; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-week-nr {
    padding: 0; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-day-inner {
    min-height: 4em; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
    min-height: 5.5em; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-day-text {
    margin: .461539em; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-marks {
    padding: 0 1.125em;
    margin-left: -1px;
    margin-right: -1px; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-text {
    height: 1.8em;
    line-height: 1.8em;
    margin: 0 .4em .2em .4em; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-label {
    padding: 0 .4em; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-label-text {
    font-size: 1.2em; }
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-label:before,
  .mbsc-material.mbsc-calendar-width-md .mbsc-calendar-label-background {
    margin: 0 -.4em; }
  .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-week-day.mbsc-ltr,
  .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-day.mbsc-ltr,
  .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-marks.mbsc-ltr {
    text-align: left; }
  .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-week-day.mbsc-rtl,
  .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-day.mbsc-rtl,
  .mbsc-material.mbsc-calendar-height-md.mbsc-calendar-width-md .mbsc-calendar-marks.mbsc-rtl {
    text-align: right; }

.mbsc-material {
  /* range highlight and selection */
  /* Marks */
  /* Picker */
  /* Desktop style */
  /* Multi month grid view */ }
  .mbsc-material.mbsc-calendar-wrapper:after {
    box-shadow: inset 0 0.5em 0.25em -0.5em rgba(48, 48, 48, 0.5); }
  .mbsc-material.mbsc-calendar-button.mbsc-button {
    color: #303030; }
  .mbsc-material.mbsc-calendar-slide {
    background: #fff; }
  .mbsc-material.mbsc-calendar-picker-slide {
    background: #fff; }
  .mbsc-material.mbsc-calendar-week-day {
    color: rgba(48, 48, 48, 0.7); }
  .mbsc-material.mbsc-calendar-cell-text {
    color: #303030; }
  .mbsc-material.mbsc-calendar-week-nr, .mbsc-material.mbsc-calendar-today {
    color: #1a73e8; }
  .mbsc-material.mbsc-focus .mbsc-calendar-cell-text {
    box-shadow: 0 0 0 2px rgba(48, 48, 48, 0.7); }
  .mbsc-material.mbsc-hover .mbsc-calendar-cell-text {
    background: rgba(0, 0, 0, 0.1); }
  .mbsc-material.mbsc-range-hover::before {
    border-color: #e6e6e6; }
  .mbsc-material.mbsc-range-day::after {
    background-color: rgba(26, 115, 232, 0.25); }
  .mbsc-material.mbsc-selected .mbsc-calendar-cell-text {
    background: #1a73e8;
    border-color: #1a73e8;
    color: #fff; }
  .mbsc-material.mbsc-calendar-mark {
    background: #1a73e8; }
  .mbsc-material.mbsc-calendar-label {
    color: #1a73e8; }
  .mbsc-material.mbsc-calendar-label-inner {
    color: #fff; }
  .mbsc-material.mbsc-calendar-label.mbsc-calendar-label-active, .mbsc-material.mbsc-calendar-label.mbsc-calendar-label-dragging {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.2); }
  .mbsc-material.mbsc-calendar-text-more {
    box-shadow: none; }
  .mbsc-material.mbsc-calendar-text-more .mbsc-calendar-label-text {
    color: #303030; }
  .mbsc-material.mbsc-calendar-popup .mbsc-popup-arrow,
  .mbsc-material.mbsc-calendar-popup .mbsc-popup-body {
    background: #fff; }
  .mbsc-material.mbsc-calendar-height-md .mbsc-calendar-week-day,
  .mbsc-material.mbsc-calendar-height-md .mbsc-calendar-day {
    border-color: #cfcfcf; }
  .mbsc-material.mbsc-calendar-month-title {
    color: #1a73e8; }

.mbsc-material-dark {
  /* range highlight and selection */
  /* Marks */
  /* Picker */
  /* Desktop style */
  /* Multi month grid view */ }
  .mbsc-material-dark.mbsc-calendar-wrapper:after {
    box-shadow: inset 0 0.5em 0.25em -0.5em rgba(255, 255, 255, 0.5); }
  .mbsc-material-dark.mbsc-calendar-button.mbsc-button {
    color: #fff; }
  .mbsc-material-dark.mbsc-calendar-slide {
    background: #000; }
  .mbsc-material-dark.mbsc-calendar-picker-slide {
    background: #303030; }
  .mbsc-material-dark.mbsc-calendar-week-day {
    color: rgba(255, 255, 255, 0.7); }
  .mbsc-material-dark.mbsc-calendar-cell-text {
    color: #fff; }
  .mbsc-material-dark.mbsc-calendar-week-nr, .mbsc-material-dark.mbsc-calendar-today {
    color: #87b0f3; }
  .mbsc-material-dark.mbsc-focus .mbsc-calendar-cell-text {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.7); }
  .mbsc-material-dark.mbsc-hover .mbsc-calendar-cell-text {
    background: rgba(255, 255, 255, 0.1); }
  .mbsc-material-dark.mbsc-range-hover::before {
    border-color: #1a1a1a; }
  .mbsc-material-dark.mbsc-range-day::after {
    background-color: rgba(135, 176, 243, 0.25); }
  .mbsc-material-dark.mbsc-selected .mbsc-calendar-cell-text {
    background: #87b0f3;
    border-color: #87b0f3;
    color: #000; }
  .mbsc-material-dark.mbsc-calendar-mark {
    background: #87b0f3; }
  .mbsc-material-dark.mbsc-calendar-label {
    color: #87b0f3; }
  .mbsc-material-dark.mbsc-calendar-label-inner {
    color: #000; }
  .mbsc-material-dark.mbsc-calendar-label.mbsc-calendar-label-active, .mbsc-material-dark.mbsc-calendar-label.mbsc-calendar-label-dragging {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.2); }
  .mbsc-material-dark.mbsc-calendar-text-more {
    box-shadow: none; }
  .mbsc-material-dark.mbsc-calendar-text-more .mbsc-calendar-label-text {
    color: #fff; }
  .mbsc-material-dark.mbsc-calendar-popup .mbsc-popup-arrow,
  .mbsc-material-dark.mbsc-calendar-popup .mbsc-popup-body {
    background: #303030; }
  .mbsc-material-dark.mbsc-calendar-height-md .mbsc-calendar-week-day,
  .mbsc-material-dark.mbsc-calendar-height-md .mbsc-calendar-day {
    border-color: #2b2b2b; }
  .mbsc-material-dark.mbsc-calendar-month-title {
    color: #87b0f3; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
@-webkit-keyframes mbsc-zoom-in-up {
  from {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }
@keyframes mbsc-zoom-in-up {
  from {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes mbsc-zoom-in-down {
  from {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes mbsc-zoom-in-down {
  from {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); }
  to {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); } }

@-webkit-keyframes mbsc-zoom-out-up {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); } }

@keyframes mbsc-zoom-out-up {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(2);
            transform: scale(2); } }

@-webkit-keyframes mbsc-zoom-out-down {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

@keyframes mbsc-zoom-out-down {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1); }
  to {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5); } }

.mbsc-calendar {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  touch-action: manipulation;
  position: relative; }

.mbsc-calendar-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; }

/* Header */
.mbsc-calendar-header {
  position: relative;
  z-index: 1;
  will-change: opacity; }
  .mbsc-calendar-header .mbsc-calendar-week-days {
    margin-left: -1px; }

.mbsc-calendar,
.mbsc-calendar-title-wrapper,
.mbsc-calendar-controls {
  display: -moz-box;
  display: flex; }

.mbsc-calendar-controls {
  -moz-box-align: center;
       align-items: center;
  -moz-box-sizing: content-box;
       box-sizing: content-box;
  min-height: 2.5em; }

.mbsc-calendar-button.mbsc-button.mbsc-reset {
  margin: 0; }

.mbsc-calendar-button-prev-multi {
  -moz-box-ordinal-group: 0;
       order: -1; }

.mbsc-calendar-button-next-multi {
  -moz-box-ordinal-group: 2;
       order: 1; }

.mbsc-calendar-body {
  height: 100%;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  overflow: hidden; }

.mbsc-calendar-body-inner {
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-left: -1px; }

.mbsc-calendar-wrapper-fixed {
  display: block;
  height: auto;
  overflow: visible;
  -moz-box-flex: 0;
       flex: 0 0 auto; }
  .mbsc-calendar-wrapper-fixed .mbsc-calendar-header {
    display: block; }
  .mbsc-calendar-wrapper-fixed .mbsc-calendar-body {
    display: block;
    height: auto; }

.mbsc-calendar-hidden {
  visibility: hidden; }

.mbsc-calendar-title-wrapper {
  -moz-box-flex: 1;
       flex: 1;
  -moz-box-align: center;
       align-items: center;
  overflow: hidden; }

.mbsc-calendar-title-wrapper-multi .mbsc-calendar-button {
  -moz-box-flex: 1;
       flex: 1; }

.mbsc-calendar-title {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.125em;
  padding: 0 0.25em;
  display: inline-block;
  vertical-align: middle; }

/* Scrollview */
.mbsc-calendar-scroll-wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  height: 100%; }

.mbsc-calendar-picker-wrapper {
  position: relative;
  width: 15em;
  height: 15em;
  overflow: hidden; }

.mbsc-calendar-picker {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; }

.mbsc-calendar-picker-main {
  position: relative;
  z-index: 0; }

.mbsc-calendar-picker-in-up {
  opacity: 0;
  -webkit-animation: mbsc-zoom-in-up 200ms cubic-bezier(0, 0, 0.2, 1);
          animation: mbsc-zoom-in-up 200ms cubic-bezier(0, 0, 0.2, 1); }

.mbsc-calendar-picker-in-down {
  opacity: 0;
  -webkit-animation: mbsc-zoom-in-down 200ms cubic-bezier(0, 0, 0.2, 1);
          animation: mbsc-zoom-in-down 200ms cubic-bezier(0, 0, 0.2, 1); }

.mbsc-calendar-picker-out-up {
  opacity: 0;
  -webkit-animation: mbsc-zoom-out-up 200ms cubic-bezier(0.4, 0, 1, 1);
          animation: mbsc-zoom-out-up 200ms cubic-bezier(0.4, 0, 1, 1); }

.mbsc-calendar-picker-out-down {
  opacity: 0;
  -webkit-animation: mbsc-zoom-out-down 200ms cubic-bezier(0, 0, 0.2, 1);
          animation: mbsc-zoom-out-down 200ms cubic-bezier(0, 0, 0.2, 1); }

.mbsc-calendar-scroll-wrapper > div {
  height: 100%; }

.mbsc-calendar-scroll-wrapper > div > div {
  height: 100%;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.mbsc-calendar-slide {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-calendar-slide.mbsc-ltr {
  left: 0; }

.mbsc-calendar-slide.mbsc-rtl {
  right: 0; }

.mbsc-calendar-slide:first-child {
  position: relative; }

.mbsc-calendar-table {
  height: 100%;
  -webkit-transform: translateZ(0);
          transform: translateZ(0); }

.mbsc-calendar-cell {
  position: relative;
  text-align: center;
  white-space: nowrap;
  cursor: pointer; }

.mbsc-calendar-cell:focus {
  outline: 0; }

.mbsc-calendar-cell-inner {
  display: inline-block;
  padding: 0 .25em;
  vertical-align: middle; }

.mbsc-calendar-cell.mbsc-disabled {
  cursor: not-allowed; }

.mbsc-calendar-week-day {
  height: 2em;
  font-size: 0.6875em;
  line-height: 2em;
  text-align: center;
  border-bottom: 1px solid transparent; }

.mbsc-calendar-year,
.mbsc-calendar-month {
  vertical-align: middle; }

.mbsc-calendar-year-text,
.mbsc-calendar-month-text {
  margin: .875em 0;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 4.5em;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-calendar-day {
  /* if only width is used, layout is broken with week numbers displayed */
  max-width: 14.285715%;
  text-align: center;
  vertical-align: top; }

.mbsc-calendar-day-empty {
  cursor: default; }

.mbsc-calendar-day-text {
  display: inline-block;
  text-align: center;
  -moz-box-sizing: content-box;
       box-sizing: content-box; }

.mbsc-calendar-day-inner {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  border: 1px solid transparent;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-calendar-day-hidden {
  visibility: hidden; }

.mbsc-calendar-month-name {
  display: none; }

.mbsc-calendar-width-md .mbsc-calendar-month-name {
  display: inline-block;
  font-weight: bold; }

.mbsc-calendar-day-outer .mbsc-calendar-day-text,
.mbsc-calendar-day-outer .mbsc-calendar-month-name {
  opacity: .5; }

.mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-day-text,
.mbsc-calendar-day-outer.mbsc-selected .mbsc-calendar-month-name {
  opacity: 1; }

.mbsc-disabled .mbsc-calendar-cell-text,
.mbsc-disabled .mbsc-calendar-month-name {
  opacity: .2; }

/* Day highlight */
.mbsc-calendar-day-highlight:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); }

/* Marks */
.mbsc-calendar-day-marked {
  padding-bottom: 0.25em; }

.mbsc-calendar-marks {
  position: absolute;
  left: 0;
  right: 0;
  height: 0.375em;
  margin-top: -0.125em;
  overflow: hidden;
  white-space: nowrap;
  text-align: center; }

.mbsc-calendar-mark {
  display: inline-block;
  vertical-align: top;
  width: 0.25em;
  height: 0.25em;
  margin: 0.0625em;
  border-radius: 0.25em; }

/* Labels */
.mbsc-calendar-labels {
  position: absolute;
  left: 0;
  right: 0; }

.mbsc-calendar-label-wrapper {
  position: absolute;
  z-index: 2;
  pointer-events: none; }

.mbsc-calendar-label {
  padding: 0 .3em; }

.mbsc-calendar-text:focus {
  outline: 0; }

.mbsc-calendar-label-text {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  z-index: 2; }

.mbsc-calendar-text:before,
.mbsc-calendar-label-background {
  content: '';
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color .2s; }

.mbsc-calendar-label.mbsc-calendar-label-hover:before {
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1; }

.mbsc-calendar-text-more {
  padding: 0 .3em; }

.mbsc-calendar-text-more.mbsc-calendar-label-active:before,
.mbsc-calendar-text-more.mbsc-calendar-label-hover:before {
  background-color: rgba(0, 0, 0, 0.1); }

.mbsc-calendar-text {
  display: block;
  position: relative;
  height: 1.6em;
  line-height: 1.6em;
  font-size: 0.625em;
  white-space: nowrap;
  margin: 0 0.1em 0.2em 0.1em; }

.mbsc-calendar-text-placeholder {
  z-index: -1; }

.mbsc-calendar-text.mbsc-ltr {
  text-align: left; }

.mbsc-calendar-text.mbsc-rtl {
  text-align: right; }

/* Label drag & drop */
.mbsc-calendar-label-resize {
  position: absolute;
  top: 0;
  height: 100%;
  width: .5em;
  z-index: 2;
  cursor: ew-resize; }

.mbsc-calendar-label-resize:after {
  content: '';
  display: none;
  position: absolute;
  top: 50%;
  width: .8em;
  height: .8em;
  margin-top: -.4em;
  background: #fff;
  border-radius: .8em;
  border: 1px solid currentColor;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-calendar-label-resize-start.mbsc-ltr,
.mbsc-calendar-label-resize-end.mbsc-rtl {
  left: 0; }

.mbsc-calendar-label-resize-start.mbsc-rtl,
.mbsc-calendar-label-resize-end.mbsc-ltr {
  right: 0; }

.mbsc-calendar-label-resize-start.mbsc-ltr:after,
.mbsc-calendar-label-resize-end.mbsc-rtl:after {
  left: -.4em; }

.mbsc-calendar-label-resize-end.mbsc-ltr:after,
.mbsc-calendar-label-resize-start.mbsc-rtl:after {
  right: -.4em; }

.mbsc-calendar-label-resize-start-touch:before,
.mbsc-calendar-label-resize-end-touch:before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1em;
  width: 2em;
  height: 2em; }

.mbsc-calendar-label-resize-start-touch:before {
  left: -1em; }

.mbsc-calendar-label-resize-end-touch:before {
  right: -1em; }

.mbsc-calendar-label-hover .mbsc-calendar-label-resize:after,
.mbsc-calendar-label-dragging .mbsc-calendar-label-resize:after {
  display: block; }

.mbsc-calendar-label-inactive {
  opacity: .4; }

.mbsc-calendar-label-hidden {
  visibility: hidden; }

.mbsc-calendar-labels-dragging {
  z-index: 3; }

/* Multi month grid view */
.mbsc-calendar-grid {
  overflow: auto;
  padding: 0 2%; }
  .mbsc-calendar-grid .mbsc-calendar-table {
    margin: 0 5%;
    width: 90%; }

.mbsc-calendar-grid-item {
  margin: 1em 0;
  padding-top: 2.5em; }

.mbsc-calendar-month-title {
  padding-bottom: .8em;
  margin: 0 10%;
  margin-top: -2em;
  font-size: 1.25em;
  font-weight: bold;
  line-height: 1.2em; }

/* Variable row */
.mbsc-calendar-body-inner-variable {
  overflow-y: auto; }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material.mbsc-list-header {
  margin-top: -1px;
  font-size: .875em;
  font-weight: 600;
  padding: .5714em 1.14285em; }

.mbsc-material.mbsc-list-header {
  background: #fff;
  color: #1a73e8; }

.mbsc-material.mbsc-list-item {
  background: #fff;
  color: #303030; }

.mbsc-material.mbsc-list-item.mbsc-hover:before {
  background: rgba(0, 0, 0, 0.05); }

.mbsc-material.mbsc-list-item.mbsc-active:before {
  background: rgba(0, 0, 0, 0.1); }

.mbsc-material-dark.mbsc-list-header {
  background: #000;
  color: #87b0f3; }

.mbsc-material-dark.mbsc-list-item {
  background: #000;
  color: #fff; }

.mbsc-material-dark.mbsc-list-item.mbsc-hover:before {
  background: rgba(0, 0, 0, 0.05); }

.mbsc-material-dark.mbsc-list-item.mbsc-active:before {
  background: rgba(0, 0, 0, 0.1); }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-list {
  display: block; }

.mbsc-list-header {
  display: -moz-box;
  display: flex; }

.mbsc-list-item {
  position: relative;
  z-index: 2;
  list-style: none;
  display: -moz-box;
  display: flex;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  overflow: hidden;
  line-height: 1.25em; }

.mbsc-list-item-actionable {
  cursor: pointer; }

.mbsc-list-item:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  transition: background-color .2s ease-out; }

.mbsc-list-item:focus {
  outline: 0; }

.mbsc-list-item::-moz-focus-inner {
  border: 0; }

.mbsc-schedule-grid-wrapper {
  overflow: hidden;
  position: relative; }

.mbsc-timeline-resource-col,
.mbsc-schedule-time-col {
  -moz-box-flex: 0;
       flex: none;
  width: 4.25em;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

/* Schedule grid */
.mbsc-timeline-grid-scroll,
.mbsc-schedule-grid-scroll {
  overflow-x: auto;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.mbsc-schedule-grid {
  -moz-box-flex: 1;
       flex: 1 0 auto; }

/* Borders */
.mbsc-schedule-all-day-item::after,
.mbsc-timeline-day::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1; }

.mbsc-schedule-all-day-item.mbsc-ltr::after,
.mbsc-timeline-day.mbsc-ltr::after {
  left: 100%;
  border-right-style: solid;
  border-right-width: 1px; }

.mbsc-schedule-all-day-item.mbsc-rtl::after,
.mbsc-timeline-day.mbsc-rtl::after {
  right: 100%;
  border-left-style: solid;
  border-left-width: 1px; }

.mbsc-timeline-slot-header.mbsc-rtl,
.mbsc-timeline-header-date.mbsc-rtl,
.mbsc-timeline-resource.mbsc-rtl,
.mbsc-timeline-resource-empty.mbsc-rtl,
.mbsc-timeline-header-column.mbsc-rtl,
.mbsc-timeline-column.mbsc-rtl,
.mbsc-schedule-column.mbsc-ltr {
  border-left-style: solid;
  border-left-width: 1px; }

.mbsc-timeline-slot-header.mbsc-ltr,
.mbsc-timeline-header-date.mbsc-ltr,
.mbsc-timeline-resource.mbsc-ltr,
.mbsc-timeline-resource-empty.mbsc-ltr,
.mbsc-timeline-header-column.mbsc-ltr,
.mbsc-timeline-column.mbsc-ltr,
.mbsc-schedule-column.mbsc-rtl {
  border-right-style: solid;
  border-right-width: 1px; }

/* Schedule event */
.mbsc-schedule-event:focus {
  outline: none; }

.mbsc-schedule-event {
  position: absolute;
  padding: 1px 0;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  min-height: .75em;
  z-index: 1; }

.mbsc-schedule-event.mbsc-ltr {
  left: 0; }

.mbsc-schedule-event.mbsc-rtl {
  right: 0; }

.mbsc-schedule-event-hover {
  cursor: pointer; }

.mbsc-schedule-event-inner {
  position: relative;
  max-height: 100%;
  margin: 0 .2em;
  line-height: 1.5em;
  font-size: .625em;
  padding: .5em;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  overflow: hidden; }

.mbsc-schedule-event-small-height.mbsc-schedule-event-inner {
  padding-top: 0;
  padding-bottom: 0;
  line-height: 1.2em;
  margin-top: -1px; }

.mbsc-schedule-event-small-height.mbsc-schedule-event-inner .mbsc-schedule-event-title {
  font-size: 1em; }

.mbsc-schedule-event-background {
  position: absolute;
  top: -.5em;
  left: 1px;
  right: 1px;
  bottom: -.5em;
  background: currentColor; }

.mbsc-schedule-event-start .mbsc-schedule-event-background {
  top: 1px; }

.mbsc-schedule-event-end .mbsc-schedule-event-background {
  bottom: 1px; }

.mbsc-schedule-event-range {
  font-size: .8em; }

.mbsc-schedule-event-all-day {
  position: relative;
  display: block;
  padding: 0;
  margin: .125em 0;
  min-height: 0;
  -moz-box-sizing: content-box;
       box-sizing: content-box;
  z-index: 2; }

.mbsc-schedule-event-all-day-placeholder {
  z-index: -1; }

.mbsc-schedule-event-all-day-inner {
  position: relative;
  height: 1.6em;
  line-height: 1.6em;
  margin: 0 .2em;
  padding: 0 .3em;
  font-size: .625em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.mbsc-schedule-event-all-day-background.mbsc-schedule-event-background {
  top: 0;
  bottom: 0; }

/* Timeline event */
.mbsc-timeline-event {
  z-index: 2;
  min-width: .75em; }
  .mbsc-timeline-event .mbsc-schedule-event-title,
  .mbsc-timeline-event .mbsc-schedule-event-range {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden; }

/* Event Drag & drop */
.mbsc-schedule-event-dragging {
  position: absolute;
  top: 0;
  z-index: 3; }

.mbsc-schedule-event-inactive {
  opacity: .5; }

.mbsc-schedule-event-hidden {
  visibility: hidden; }

/* Event resize handles */
.mbsc-schedule-event-resize {
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  height: .25em;
  z-index: 1;
  cursor: ns-resize; }

.mbsc-schedule-event-resize-start {
  top: 0; }

.mbsc-schedule-event-resize-end {
  bottom: 0; }

.mbsc-schedule-event-resize-start:after {
  top: -.25em;
  right: .5em; }

.mbsc-schedule-event-resize-end:after {
  bottom: -.25em;
  left: .5em; }

.mbsc-schedule-event-resize:after {
  content: '';
  position: absolute;
  margin: 1px 0;
  width: .5em;
  height: .5em;
  background: #fff;
  border-radius: 50%;
  border: 1px solid currentColor;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-schedule-event-resize-start-touch:before,
.mbsc-schedule-event-resize-end-touch:before {
  content: '';
  position: absolute;
  margin: 1px 0;
  width: 1.5em;
  height: 1em; }

.mbsc-schedule-event-resize-start-touch:before {
  top: -.5em;
  right: 0; }

.mbsc-schedule-event-resize-end-touch:before {
  bottom: -.5em;
  left: 0; }

.mbsc-schedule-event-dragging .mbsc-schedule-event-resize,
.mbsc-schedule-event-hover .mbsc-schedule-event-resize {
  display: block; }

/* Blocking and colored date styles */
.mbsc-schedule-invalid,
.mbsc-schedule-color {
  position: absolute;
  top: -.5em;
  bottom: -.5em;
  left: 0;
  right: 0;
  -moz-box-sizing: content-box;
       box-sizing: content-box;
  z-index: 0;
  overflow: hidden; }

.mbsc-schedule-invalid-all-day,
.mbsc-schedule-color-all-day {
  top: 0;
  bottom: 0;
  z-index: -1; }

.mbsc-schedule-invalid-start,
.mbsc-schedule-color-start {
  padding-top: .5em;
  margin-top: -.5em; }

.mbsc-schedule-invalid-end,
.mbsc-schedule-color-end {
  padding-bottom: .5em; }

.mbsc-schedule-invalid-text,
.mbsc-schedule-color-text {
  padding: .375em;
  font-size: .75em; }

.mbsc-schedule-color-text {
  opacity: .8; }

/* Time indicator */
.mbsc-schedule-time-indicator {
  position: absolute;
  z-index: 2;
  pointer-events: none; }

.mbsc-schedule-time-indicator-x {
  border-bottom: 1px solid; }

.mbsc-schedule-time-indicator-x.mbsc-ltr {
  left: 4.25em;
  right: 0; }

.mbsc-schedule-time-indicator-x.mbsc-rtl {
  right: 4.25em;
  left: 0; }

.mbsc-schedule-time-indicator-y {
  top: 0;
  bottom: 0;
  border-left: 1px solid; }

.mbsc-schedule-time-indicator-day {
  position: absolute;
  width: 14.285714%; }

.mbsc-schedule-time-indicator-day:before {
  content: '';
  position: absolute;
  width: .4375em;
  height: .4375em;
  margin-top: -.1875em;
  margin-left: -.1875em;
  margin-right: -.1875em;
  border-radius: 50%;
  z-index: 1; }

.mbsc-schedule-time-indicator-time {
  position: absolute;
  top: -1em;
  font-size: .625em;
  line-height: 2em;
  margin-top: 1px;
  padding: 0 .8em;
  white-space: nowrap; }

.mbsc-schedule-time-indicator-time-y {
  top: -1.8em;
  padding: 0 .5em;
  margin-left: -.5em;
  margin-right: -.5em;
  line-height: 1.6em; }

.mbsc-schedule-time-indicator-time-x.mbsc-ltr,
.mbsc-schedule-time-indicator-time-y.mbsc-rtl {
  right: 100%; }

.mbsc-schedule-time-indicator-time-x.mbsc-rtl,
.mbsc-schedule-time-indicator-time-y.mbsc-ltr {
  left: 100%; }

/* Cursor indicator */
.mbsc-cursor-indicator {
  position: absolute;
  z-index: 3; }

.mbsc-cursor-indicator-x {
  top: 1.875em; }

.mbsc-cursor-indicator-y.mbsc-ltr {
  left: 4.25em; }

.mbsc-cursor-indicator-y.mbsc-rtl {
  right: 4.25em; }

.mbsc-cursor-indicator-time {
  line-height: 1.6em;
  font-size: .625em;
  line-height: 2em;
  padding: 0 .8em;
  white-space: nowrap; }

.mbsc-cursor-indicator-time-y {
  position: absolute;
  margin-top: -.5em; }

.mbsc-cursor-indicator-time-y.mbsc-ltr {
  right: 100%; }

.mbsc-cursor-indicator-time-y.mbsc-rtl {
  left: 100%; }

@supports (overflow: clip) {
  .mbsc-schedule-event-inner {
    position: -webkit-sticky;
    position: sticky;
    top: 0; } }

.mbsc-schedule-wrapper {
  overflow: auto; }

/* Resource groups */
.mbsc-schedule-resource-group {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  flex-basis: 16em; }

.mbsc-schedule-resource-group:first-child:last-child {
  flex-basis: 0; }

.mbsc-schedule-resource-group.mbsc-ltr {
  border-left-width: 2px;
  border-left-style: solid; }

.mbsc-schedule-resource-group.mbsc-rtl {
  border-right-width: 2px;
  border-right-style: solid; }

.mbsc-schedule-resource-group:first-child {
  border: 0; }

/* Schedule header */
.mbsc-schedule-date-header-text {
  line-height: 1.5em;
  text-align: center; }

.mbsc-schedule-header {
  overflow: hidden; }

.mbsc-schedule-all-day-group-wrapper,
.mbsc-schedule-header-wrapper {
  min-width: 0;
  overflow: hidden; }

.mbsc-schedule-header-item {
  display: block;
  position: relative;
  text-align: center;
  line-height: 1.25em; }

.mbsc-schedule-header-item.mbsc-hover {
  cursor: pointer; }

.mbsc-schedule-header-dayname {
  font-size: .75em; }

.mbsc-schedule-header-day {
  font-size: 1.125em; }

/* Schedule column width for: header item, header resource, all-day item, schedule column  */
.mbsc-schedule-col-width {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  width: 2.5em; }

.mbsc-schedule-wrapper-multi .mbsc-schedule-col-width {
  width: 6.25em; }

/* Resource title */
.mbsc-schedule-resource {
  overflow: hidden; }

.mbsc-schedule-resource-title {
  padding: .5em;
  font-size: .875em;
  font-weight: 600;
  text-align: center; }

/* Schedule all day event container */
.mbsc-schedule-all-day-wrapper {
  position: relative;
  max-height: 7.8125em;
  overflow-x: hidden;
  overflow-y: scroll; }

.mbsc-schedule-all-day-wrapper-shadow {
  position: relative; }

.mbsc-schedule-all-day-wrapper-shadow:after {
  content: '';
  box-shadow: inset 0 0.5em 0.25em -0.5em rgba(48, 48, 48, 0.5);
  height: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4px;
  z-index: 4;
  pointer-events: none; }

.mbsc-schedule-all-day-item {
  position: relative; }

.mbsc-schedule-all-day-item:first-child,
.mbsc-schedule-column:first-child {
  border: 0; }

/* Time section */
.mbsc-schedule-time-wrapper {
  position: relative;
  width: 4.25em;
  height: 3.125em;
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-schedule-time {
  position: relative;
  line-height: 2em;
  top: -1em;
  font-size: .75em; }

.mbsc-schedule-time-end {
  position: absolute;
  top: auto;
  bottom: -1em;
  left: 0;
  right: 0; }

.mbsc-schedule-time.mbsc-ltr {
  text-align: right; }

.mbsc-schedule-time.mbsc-rtl {
  text-align: left; }

.mbsc-schedule-time-cont-pos {
  position: relative; }

/* Grid */
.mbsc-schedule-time-cont-inner {
  position: absolute;
  width: 100%;
  z-index: 2;
  pointer-events: none;
  height: 100%;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  padding: .5em 0; }

.mbsc-schedule-column {
  padding: .5em 0; }

.mbsc-schedule-column-inner {
  position: relative; }

.mbsc-schedule-all-day-item,
.mbsc-schedule-item {
  -moz-box-sizing: border-box;
       box-sizing: border-box; }

.mbsc-schedule-item {
  height: 3.125em;
  position: relative;
  border-top: 1px solid; }

.mbsc-schedule-item-last {
  border-bottom: 1px solid; }

/* Event spacing */
.mbsc-schedule-events {
  position: absolute;
  height: 100%; }

.mbsc-schedule-events.mbsc-ltr {
  left: 0;
  right: .5em; }

.mbsc-schedule-events.mbsc-rtl {
  right: 0;
  left: .5em; }

/* All day events */
.mbsc-schedule-event-all-day {
  /* Increase touch area for resize dots */ }
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize {
    width: .25em;
    height: 100%;
    cursor: ew-resize; }
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize:after {
    top: 50%;
    right: auto;
    left: auto;
    margin-top: -.25em; }
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start.mbsc-ltr,
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end.mbsc-rtl {
    right: auto; }
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end.mbsc-ltr,
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start.mbsc-rtl {
    left: auto; }
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start.mbsc-ltr:after,
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end.mbsc-rtl:after {
    right: -.25em; }
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end.mbsc-ltr:after,
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start.mbsc-rtl:after {
    left: -.25em; }
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-start-touch:before {
    height: 1.5em;
    top: 50%;
    left: -.75em;
    right: auto;
    margin-top: -.75em; }
  .mbsc-schedule-event-all-day .mbsc-schedule-event-resize-end-touch:before {
    height: 1.5em;
    top: 50%;
    left: auto;
    right: -.75em;
    margin-top: -.75em; }

/* Large screen */
.mbsc-calendar-width-md .mbsc-schedule-event-title {
  font-size: 1.2em; }

.mbsc-calendar-width-md .mbsc-schedule-event-range {
  font-size: 1em; }

.mbsc-calendar-width-md .mbsc-schedule-event-all-day-title {
  font-size: 1em; }

.mbsc-calendar-width-md .mbsc-schedule-event-all-day-inner {
  font-size: .75em;
  height: 1.5em;
  line-height: 1.5em;
  padding: 0 .333334em;
  margin: 0 .333334em; }

.mbsc-timeline {
  overflow: hidden;
  position: relative; }
  .mbsc-timeline.mbsc-ltr {
    margin-right: -2px; }
  .mbsc-timeline.mbsc-rtl {
    margin-left: -2px; }

.mbsc-timeline-header-row-1 {
  height: 1.625em; }

.mbsc-timeline-header-row-2 {
  height: 3.25em; }

.mbsc-timeline-header-row-3 {
  height: 4.875em; }

.mbsc-timeline-header-row-4 {
  height: 6.5em; }

/* Resource column */
.mbsc-timeline-resource-col {
  width: 12em; }

.mbsc-timeline-resources {
  position: absolute;
  top: 0;
  bottom: 15px;
  z-index: 4;
  overflow: hidden; }
  .mbsc-timeline-resources.mbsc-ltr {
    left: 0; }
  .mbsc-timeline-resources.mbsc-rtl {
    right: 0; }

.mbsc-timeline-resource-empty {
  position: relative;
  z-index: 5;
  overflow: hidden;
  pointer-events: auto;
  align-self: flex-start; }

.mbsc-timeline-resource {
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  overflow: hidden; }

/* Hierarchy */
.mbsc-timeline-parent {
  background: rgba(230, 230, 230, 0.25); }

.mbsc-timeline-resource-icon {
  font-size: .875em;
  font-weight: 600;
  margin: .357143em 0;
  cursor: pointer;
  -moz-box-sizing: content-box;
       box-sizing: content-box; }

.mbsc-timeline-resource-icon.mbsc-ltr {
  margin-left: .5em; }

.mbsc-timeline-resource-icon.mbsc-rtl {
  margin-right: .5em; }

.mbsc-timeline-resource-header,
.mbsc-timeline-resource-title {
  font-size: .875em;
  font-weight: 600;
  padding: .5em; }

/* Header */
.mbsc-timeline-header {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 3;
  pointer-events: none; }
  .mbsc-timeline-header .mbsc-schedule-time-indicator {
    top: auto;
    bottom: auto;
    height: 100%;
    margin-top: -.3125em; }

.mbsc-timeline-header-time-cont {
  position: relative; }

.mbsc-timeline-day {
  position: relative;
  min-width: 9em;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  pointer-events: auto; }

.mbsc-timeline-day-month {
  min-width: 0; }

.mbsc-timeline-header-column.mbsc-timeline-no-border,
.mbsc-timeline-column.mbsc-timeline-no-border {
  border-left: 0;
  border-right: 0; }

.mbsc-timeline-month {
  min-width: 0;
  position: relative; }

.mbsc-timeline-header-month,
.mbsc-timeline-header-date,
.mbsc-timeline-header-week {
  height: 1.625em;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  pointer-events: auto; }

.mbsc-timeline-header-month-last.mbsc-ltr,
.mbsc-timeline-header-week-last.mbsc-ltr {
  border-right-width: 1px;
  border-right-style: solid; }

.mbsc-timeline-header-month-last.mbsc-rtl,
.mbsc-timeline-header-week-last.mbsc-rtl {
  border-left-width: 1px;
  border-left-style: solid; }

.mbsc-timeline-header-month-text,
.mbsc-timeline-header-date-text,
.mbsc-timeline-header-week-text {
  font-size: .875em;
  padding: 0 .285714em;
  line-height: 1.785715em;
  display: inline-block;
  vertical-align: top;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden; }

.mbsc-timeline-header-week-text,
.mbsc-timeline-header-month-text {
  overflow: visible;
  max-width: none;
  position: absolute; }

.mbsc-timeline-header-month-text {
  font-weight: bold; }

.mbsc-timeline-header-month-text-last,
.mbsc-timeline-header-week-text-last {
  max-width: 100%;
  overflow: hidden; }

.mbsc-timeline-header-column {
  border-bottom: 1px solid;
  height: 1.625em; }

.mbsc-cursor-indicator-time-x,
.mbsc-timeline-header-time {
  padding: 0 .5em;
  font-size: .625em;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 2.6em; }

/* Grid */
.mbsc-timeline-column,
.mbsc-timeline-header-column {
  width: 4em;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  position: relative; }

.mbsc-timeline-grid-scroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: auto; }

.mbsc-timeline-grid {
  position: relative;
  overflow: hidden; }

.mbsc-timeline-resource,
.mbsc-timeline-row {
  height: 3.25em;
  position: relative;
  -moz-box-flex: 1;
       flex: 1 0 auto; }

.mbsc-timeline-resource-empty,
.mbsc-timeline-resource,
.mbsc-timeline-header-date,
.mbsc-timeline-header-month,
.mbsc-timeline-header-week,
.mbsc-timeline-column {
  border-bottom-style: solid;
  border-bottom-width: 1px; }

/* Events */
.mbsc-timeline-events {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: .5em; }

.mbsc-timeline-event-background {
  top: 1px;
  bottom: 1px; }

.mbsc-timeline-event-resize {
  height: 100%;
  width: .25em;
  right: auto;
  left: auto;
  cursor: ew-resize; }
  .mbsc-timeline-event-resize.mbsc-schedule-event-resize-start.mbsc-ltr, .mbsc-timeline-event-resize.mbsc-schedule-event-resize-end.mbsc-rtl {
    left: 0; }
  .mbsc-timeline-event-resize.mbsc-schedule-event-resize-end.mbsc-ltr, .mbsc-timeline-event-resize.mbsc-schedule-event-resize-start.mbsc-rtl {
    right: 0; }
  .mbsc-timeline-event-resize.mbsc-schedule-event-resize-end:after, .mbsc-timeline-event-resize.mbsc-schedule-event-resize-start:after {
    top: 50%;
    margin-top: -.25em; }
  .mbsc-timeline-event-resize.mbsc-schedule-event-resize-end:after {
    left: -.125em; }
  .mbsc-timeline-event-resize.mbsc-schedule-event-resize-start:after {
    right: -.125em; }
  .mbsc-timeline-event-resize.mbsc-schedule-event-resize-start-touch:before, .mbsc-timeline-event-resize.mbsc-schedule-event-resize-end-touch:before {
    height: 1.5em;
    top: 50%;
    left: 50%;
    margin: -.75em 0 0 -.75em; }

.mbsc-timeline-event-listing {
  position: relative; }
  .mbsc-timeline-event-listing.mbsc-timeline-event-dragging {
    position: absolute;
    top: 0; }
  .mbsc-timeline-event-listing.mbsc-schedule-event-all-day-placeholder {
    padding: 0;
    margin: .125em 0; }

/* Invalid, color */
.mbsc-timeline-color,
.mbsc-timeline-invalid {
  top: 0;
  bottom: 0;
  padding: 0;
  margin: 0; }

/* No time displayed */
.mbsc-timeline-header-column.mbsc-timeline-no-height {
  border: 0;
  height: 0; }

/* Sticky positioning */
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .mbsc-timeline-resources.mbsc-ltr {
    position: -webkit-sticky;
    position: sticky;
    top: auto;
    bottom: auto;
    overflow: visible;
    padding-top: 0; } }

/* Slots */
.mbsc-timeline-slots {
  height: 1.625em;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  border-bottom: 1px solid; }

.mbsc-timeline-slot {
  position: relative;
  width: 8em;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  -moz-box-flex: 1;
       flex: 1 0 auto; }

.mbsc-timeline-slot-title {
  font-size: .875em;
  font-weight: 600;
  line-height: 1.785715em;
  padding: 0 .285714em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

@supports (overflow: clip) {
  .mbsc-timeline-grid {
    overflow: clip; }
  .mbsc-timeline .mbsc-schedule-event-inner {
    display: inline-block;
    max-width: 100%;
    vertical-align: top; }
  .mbsc-timeline.mbsc-ltr .mbsc-schedule-event-inner {
    left: 192px; }
  .mbsc-timeline.mbsc-rtl .mbsc-schedule-event-inner {
    right: 192px; }
  .mbsc-timeline-event-listing .mbsc-schedule-event-inner {
    position: relative;
    display: block; }
  .mbsc-timeline .mbsc-timeline-event-listing .mbsc-schedule-event-inner,
  .mbsc-timeline.mbsc-timeline-no-resource .mbsc-schedule-event-inner {
    left: 0;
    right: 0; } }

/* Base colors */
/* Form colors */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material {
  /* Event list */
  /* Popover list */
  /* Schedule */
  /* Header */
  /* Grid & All-day cont */
  /* Time & cursor indicator */
  /* Event style */ }
  .mbsc-material.mbsc-eventcalendar-schedule .mbsc-calendar-wrapper:after {
    display: none; }
  .mbsc-material.mbsc-event.mbsc-list-item {
    margin: .625em 1em;
    padding: .5em .625em;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
         flex-direction: column;
    border-radius: .214286em;
    transition: box-shadow .2s ease-out; }
  .mbsc-material.mbsc-event-text, .mbsc-material.mbsc-event-time {
    font-size: .8125em;
    line-height: 1.384616em; }
  .mbsc-material.mbsc-event-time {
    display: -moz-box;
    display: flex;
    font-weight: 300; }
  .mbsc-material.mbsc-event-text {
    font-weight: 600; }
  .mbsc-material.mbsc-event-all-day {
    display: none; }
  .mbsc-material.mbsc-event-sep {
    padding: 0 .25em; }
  .mbsc-material.mbsc-event-until {
    padding-right: .25em; }
  .mbsc-material.mbsc-popover-list {
    width: 13em;
    padding: .5em .125em; }
  .mbsc-material.mbsc-popover-list .mbsc-event {
    margin: .125em .5em;
    padding: .25em .5em; }
  .mbsc-material.mbsc-popover-list .mbsc-event-time,
  .mbsc-material.mbsc-popover-list .mbsc-event-text {
    font-size: .75em; }
  .mbsc-material.mbsc-schedule-header-item {
    padding-bottom: .25em; }
  .mbsc-material.mbsc-schedule-header-dayname {
    position: relative;
    line-height: 2.8em;
    font-size: .625em;
    font-weight: 500;
    text-transform: uppercase; }
  .mbsc-material.mbsc-schedule-header-day {
    height: 1.8em;
    width: 1.8em;
    margin-top: -.3em;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.8em;
    font-size: 1.25em;
    border-radius: 50%; }
  .mbsc-material.mbsc-schedule-date-header-text {
    font-size: .875em;
    font-weight: 600;
    padding: .5714em 1.14285em; }
  .mbsc-material.mbsc-schedule-resource.mbsc-ltr {
    border-left-width: 1px;
    border-left-style: solid; }
  .mbsc-material.mbsc-schedule-resource.mbsc-rtl {
    border-right-width: 1px;
    border-right-style: solid; }
  .mbsc-material.mbsc-schedule-resource:first-child {
    border: 0; }
  .mbsc-material.mbsc-schedule-all-day-text {
    padding: .4em .8em;
    font-size: .625em;
    line-height: 1.6em;
    text-transform: uppercase; }
  .mbsc-material.mbsc-schedule-all-day-item {
    padding: 1px 0;
    min-height: 1.5em; }
  .mbsc-material.mbsc-schedule-time-col.mbsc-ltr {
    border-right-width: 1px;
    border-right-style: solid; }
  .mbsc-material.mbsc-schedule-time-col.mbsc-rtl {
    border-left-width: 1px;
    border-left-style: solid; }
  .mbsc-material.mbsc-schedule-time-col.mbsc-schedule-time-col-empty {
    border: 0; }
  .mbsc-material.mbsc-schedule-time-wrapper.mbsc-ltr {
    padding-right: 1em; }
  .mbsc-material.mbsc-schedule-time-wrapper.mbsc-rtl {
    padding-left: 1em; }
  .mbsc-material.mbsc-schedule-time-wrapper:after {
    content: '';
    position: absolute;
    top: 0;
    width: 9px; }
  .mbsc-material.mbsc-schedule-time-wrapper-end:before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 9px; }
  .mbsc-material.mbsc-schedule-time-wrapper-end.mbsc-ltr:before, .mbsc-material.mbsc-schedule-time-wrapper.mbsc-ltr:after {
    right: 0; }
  .mbsc-material.mbsc-schedule-time-wrapper-end.mbsc-rtl:before, .mbsc-material.mbsc-schedule-time-wrapper.mbsc-rtl:after {
    left: 0; }
  .mbsc-material.mbsc-schedule-time {
    font-size: .625em; }
  .mbsc-material.mbsc-schedule-time-end.mbsc-ltr {
    right: 1.6em; }
  .mbsc-material.mbsc-schedule-time-end.mbsc-rtl {
    left: 1.6em; }
  .mbsc-material.mbsc-schedule-time-indicator-x:after {
    content: '';
    position: absolute;
    top: 0;
    width: .5625em;
    border-bottom: 1px solid;
    border-color: inherit; }
  .mbsc-material.mbsc-schedule-time-indicator.mbsc-ltr:after {
    left: -.5625em; }
  .mbsc-material.mbsc-schedule-time-indicator.mbsc-rtl:after {
    right: -.5625em; }
  .mbsc-material.mbsc-schedule-time-indicator-time-x {
    padding: 0 1.6em;
    margin: 0 1px; }
  .mbsc-material.mbsc-cursor-indicator-y.mbsc-ltr {
    left: 3.75em; }
  .mbsc-material.mbsc-cursor-indicator-y.mbsc-rtl {
    right: 3.75em; }
  .mbsc-material.mbsc-schedule-event-inner {
    border-radius: .4em; }
  .mbsc-material.mbsc-schedule-event-start .mbsc-schedule-event-background {
    border-top-left-radius: .333334em;
    border-top-right-radius: .333334em; }
  .mbsc-material.mbsc-schedule-event-end .mbsc-schedule-event-background {
    border-bottom-left-radius: .333334em;
    border-bottom-right-radius: .333334em; }
  .mbsc-material.mbsc-timeline-event-start.mbsc-ltr .mbsc-schedule-event-background, .mbsc-material.mbsc-timeline-event-end.mbsc-rtl .mbsc-schedule-event-background {
    border-bottom-left-radius: .333334em;
    border-top-left-radius: .333334em; }
  .mbsc-material.mbsc-timeline-event-end.mbsc-ltr .mbsc-schedule-event-background, .mbsc-material.mbsc-timeline-event-start.mbsc-rtl .mbsc-schedule-event-background {
    border-bottom-right-radius: .333334em;
    border-top-right-radius: .333334em; }
  .mbsc-material.mbsc-schedule-event-active .mbsc-schedule-event-background, .mbsc-material.mbsc-schedule-event-dragging .mbsc-schedule-event-background {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 1px 3px -1px rgba(0, 0, 0, 0.2); }
  .mbsc-material.mbsc-schedule-event-hover .mbsc-schedule-event-background:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1); }
  .mbsc-material.mbsc-schedule-event-title {
    font-weight: 500; }
  .mbsc-material.mbsc-schedule-event-all-day-background.mbsc-schedule-event-background {
    border-radius: 0; }
  .mbsc-material.mbsc-timeline-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background,
  .mbsc-material.mbsc-timeline-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background,
  .mbsc-material.mbsc-schedule-event-start.mbsc-ltr .mbsc-schedule-event-all-day-background,
  .mbsc-material.mbsc-schedule-event-end.mbsc-rtl .mbsc-schedule-event-all-day-background {
    border-top-left-radius: .333334em;
    border-bottom-left-radius: .333334em; }
  .mbsc-material.mbsc-timeline-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background,
  .mbsc-material.mbsc-timeline-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background,
  .mbsc-material.mbsc-schedule-event-end.mbsc-ltr .mbsc-schedule-event-all-day-background,
  .mbsc-material.mbsc-schedule-event-start.mbsc-rtl .mbsc-schedule-event-all-day-background {
    border-top-right-radius: .333334em;
    border-bottom-right-radius: .333334em; }
  .mbsc-material.mbsc-schedule-header-item-large .mbsc-schedule-header-dayname {
    line-height: 2.909091em;
    font-size: 0.6875em;
    text-transform: uppercase; }
  .mbsc-material.mbsc-schedule-header-item-large .mbsc-schedule-header-day {
    height: 1.769231em;
    width: 1.769231em;
    line-height: 1.769231em;
    font-size: 1.625em;
    margin-top: -.307693em; }

.mbsc-material {
  /* Schedule */
  /* Header */
  /* Grid & All-day cont */
  /* Event style */
  /* Invalid */
  /* Timeline */ }
  .mbsc-material.mbsc-eventcalendar {
    background: #fff;
    color: #303030; }
    .mbsc-material.mbsc-eventcalendar .mbsc-calendar-day.mbsc-disabled {
      background: #f0f0f0; }
  .mbsc-material.mbsc-event-list-empty {
    color: rgba(48, 48, 48, 0.7); }
  .mbsc-material.mbsc-schedule-date-header, .mbsc-material.mbsc-event-day.mbsc-list-header {
    background: #fff;
    color: rgba(48, 48, 48, 0.7); }
  .mbsc-material.mbsc-colored-event.mbsc-list-item {
    background: #1a73e8;
    color: #fff; }
  .mbsc-material.mbsc-event.mbsc-list-item.mbsc-focus {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.4); }
  .mbsc-material.mbsc-event-color {
    background: #1a73e8; }
  .mbsc-material.mbsc-schedule-header-dayname {
    color: #70757a; }
  .mbsc-material.mbsc-schedule-header-day {
    color: #303030; }
  .mbsc-material.mbsc-schedule-header-dayname-curr {
    color: #1a73e8; }
  .mbsc-material.mbsc-schedule-header-day-today {
    color: #1a73e8; }
  .mbsc-material.mbsc-schedule-header-day.mbsc-hover {
    background: rgba(26, 115, 232, 0.3); }
  .mbsc-material.mbsc-schedule-header-day.mbsc-selected {
    background-color: #1a73e8;
    color: #fff; }
  .mbsc-material.mbsc-timeline-day::after, .mbsc-material.mbsc-timeline-slots, .mbsc-material.mbsc-timeline-slot-header, .mbsc-material.mbsc-timeline-header-month, .mbsc-material.mbsc-timeline-header-week, .mbsc-material.mbsc-timeline-header-date, .mbsc-material.mbsc-timeline-resource-empty, .mbsc-material.mbsc-timeline-header-column, .mbsc-material.mbsc-timeline-header, .mbsc-material.mbsc-timeline-resource, .mbsc-material.mbsc-timeline-column, .mbsc-material.mbsc-schedule-resource-group, .mbsc-material.mbsc-schedule-resource, .mbsc-material.mbsc-schedule-time-col, .mbsc-material.mbsc-schedule-all-day-item::after, .mbsc-material.mbsc-schedule-column, .mbsc-material.mbsc-schedule-item {
    border-color: #cfcfcf; }
  .mbsc-material.mbsc-schedule-time-wrapper-end:before, .mbsc-material.mbsc-schedule-time-wrapper:after {
    border-bottom: 1px solid #cfcfcf; }
  .mbsc-material.mbsc-schedule-all-day-text, .mbsc-material.mbsc-schedule-time {
    color: #70757a; }
  .mbsc-material.mbsc-schedule-time-indicator {
    border-color: #1a73e8; }
  .mbsc-material.mbsc-schedule-time-indicator-day:before {
    background: #1a73e8; }
  .mbsc-material.mbsc-cursor-indicator-time, .mbsc-material.mbsc-schedule-time-indicator-time {
    background: rgba(255, 255, 255, 0.8); }
  .mbsc-material.mbsc-schedule-time-indicator-time {
    color: #1a73e8; }
  .mbsc-material.mbsc-schedule-event {
    color: #1a73e8; }
  .mbsc-material.mbsc-schedule-event-inner {
    color: #fff; }
  .mbsc-material.mbsc-schedule-invalid {
    background: rgba(240, 240, 240, 0.75);
    color: #8a8a8a; }
  .mbsc-material.mbsc-timeline-resource-empty, .mbsc-material.mbsc-timeline-resource-bg {
    background: #fff; }

.mbsc-material-dark {
  /* Schedule */
  /* Header */
  /* Grid & All-day cont */
  /* Event style */
  /* Invalid */
  /* Timeline */ }
  .mbsc-material-dark.mbsc-eventcalendar {
    background: #000;
    color: #fff; }
    .mbsc-material-dark.mbsc-eventcalendar .mbsc-calendar-day.mbsc-disabled {
      background: #1a1a1a; }
  .mbsc-material-dark.mbsc-event-list-empty {
    color: rgba(255, 255, 255, 0.7); }
  .mbsc-material-dark.mbsc-schedule-date-header, .mbsc-material-dark.mbsc-event-day.mbsc-list-header {
    background: #000;
    color: rgba(255, 255, 255, 0.7); }
  .mbsc-material-dark.mbsc-colored-event.mbsc-list-item {
    background: #87b0f3;
    color: #000; }
  .mbsc-material-dark.mbsc-event.mbsc-list-item.mbsc-focus {
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.4); }
  .mbsc-material-dark.mbsc-event-color {
    background: #87b0f3; }
  .mbsc-material-dark.mbsc-schedule-header-dayname {
    color: #dddddd; }
  .mbsc-material-dark.mbsc-schedule-header-day {
    color: #fff; }
  .mbsc-material-dark.mbsc-schedule-header-dayname-curr {
    color: #87b0f3; }
  .mbsc-material-dark.mbsc-schedule-header-day-today {
    color: #87b0f3; }
  .mbsc-material-dark.mbsc-schedule-header-day.mbsc-hover {
    background: rgba(135, 176, 243, 0.3); }
  .mbsc-material-dark.mbsc-schedule-header-day.mbsc-selected {
    background-color: #87b0f3;
    color: #000; }
  .mbsc-material-dark.mbsc-timeline-day::after, .mbsc-material-dark.mbsc-timeline-slots, .mbsc-material-dark.mbsc-timeline-slot-header, .mbsc-material-dark.mbsc-timeline-header-month, .mbsc-material-dark.mbsc-timeline-header-week, .mbsc-material-dark.mbsc-timeline-header-date, .mbsc-material-dark.mbsc-timeline-resource-empty, .mbsc-material-dark.mbsc-timeline-header-column, .mbsc-material-dark.mbsc-timeline-header, .mbsc-material-dark.mbsc-timeline-resource, .mbsc-material-dark.mbsc-timeline-column, .mbsc-material-dark.mbsc-schedule-resource-group, .mbsc-material-dark.mbsc-schedule-resource, .mbsc-material-dark.mbsc-schedule-time-col, .mbsc-material-dark.mbsc-schedule-all-day-item::after, .mbsc-material-dark.mbsc-schedule-column, .mbsc-material-dark.mbsc-schedule-item {
    border-color: #2b2b2b; }
  .mbsc-material-dark.mbsc-schedule-time-wrapper-end:before, .mbsc-material-dark.mbsc-schedule-time-wrapper:after {
    border-bottom: 1px solid #2b2b2b; }
  .mbsc-material-dark.mbsc-schedule-all-day-text, .mbsc-material-dark.mbsc-schedule-time {
    color: #dddddd; }
  .mbsc-material-dark.mbsc-schedule-time-indicator {
    border-color: #87b0f3; }
  .mbsc-material-dark.mbsc-schedule-time-indicator-day:before {
    background: #87b0f3; }
  .mbsc-material-dark.mbsc-cursor-indicator-time, .mbsc-material-dark.mbsc-schedule-time-indicator-time {
    background: rgba(0, 0, 0, 0.8); }
  .mbsc-material-dark.mbsc-schedule-time-indicator-time {
    color: #87b0f3; }
  .mbsc-material-dark.mbsc-schedule-event {
    color: #87b0f3; }
  .mbsc-material-dark.mbsc-schedule-event-inner {
    color: #000; }
  .mbsc-material-dark.mbsc-schedule-invalid {
    background: rgba(26, 26, 26, 0.75);
    color: #787878; }
  .mbsc-material-dark.mbsc-timeline-resource-empty, .mbsc-material-dark.mbsc-timeline-resource-bg {
    background: #000; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-eventcalendar {
  height: 100%;
  overflow: hidden; }

/* Event list */
.mbsc-event-list {
  -moz-box-flex: 1;
       flex: 1 1 auto;
  position: relative; }

.mbsc-event-list-scroll {
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.mbsc-event-list-empty {
  text-align: center;
  margin: 3em;
  font-size: 1.5em; }

.mbsc-event-day {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 3; }

.mbsc-event-color,
.mbsc-event-time {
  -moz-box-flex: 0;
       flex: 0 0 auto; }

.mbsc-event-content {
  -moz-box-flex: 1;
       flex: 1 1 auto;
  overflow: hidden; }

.mbsc-event-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/* Popover list */
.mbsc-popover-list {
  width: 20em;
  max-width: 100%;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  overflow: hidden; }

.mbsc-popover-hidden {
  visibility: hidden; }

.mbsc-calendar-dragging {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  cursor: move; }

/* External drag element */
.mbsc-draggable {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mbsc-drag-clone {
  position: fixed;
  display: table;
  z-index: 99;
  margin: 15px 0 0 0 !important;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  cursor: move; }
  .mbsc-drag-clone:after {
    content: '';
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: -50px;
    right: -50px; }

/* Fake scroll rules */
.mbsc-schedule-fake-scroll-x,
.mbsc-schedule-fake-scroll-y {
  visibility: hidden;
  -moz-box-flex: 0;
       flex: none; }

.mbsc-schedule-fake-scroll-x {
  overflow-x: scroll;
  width: 0; }

.mbsc-schedule-fake-scroll-y {
  overflow-y: scroll;
  max-height: 20px; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material {
  /* Checkmark styling */
  /* Desktop styling */ }
  .mbsc-material.mbsc-scroller-wheel-wrapper {
    margin: 0 .25em;
    padding: .5em 0; }
  .mbsc-material.mbsc-scroller-wheel-cont::after, .mbsc-material.mbsc-scroller-wheel-cont::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    border-width: 2px;
    border-top-style: solid;
    z-index: 1; }
  .mbsc-material.mbsc-scroller-wheel-cont::after {
    bottom: -2px; }
  .mbsc-material.mbsc-scroller-wheel-cont::before {
    top: -2px; }
  .mbsc-material.mbsc-scroller-wheel-group {
    padding: 2em .25em; }
  .mbsc-material.mbsc-scroller-wheel-item {
    padding: 0 .272727em;
    font-size: 1.375em;
    text-align: center; }
  .mbsc-material.mbsc-wheel-item-multi {
    padding: 0 2em; }
  .mbsc-material.mbsc-scroller-wheel-header {
    font-weight: bold;
    font-size: 1.125em; }
  .mbsc-material.mbsc-wheel-checkmark {
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    position: absolute;
    top: 50%;
    display: none;
    border-radius: .1875em;
    width: 1.3em;
    height: 1.3em;
    margin-top: -.55em; }
    .mbsc-material.mbsc-wheel-checkmark::after {
      content: '';
      -moz-box-sizing: border-box;
           box-sizing: border-box;
      position: absolute;
      display: block;
      top: 0.27273em;
      left: 0.27273em;
      width: .8125em;
      height: .4375em;
      opacity: 0;
      border: .125em solid;
      border-top: 0;
      border-right: 0;
      -webkit-transform: scale(0) rotate(-45deg);
              transform: scale(0) rotate(-45deg);
      transition: -webkit-transform .1s ease-out;
      transition: transform .1s ease-out;
      transition: transform .1s ease-out, -webkit-transform .1s ease-out; }
    .mbsc-material.mbsc-wheel-checkmark.mbsc-selected::after {
      opacity: 1;
      -webkit-transform: scale(1) rotate(-45deg);
              transform: scale(1) rotate(-45deg); }
    .mbsc-material.mbsc-wheel-checkmark.mbsc-ltr {
      left: .25em; }
    .mbsc-material.mbsc-wheel-checkmark.mbsc-rtl {
      right: .25em; }
  .mbsc-material.mbsc-wheel-item-multi .mbsc-material.mbsc-wheel-checkmark {
    display: block; }
  .mbsc-material.mbsc-scroller-pointer .mbsc-scroller-wheel-group {
    padding: 0; }
  .mbsc-material.mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper {
    margin: 0;
    padding: 0; }
  .mbsc-material.mbsc-scroller-pointer .mbsc-scroller-wheel-item {
    font-size: 1em;
    padding: 0 1.25em; }
  .mbsc-material.mbsc-scroller-pointer .mbsc-wheel-item-multi {
    padding: 0 2em; }
  .mbsc-material.mbsc-scroller-pointer .mbsc-wheel-item-multi.mbsc-ltr {
    text-align: left; }
  .mbsc-material.mbsc-scroller-pointer .mbsc-wheel-item-multi.mbsc-rtl {
    text-align: right; }

.mbsc-material.mbsc-scroller-wheel-cont::after, .mbsc-material.mbsc-scroller-wheel-cont::before {
  border-color: #1a73e8; }

.mbsc-material.mbsc-scroller-wheel-multi::after, .mbsc-material.mbsc-scroller-wheel-multi::before {
  border-color: transparent; }

.mbsc-material.mbsc-scroller-wheel-item.mbsc-active, .mbsc-material.mbsc-scroller-wheel-item.mbsc-hover, .mbsc-material.mbsc-scroller-wheel-item.mbsc-focus, .mbsc-material.mbsc-scroller-wheel-header.mbsc-focus {
  background: rgba(0, 0, 0, 0.05); }

.mbsc-material.mbsc-wheel-checkmark::after {
  border-color: #1a73e8; }

.mbsc-material-dark.mbsc-scroller-wheel-cont::after, .mbsc-material-dark.mbsc-scroller-wheel-cont::before {
  border-color: #87b0f3; }

.mbsc-material-dark.mbsc-scroller-wheel-multi::after, .mbsc-material-dark.mbsc-scroller-wheel-multi::before {
  border-color: transparent; }

.mbsc-material-dark.mbsc-scroller-wheel-item.mbsc-active, .mbsc-material-dark.mbsc-scroller-wheel-item.mbsc-hover, .mbsc-material-dark.mbsc-scroller-wheel-item.mbsc-focus, .mbsc-material-dark.mbsc-scroller-wheel-header.mbsc-focus {
  background: rgba(0, 0, 0, 0.05); }

.mbsc-material-dark.mbsc-wheel-checkmark::after {
  border-color: #87b0f3; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-scroller {
  width: 100%;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.mbsc-scroller-wheel-group-cont {
  position: relative;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  overflow: hidden; }

.mbsc-scroller-wheel-group-cont:first-child:last-child {
  display: block; }

.mbsc-scroller-wheel-group {
  margin: 0 auto;
  position: relative;
  display: -moz-box;
  display: flex;
  -moz-box-orient: horizontal;
  -moz-box-direction: normal;
       flex-direction: row;
  -moz-box-pack: center;
       justify-content: center; }

.mbsc-scroller-wheel-group-3d {
  -webkit-perspective: 100em;
          perspective: 100em; }

.mbsc-scroller-wheel-wrapper {
  display: block;
  max-width: 100%;
  position: relative;
  touch-action: none;
  /* Temp */
  min-width: 80px; }

.mbsc-scroller-pointer .mbsc-scroller-wheel-wrapper-0:last-child {
  -moz-box-flex: 1;
       flex: 1 1 auto; }

.mbsc-scroller-wheel-line {
  display: none;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  pointer-events: none;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.mbsc-scroller-wheel-overlay {
  display: none;
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none; }

.mbsc-scroller-wheel {
  display: block;
  overflow: hidden;
  /* Fixes Firefox rendering issues */
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  margin: -1px 0; }

.mbsc-scroller-wheel-cont {
  position: relative;
  z-index: 1;
  top: 50%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.mbsc-scroller-wheel-cont-3d {
  overflow: hidden;
  border: 0; }

.mbsc-scroller-wheel-cont-3d > div {
  position: relative;
  top: -1px; }

.mbsc-scroller-wheel-wrapper-3d,
.mbsc-scroller-wheel-3d {
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d; }

.mbsc-scroller-items-3d {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.mbsc-scroller .mbsc-scroller-wheel,
.mbsc-scroller .mbsc-scroller-wheel-cont {
  -moz-box-sizing: content-box;
       box-sizing: content-box; }

.mbsc-scroller-wheel-item {
  display: block;
  position: relative;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.mbsc-scroller-wheel-item:focus,
.mbsc-scroller-wheel-header:focus {
  outline: 0; }

.mbsc-scroller-wheel-item-3d {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.mbsc-scroller-wheel-item.mbsc-disabled {
  opacity: .3; }

.mbsc-scroller-wheel-header {
  display: block;
  padding: 0 .5em; }
  .mbsc-scroller-wheel-header.mbsc-ltr {
    text-align: left; }
  .mbsc-scroller-wheel-header.mbsc-rtl {
    text-align: right; }

.mbsc-scroller-bar-cont {
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  width: 10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.05);
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  transition: opacity .2s; }
  .mbsc-scroller-bar-cont.mbsc-ltr {
    right: 0; }
  .mbsc-scroller-bar-cont.mbsc-rtl {
    left: 0; }

.mbsc-scroller-bar-hidden {
  display: none; }

.mbsc-scroller-wheel:hover .mbsc-scroller-bar-cont,
.mbsc-scroller-bar-started {
  opacity: 1; }

.mbsc-scroller-bar {
  position: absolute;
  right: 0;
  top: 0;
  width: 10px; }
  .mbsc-scroller-bar::after {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-ios.mbsc-timegrid-item:hover, .mbsc-ios.mbsc-timegrid-item:focus {
  background: rgba(0, 122, 255, 0.3); }

.mbsc-ios.mbsc-timegrid-item.mbsc-selected {
  background: #007aff;
  color: white; }

.mbsc-ios-dark.mbsc-timegrid-item:hover, .mbsc-ios-dark.mbsc-timegrid-item:focus {
  background: rgba(255, 159, 10, 0.3); }

.mbsc-ios-dark.mbsc-timegrid-item.mbsc-selected {
  background: #ff9f0a;
  color: white; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material.mbsc-timegrid-item {
  line-height: 2em;
  margin: .5em .625em;
  border-radius: 2em;
  font-size: .875em; }

.mbsc-material.mbsc-timegrid-item.mbsc-selected, .mbsc-material.mbsc-timegrid-item:hover, .mbsc-material.mbsc-timegrid-item:focus {
  margin: 0 .25em;
  outline: none; }

.mbsc-material.mbsc-timegrid-item:hover, .mbsc-material.mbsc-timegrid-item:focus {
  background: rgba(48, 48, 48, 0.1); }

.mbsc-material.mbsc-timegrid-item.mbsc-selected {
  background: #1a73e8;
  color: white; }

.mbsc-material-dark.mbsc-timegrid-item:hover, .mbsc-material-dark.mbsc-timegrid-item:focus {
  background: rgba(255, 255, 255, 0.1); }

.mbsc-material-dark.mbsc-timegrid-item.mbsc-selected {
  background: #87b0f3;
  color: black; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-windows.mbsc-timegrid-item.mbsc-selected {
  background: rgba(0, 120, 215, 0.25);
  color: #333333; }

.mbsc-windows.mbsc-timegrid-item:hover, .mbsc-windows.mbsc-timegrid-item:focus {
  background: rgba(51, 51, 51, 0.1); }

.mbsc-windows.mbsc-timegrid-item.mbsc-selected:hover, .mbsc-windows.mbsc-timegrid-item.mbsc-selected:focus {
  background: rgba(18, 96, 158, 0.35); }

.mbsc-windows-dark.mbsc-timegrid-item.mbsc-selected {
  background: rgba(0, 120, 215, 0.25);
  color: #ffffff; }

.mbsc-windows-dark.mbsc-timegrid-item:hover, .mbsc-windows-dark.mbsc-timegrid-item:focus {
  background: rgba(255, 255, 255, 0.1); }

.mbsc-windows-dark.mbsc-timegrid-item.mbsc-selected:hover, .mbsc-windows-dark.mbsc-timegrid-item.mbsc-selected:focus {
  background: rgba(89, 167, 229, 0.35); }

.mbsc-datepicker-control-calendar .mbsc-datepicker-tab-timegrid {
  min-height: 10em; }

.mbsc-datepicker-tab-timegrid {
  min-height: 19em; }

.mbsc-timegrid-item.mbsc-disabled {
  pointer-events: none;
  opacity: 0.2; }

.mbsc-datepicker-tab-timegrid.mbsc-datepicker-tab {
  position: relative;
  overflow: auto;
  -moz-box-align: start;
       align-items: flex-start;
  width: 18.5em; }

.mbsc-timegrid-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: table;
  table-layout: fixed;
  width: 100%; }

.mbsc-timegrid-row {
  display: table-row; }

.mbsc-timegrid-cell {
  display: table-cell;
  position: relative; }
  .mbsc-timegrid-cell.mbsc-disabled {
    cursor: not-allowed; }

.mbsc-timegrid-item {
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material {
  /* Range Control */ }
  .mbsc-material.mbsc-picker-header {
    font-size: 1.25em;
    font-weight: 500;
    padding: .8em .8em 0 .8em;
    line-height: 1.4em;
    text-align: center; }
  .mbsc-material.mbsc-range-control-wrapper .mbsc-segmented {
    padding: 0;
    max-width: 100%;
    width: 18.5em; }
  .mbsc-material.mbsc-range-control-wrapper .mbsc-segmented-button.mbsc-button.mbsc-font {
    background: none;
    border: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    padding: .8571422em 1.142857em;
    display: block; }
  .mbsc-material.mbsc-range-control-wrapper .mbsc-segmented-button.mbsc-ltr {
    text-align: left; }
  .mbsc-material.mbsc-range-control-wrapper .mbsc-segmented-button.mbsc-rtl {
    text-align: right; }
  .mbsc-material.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-ltr {
    padding-right: 2.642857em; }
  .mbsc-material.mbsc-range-control-wrapper .mbsc-range-value-nonempty .mbsc-segmented-button.mbsc-rtl {
    padding-left: 2.642857em; }
  .mbsc-material.mbsc-datepicker-control-calendar.mbsc-datepicker-control-timegrid .mbsc-range-control-wrapper .mbsc-segmented,
  .mbsc-material.mbsc-datepicker-control-calendar.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
  .mbsc-material.mbsc-datepicker-control-date.mbsc-datepicker-control-time .mbsc-range-control-wrapper .mbsc-segmented,
  .mbsc-material.mbsc-datepicker-control-datetime .mbsc-range-control-wrapper .mbsc-segmented {
    width: 25em; }
  .mbsc-material.mbsc-range-control-label {
    text-transform: uppercase; }
  .mbsc-material.mbsc-range-control-value {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis; }
  .mbsc-material.mbsc-range-label-clear {
    margin-top: -.625em;
    right: .75em;
    width: 1.3125em;
    height: 1.3125em; }
    .mbsc-material.mbsc-range-label-clear.mbsc-ltr {
      right: .75em; }
    .mbsc-material.mbsc-range-label-clear.mbsc-rtl {
      left: .75em; }
  .mbsc-material.mbsc-datepicker .mbsc-calendar-title {
    font-size: 1.142858em;
    line-height: 1.75em; }
  .mbsc-material.mbsc-calendar-grid .mbsc-calendar-title {
    font-size: 1.428572em;
    line-height: 1.4em; }

.mbsc-material {
  /* Range Controls */ }
  .mbsc-material.mbsc-datepicker .mbsc-calendar-slide {
    background: #fff; }
  .mbsc-material.mbsc-datepicker-inline {
    background: #fff;
    color: #303030; }
  .mbsc-material.mbsc-datepicker-inline .mbsc-calendar-slide {
    background: #fff; }
  .mbsc-material.mbsc-range-control-wrapper {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2); }
  .mbsc-material.mbsc-range-control-wrapper .mbsc-material.mbsc-segmented-button.mbsc-selected {
    border-bottom-color: #1a73e8; }
  .mbsc-material.mbsc-range-control-value.active, .mbsc-material.mbsc-range-control-label.active {
    color: #1a73e8; }
  .mbsc-material.mbsc-range-control-text-empty, .mbsc-material.mbsc-range-control-text-empty.active {
    color: rgba(48, 48, 48, 0.4); }
  .mbsc-material.mbsc-range-label-clear {
    color: #b0b0b0; }
    .mbsc-material.mbsc-range-label-clear:hover {
      color: #7d7d7d; }

.mbsc-material-dark {
  /* Range Controls */ }
  .mbsc-material-dark.mbsc-datepicker .mbsc-calendar-slide {
    background: #303030; }
  .mbsc-material-dark.mbsc-datepicker-inline {
    background: #000;
    color: #fff; }
  .mbsc-material-dark.mbsc-datepicker-inline .mbsc-calendar-slide {
    background: #000; }
  .mbsc-material-dark.mbsc-range-control-wrapper {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .mbsc-material-dark.mbsc-range-control-wrapper .mbsc-material-dark.mbsc-segmented-button.mbsc-selected {
    border-bottom-color: #87b0f3; }
  .mbsc-material-dark.mbsc-range-control-value.active, .mbsc-material-dark.mbsc-range-control-label.active {
    color: #87b0f3; }
  .mbsc-material-dark.mbsc-range-control-text-empty, .mbsc-material-dark.mbsc-range-control-text-empty.active {
    color: rgba(255, 255, 255, 0.4); }
  .mbsc-material-dark.mbsc-range-label-clear {
    color: gray; }
    .mbsc-material-dark.mbsc-range-label-clear:hover {
      color: #b3b3b3; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Needed for angular */
mbsc-datetime {
  display: block;
  width: 100%; }

.mbsc-datepicker-inline {
  height: 100%; }

.mbsc-datepicker .mbsc-calendar {
  height: 100%;
  max-width: 100%;
  padding-bottom: .5em;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  display: block; }

.mbsc-datepicker .mbsc-calendar-header .mbsc-calendar-week-days {
  padding: 0 .5em; }

/* Start/end controls */
.mbsc-range-control-wrapper .mbsc-segmented-input {
  width: 1px; }

.mbsc-range-label-clear {
  position: absolute;
  top: 50%; }

/* Tabs */
.mbsc-datepicker-tab-wrapper {
  position: relative;
  display: -moz-box;
  display: flex;
  flex-wrap: wrap;
  -moz-box-pack: center;
       justify-content: center;
  overflow: hidden; }

.mbsc-datepicker-tab {
  visibility: hidden;
  max-width: 100%;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center; }

.mbsc-datepicker-tab-expand {
  -moz-box-flex: 1;
       flex: 1 1 auto;
  height: 100%; }

.mbsc-datepicker-tab-active {
  visibility: visible; }

.mbsc-datepicker-time-modal {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  max-width: none;
  height: 100%; }

.mbsc-datepicker .mbsc-calendar-slide {
  padding: 0 .5em; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-material.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-cont::after,
.mbsc-material.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-cont::before {
  display: none; }

.mbsc-material.mbsc-select-filter {
  margin: 0; }

.mbsc-material.mbsc-select-empty-text {
  font-size: 1.375em;
  opacity: .5; }

.mbsc-material.mbsc-select-scroller-inline {
  background-color: #fff;
  color: #303030; }

.mbsc-material.mbsc-select-filter-clear {
  color: #b0b0b0; }
  .mbsc-material.mbsc-select-filter-clear:hover {
    color: #7d7d7d; }

.mbsc-material.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
  color: #1a73e8;
  background: rgba(0, 0, 0, 0.15); }

.mbsc-material.mbsc-select-group-wheel-multi .mbsc-selected {
  color: #1a73e8; }

.mbsc-material-dark.mbsc-select-scroller-inline {
  background-color: #000;
  color: #fff; }

.mbsc-material-dark.mbsc-select-filter-clear {
  color: gray; }
  .mbsc-material-dark.mbsc-select-filter-clear:hover {
    color: #b3b3b3; }

.mbsc-material-dark.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-selected {
  color: #87b0f3;
  background: rgba(0, 0, 0, 0.15); }

.mbsc-material-dark.mbsc-select-group-wheel-multi .mbsc-selected {
  color: #87b0f3; }

/* Base colors */
/* Form colors */
/* Calendar colors (will be used by eventcalendar, calendar, range) */
.mbsc-select-scroller .mbsc-scroller-wheel-wrapper {
  -moz-box-flex: 1;
       flex: 1 1 auto; }

.mbsc-select-filter-cont {
  position: relative; }

.mbsc-select-filter-clear {
  position: absolute;
  width: 1em;
  height: 1em;
  top: 50%;
  margin-top: -1em;
  padding: .5em;
  cursor: pointer;
  -moz-box-sizing: content-box;
       box-sizing: content-box; }
  .mbsc-select-filter-clear.mbsc-rtl {
    left: .5em;
    right: auto; }
  .mbsc-select-filter-clear.mbsc-ltr {
    right: .5em;
    left: auto; }

.mbsc-select-empty .mbsc-scroller-wheel-group-cont {
  visibility: hidden; }

.mbsc-select-empty-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-align: center;
       align-items: center; }

.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-ltr {
  text-align: left; }

.mbsc-select-scroller.mbsc-scroller-pointer .mbsc-scroller-wheel-item.mbsc-rtl {
  text-align: right; }
